import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface UserImageProps {
  children: React.ReactNode;
  size?: string;
}

export const UserImage: FunctionComponent<UserImageProps> = (props) => {
  const { children, size } = props;

  return (
    <div
      className={classNames(
        styles['profile-image'],
        { [styles['profile-image--large']]: size === 'large' },
        { [styles['profile-image--medium']]: size === 'medium' },
        { [styles['profile-image--small']]: size === 'small' },
        { [styles['profile-image--mini']]: size === 'mini' }
      )}
    >
      {children}
    </div>
  );
};
