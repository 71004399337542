import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { recentMessagesSelector } from 'features/chat/chatSelectors';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useMediaQuery } from 'react-responsive';
import { matchPath, useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';

export const useRecentMessages = () => {
  return useSelector((state: RootState) => recentMessagesSelector(state));
};

export const useSlidePanelForCrowd = () => {
  const { isDACreatorApp } = useContext(UserAgentContext);
  const { width } = useWindowDimensions();
  return isDACreatorApp || width >= 740;
};

export const useEventChatWithPlayer = (): boolean => {
  const { isListenerAppWebview } = useContext(UserAgentContext);
  const { width } = useWindowDimensions();
  if (isListenerAppWebview || width >= 640) return false;
  return true;
};

export const useChatVisibleOnListenerMobile = (): boolean => {
  const chatVisible = useAppSelector((state) => state.chat.isVisible);
  const { isListenerAppWebview } = useContext(UserAgentContext);
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);
  const isMobile = useMediaQuery({
    query: '(max-width: 640px)'
  });
  if (chatVisible && isListenerAppWebview && isMobile && isEventPath) {
    return true;
  }
  return false;
};
