import React, { FunctionComponent } from 'react';
import { DeepLinkBanner } from 'components/DeepLinkBanner';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { useAppSelector } from 'hooks/redux';
import { cloudflareUrl } from 'utilities/cloudflareImageResizer';

export const RecordingDeepLinkBanner: FunctionComponent = () => {
  const { nativeUrlSchemeLink } = useNativeEvents();
  const channel = useAppSelector((state) => state.channel.channelDetails);

  const deepLink = nativeUrlSchemeLink('mixlr', 'recording', {
    url: window.location.href,
    channel_url: window.location.origin.replace(/\/+$/, ''),
    channel_name: channel?.username,
    channel_theme_color: channel?.themeColor,
    channel_logo_url: cloudflareUrl(channel?.logoKey, {
      width: 100,
      quality: 80,
      baseUrl: process.env.REACT_APP_STORAGE_CDN_URL
    })
  });

  return <DeepLinkBanner deepLink={deepLink} />;
};
