import React, { FunctionComponent } from 'react';
import { Icon } from '../Icons';

interface LiveNowProps {
  isLive: boolean | undefined;
}

export const LiveNow: FunctionComponent<LiveNowProps> = (props) => {
  if (!props.isLive) {
    return null;
  }

  return (
    <div className="tw-bg-mixlr-red tw-text-white tw-inline-flex tw-gap-2 tw-items-center tw-justify-center tw-text-base [.ios_&]:tw-text-xs tw-font-semibold tw-rounded-full tw-h-[24px] tw-px-3 [.ios_&]:tw-px-2">
      <Icon
        icon={'liveNow'}
        height={16}
        hidden={true}
        label={'Live'}
        width={16}
      />
      Live
    </div>
  );
};
