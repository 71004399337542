"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.telegram = void 0;
var types_1 = require("../types");
exports.telegram = {
    name: 'telegram',
    matches: [
        {
            match: '(https?://)?(www.)?(t.me|telegram.me)/({PROFILE_ID})/?', group: 4, type: types_1.TYPE_DESKTOP,
            pattern: 'https://t.me/{PROFILE_ID}'
            // profileId: ([a-z0-9_]{5,32})
        },
        { match: '@?({PROFILE_ID})', group: 1 },
    ]
};
