import React, {
  FunctionComponent,
  useRef,
  ChangeEvent,
  SyntheticEvent
} from 'react';
import { IconButton } from 'components/Buttons/IconButton';
import styles from './styles.module.scss';

interface FormInlineProps {
  label: string;
  inputType: string;
  inputName: string;
  buttonlabel: string;
  buttonIcon: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: SyntheticEvent) => void;
  formError: boolean;
  formErrorMsg: string;
}

export const FormInline: FunctionComponent<FormInlineProps> = (props) => {
  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault;
    if (!formRef.current) return;
    const isFormValid = formRef.current.checkValidity();
    if (!isFormValid) {
      formRef.current.reportValidity();
    } else {
      props.onSubmit(event);
    }
  };

  return (
    <form
      ref={formRef}
      action=""
      method="get"
      className={styles['form-inline']}
      onSubmit={onSubmit}
    >
      <label htmlFor={props.inputName} className={styles['form-inline__label']}>
        {props.label}
      </label>
      <div className={styles['form-inline__row']}>
        <input
          className={styles['form-inline__input']}
          type={props.inputType}
          name={props.inputName}
          onChange={props.onChange}
          required
        />
        <IconButton
          label={props.buttonlabel}
          icon={props.buttonIcon}
          type="submit"
        />
      </div>
      {props.formError && (
        <div className="form__error">{props.formErrorMsg}</div>
      )}
    </form>
  );
};
