import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PlayerConfigInterface {
  audioSrc: string;
}

export interface AudioState {
  duration: number;
  error: Error | null;
  ended: boolean;
  connecting: boolean;
  ready: boolean;
  loading: boolean;
  playing: boolean;
  play: boolean;
  currentVolume: number;
  url: string | undefined;
  nativeAudioStatus: string | undefined;
}

export const initialAudioState: AudioState = {
  duration: 0,
  ended: false,
  error: null,
  connecting: false,
  ready: false,
  loading: false,
  playing: false,
  play: false,
  currentVolume: 1,
  url: undefined,
  nativeAudioStatus: undefined
};

const audioSlice = createSlice({
  name: 'audio',
  initialState: initialAudioState,
  reducers: {
    setAudioUrl(state, action: PayloadAction<string>) {
      state.url = action.payload;
    },
    setEnded(state, action: PayloadAction<boolean>) {
      state.ended = action.payload;
    },
    setConnecting(state, action: PayloadAction<boolean>) {
      state.connecting = action.payload;
    },
    setReady(state, action: PayloadAction<boolean>) {
      state.ready = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPlaying(state, action: PayloadAction<boolean>) {
      state.playing = action.payload;
    },
    setPlay(state, action: PayloadAction<boolean>) {
      state.play = action.payload;
    },
    setCurrentVolume(state, action: PayloadAction<number>) {
      state.currentVolume = action.payload;
    },
    setDuration(state, action: PayloadAction<number>) {
      state.duration = action.payload;
    },
    setError(state, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    resetAudioState() {
      return initialAudioState;
    },
    setNativeAudioStatus(state, action: PayloadAction<string>) {
      state.nativeAudioStatus = action.payload;
      switch (action.payload) {
        case 'playing':
          state.playing = true;
          state.connecting = false;
          break;
        case 'stopped':
          state.playing = false;
          state.connecting = false;
          break;
        case 'connecting':
          state.connecting = true;
          break;
      }
    }
  }
});

export const {
  setAudioUrl,
  setEnded,
  setConnecting,
  setReady,
  setLoading,
  setPlaying,
  setPlay,
  setDuration,
  setCurrentVolume,
  setError,
  setNativeAudioStatus,
  resetAudioState
} = audioSlice.actions;

export default audioSlice.reducer;
