import { combineReducers } from '@reduxjs/toolkit';
import alertReducer from 'features/alert/alertSlice';
import channelReducer from 'features/channel/channelSlice';
import chatReducer from 'features/chat/chatSlice';
import crowdReducer from 'features/crowd/crowdSlice';
import currentUserReducer from 'features/current-user/currentUserSlice';
import hubReducer from 'features/hub/hubSlice';
import eventsReducer from 'features/events/eventsSlice';
import uiReducer from 'features/ui/uiSlice';
import audioReducer from 'features/audio/audioSlice';
import recordingsReducer from 'features/recordings/recordingsSlice';
import broadcastsReducer from 'features/broadcast/broadcastsSlice';
import searchReducer from 'features/search/searchSlice';
import collectionsReducer from 'features/collections/collectionsSlice';

const rootReducer = combineReducers({
  audio: audioReducer,
  chat: chatReducer,
  channel: channelReducer,
  crowd: crowdReducer,
  broadcasts: broadcastsReducer,
  hub: hubReducer,
  currentUser: currentUserReducer,
  notifications: alertReducer,
  events: eventsReducer,
  ui: uiReducer,
  recordings: recordingsReducer,
  search: searchReducer,
  collections: collectionsReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
