import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as AudioLoading } from 'images/audio-loading.svg';

export const ConnectingAudio: FunctionComponent = () => {
  return (
    <div className={styles['connecting-audio']}>
      <AudioLoading />
      <strong>Connecting audio</strong>
    </div>
  );
};
