import { Broadcast } from 'features/broadcast/broadcastsSlice';
import { createSelector } from 'reselect';
import { RootState } from 'rootReducer';

export const broadcastSelector = createSelector(
  [
    (state: RootState) => state.broadcasts.broadcasts,
    (state: RootState, uid: string | null) => uid
  ],
  (broadcasts, uid): Broadcast | null => {
    return broadcasts.find((broadcastEl) => broadcastEl.uid === uid) || null;
  },
  {
    memoizeOptions: {
      maxSize: 10
    }
  }
);

export const liveBroadcastsSelector = createSelector(
  [(state: RootState) => state.broadcasts.broadcasts],
  (broadcasts): Broadcast[] => {
    return broadcasts.filter((broadcastEl) => broadcastEl.isLive);
  }
);

export const nextLiveBroadcastSelector = createSelector(
  liveBroadcastsSelector,
  (broadcasts): Broadcast | null => {
    return broadcasts[broadcasts.length - 1];
  }
);

export const broadcastForEventSelector = createSelector(
  [
    (state: RootState) => state.broadcasts.broadcasts,
    (state: RootState, eventId?: string | null) => eventId
  ],
  (broadcasts, eventId): Broadcast | null => {
    return (
      broadcasts.find((broadcastEl) => broadcastEl.eventId === eventId) || null
    );
  }
);

export const currentEventBroadcastSelector = createSelector(
  [
    (state: RootState) => state.events.currentEventId,
    (state: RootState) => state.broadcasts.broadcasts
  ],
  (eventId, broadcasts): Broadcast | null => {
    return (
      broadcasts.find((broadcastEl) => broadcastEl.eventId === eventId) || null
    );
  }
);

export const currentBroadcastFinishedSelector = createSelector(
  currentEventBroadcastSelector,
  (broadcast): boolean => {
    return broadcast?.finished || false;
  }
);

export const currentBroadcastListenerCountSelector = createSelector(
  currentEventBroadcastSelector,
  (broadcast): number | null => {
    return broadcast?.listenerCount || null;
  }
);

export const currentBroadcastHeartCountSelector = createSelector(
  currentEventBroadcastSelector,
  (broadcast): number | null => {
    if (!broadcast) {
      return null;
    }
    return broadcast.heartCount;
  }
);
