import React, { FunctionComponent } from 'react';
import { useAppSelector } from 'hooks/redux';
import { ReactComponent as SearchEmptySVG } from 'images/empty-search.svg';
import { ReactComponent as RecordingsEmptySVG } from 'images/empty-recordings.svg';
import { ReactComponent as EventsEmptySVG } from 'images/empty-events.svg';
import { styleWrapper } from 'utilities/styleWrapper';

export const SearchEmpty: FunctionComponent = () => {
  const searchQuery = useAppSelector((state) => state.search.searchQuery);
  const searchType = useAppSelector((state) => state.search.searchType);
  const Wrapper = styleWrapper('div', 'empty-state');
  const Content = styleWrapper('div', 'empty-state__content');

  if (searchQuery) {
    return (
      <Wrapper>
        <Content>
          {searchType === 'events' ? (
            <EventsEmptySVG />
          ) : (
            <RecordingsEmptySVG />
          )}
          <p>No {searchType} found</p>
        </Content>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Content>
        <SearchEmptySVG />
        <p>Search events & recordings</p>
      </Content>
    </Wrapper>
  );
};
