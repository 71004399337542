import { V3Recordings } from 'api/v3/types/recordings';
import { V3RecordingAttributes } from 'api/v3/types/recording';

const perFetch = 20;

export async function apiGetRecordings(
  subdomain: string,
  pageNumber: number
): Promise<V3Recordings> {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/v3/channels/${subdomain}/recordings?page[size]=${perFetch}&page[number]=${pageNumber}`;
  const results = await fetch(url, { credentials: 'include' });

  if (results.ok) {
    return await results.json();
  } else {
    throw results.body;
  }
}

export async function apiGetRecording(
  channelSubdomain: string,
  recordingId: string
): Promise<V3RecordingAttributes> {
  const result = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/channels/${channelSubdomain}/recordings/${recordingId}`,
    { credentials: 'include' }
  );

  if (result.ok) {
    const json = await result.json();

    return json.data;
  } else {
    throw result;
  }
}
