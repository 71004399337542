import React, { FunctionComponent, useContext } from 'react';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { InfiniteScrollCollections } from './InfiniteScrollCollections';
import { PaginatedCollections } from './PaginatedCollections';

export const ChannelCollections: FunctionComponent = () => {
  const { isListenerAppWebview } = useContext(UserAgentContext);

  if (isListenerAppWebview) {
    return <InfiniteScrollCollections itemsPerPage={20} />;
  }

  return <PaginatedCollections itemsPerPage={20} />;
};
