import React, { FunctionComponent } from 'react';
import { Chat } from 'features/chat/Chat';
import { EventModel } from 'features/events/EventModel';

interface EventChatProps {
  event: EventModel | null;
}

export const EventChat: FunctionComponent<EventChatProps> = ({ event }) => {
  if (event && !event.chatEnabled) {
    return null;
  }

  return <Chat fetchHistory={true} />;
};
