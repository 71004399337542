import React, { FunctionComponent, useEffect } from 'react';
import classNames from 'classnames';
import { motion, useReducedMotion, AnimatePresence } from 'framer-motion';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { UserImage } from 'components/UserImage';
import { LogoImage } from 'components/LogoImage';
import { ThumbImage } from 'components/Images/ThumbImage';
import { CrowdMemberState, setCrowdVisible } from 'features/crowd/crowdSlice';
import { crowdSortedListSelector } from 'features/crowd/crowdSelectors';
import { useSlidePanelForCrowd } from 'features/chat/chatHooks';
import { setSelectedChatUserId } from 'features/chat/chatSlice';
import { ChannelDetails } from 'features/channel/channelSlice';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const Crowd: FunctionComponent = () => {
  const shouldReduceMotion = useReducedMotion();
  const isCrowdVisible = useSelector(
    (state: RootState) => state.crowd.isCrowdVisible
  );
  const { width } = useWindowDimensions();
  const crowd = useSelector(crowdSortedListSelector);
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const slidePanelForCrowd = useSlidePanelForCrowd();
  const dispatch = useDispatch();

  const animationVariant = {
    visible: {
      opacity: 1,
      x: slidePanelForCrowd && width < 1024 ? '-100%' : 0,
      boxShadow:
        slidePanelForCrowd && width < 1024
          ? '0 0 16px rgba(0, 0, 0, 0.2)'
          : 'none'
    },
    hidden: {
      opacity: slidePanelForCrowd ? 0 : 0,
      x: 0,
      boxShadow: 'none'
    }
  };

  const showProfileMember =
    (member: CrowdMemberState) => (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch(
        setSelectedChatUserId({
          id: member.id,
          username: member.username,
          profileImageUrl: member.profileImageUrl
        })
      );
    };

  const showProfileChannel =
    (channel: ChannelDetails) => (event: React.MouseEvent) => {
      event.preventDefault();
      if (!channel.channelId) return;
      const channelLogo = event.currentTarget.querySelector('img');
      dispatch(
        setSelectedChatUserId({
          id: channel.channelId,
          username: channel.username,
          profileImageUrl: channelLogo ? channelLogo.src : ''
        })
      );
    };

  useEffect(() => {
    dispatch(setCrowdVisible(width > 1024));
  }, [width, dispatch]);

  return (
    <AnimatePresence initial={false}>
      {isCrowdVisible && (
        <motion.div
          className={classNames(styles['crowd'], {
            [styles['crowd--visible']]: isCrowdVisible
          })}
          initial={'hidden'}
          animate={'visible'}
          exit={'hidden'}
          transition={{ duration: shouldReduceMotion ? 0 : 0.2 }}
          variants={animationVariant}
        >
          <div className={styles['crowd__list']}>
            <ul
              className={classNames(styles['crowd__list__view'], 'list--reset')}
            >
              {channel && (
                <li
                  className={classNames(
                    styles['crowd__member'],
                    styles['crowd__member--creator']
                  )}
                  onClick={showProfileChannel(channel)}
                >
                  <LogoImage size={'mini'} hasFallback={!channel.logoKey}>
                    <ThumbImage
                      artworkUrl={channel.channelLogo.image.small}
                      artworkMode={'logo'}
                      alt={`Logo for ${channel.username}`}
                    />
                  </LogoImage>
                  <h3
                    className={classNames(
                      styles['crowd__member__title'],
                      'truncate'
                    )}
                  >
                    {channel.username}
                  </h3>
                </li>
              )}
              {crowd.map((member) => (
                <li
                  key={member.id}
                  className={styles['crowd__member']}
                  onClick={showProfileMember(member)}
                >
                  <UserImage size={'mini'}>
                    <img
                      src={member.profileImageUrl}
                      alt={`Profile image for ${member.username}`}
                      loading="lazy"
                    />
                  </UserImage>
                  <h3
                    className={classNames(
                      styles['crowd__member__title'],
                      'truncate'
                    )}
                  >
                    {member.username}
                  </h3>
                </li>
              ))}
            </ul>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
