import React, { FunctionComponent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import { formatTime } from '../../utilities/formatters';
import { useAudio } from 'features/audio/audio';
import { useAudioPosition } from 'features/audio/useAudioPosition';

export const SeekBar: FunctionComponent = () => {
  const [seekPosition, setSeekPosition] = useState<number>(0);
  const [barWidth, setBarWidth] = useState<string>('0%');
  const { audio } = useAudio();
  const loading = useSelector((state: RootState) => state.audio.loading);
  const { duration, seek, percentComplete, position, seeking, setSeeking } =
    useAudioPosition();

  useEffect(() => {
    setBarWidth(`${percentComplete}%`);
  }, [percentComplete]);

  const onInput = (event: any) => {
    const newValue = Number(event.target.value);
    const progressPosition = ((newValue / duration) * 100) | 0;
    setSeeking(true);
    setBarWidth(`${progressPosition}%`);
    setSeekPosition(newValue);
  };

  const onSeekEnd = () => {
    seek(seekPosition);
    setSeeking(false);
  };

  const onMoveSeek = (event: any) => {
    const newValue = Number(event.target.value);
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      seek(newValue);
    }
  };

  const value = seeking ? seekPosition : audio?.currentTime || 0;
  return (
    <div className={styles['seekbar']}>
      <input
        className={styles['seekbar__range']}
        type="range"
        id="seek-bar"
        disabled={loading || !audio}
        value={value}
        min={0}
        max={duration}
        onInput={onInput}
        onMouseUp={onSeekEnd}
        onTouchEnd={onSeekEnd}
        onKeyDown={onMoveSeek}
        tab-index="0"
        aria-label="Audio timeline"
        aria-valuemin={0}
        aria-valuemax={duration}
        aria-valuetext={value.toString()}
        aria-valuenow={value}
        style={{
          background: `
          linear-gradient(
          to right,
          var(--color__background) 0%,
          var(--color__background) ${barWidth},
          rgb(var(--color__white-rgb), .1) ${barWidth},
          rgb(var(--color__white-rgb), .1) 100%
        )
      `
        }}
      />
      <div className={styles['seekbar__time']}>
        <span className="tnum">{formatTime(position)}</span>
        <span className="tnum">{formatTime(duration)}</span>
      </div>
      <label className="visually-hidden" htmlFor="seek-bar">
        Seek through audio
      </label>
    </div>
  );
};
