import React, { FunctionComponent, useState, useContext } from 'react';
import styles from './styles.module.scss';
import buttonStyles from 'components/Buttons/styles.module.scss';
import SwiperCore, { Navigation, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Card } from 'components/Card';
import { Link } from 'react-router-dom';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { EventModel } from 'features/events/EventModel';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { Icon } from 'components/Icons';
import classNames from 'classnames';
import { UserAgentContext } from 'features/user-agent/userAgent';

interface SwiperListProps {
  title: string;
  url: string;
  eventData?: EventModel[];
  recordingData?: RecordingModel[];
}

export const SwiperList: FunctionComponent<SwiperListProps> = ({
  eventData,
  recordingData,
  title,
  url
}) => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  SwiperCore.use([Navigation, Scrollbar, A11y]);
  const { isListenerAppWebview } = useContext(UserAgentContext);

  if (eventData && eventData.length === 0) {
    return null;
  }

  if (recordingData && recordingData.length === 0) {
    return null;
  }

  return (
    <div className={styles['swiper-list']}>
      <div className={styles['swiper-list__header']}>
        <h2>{title}</h2>
        {!isListenerAppWebview && <Link to={url}>View all</Link>}
      </div>
      <Swiper
        breakpoints={{
          320: {
            slidesPerView: 1.5,
            scrollbar: {
              draggable: true
            }
          },
          600: {
            slidesPerView: 2.5,
            scrollbar: false
          },
          1100: {
            slidesPerView: 3.5
          },
          2000: {
            slidesPerView: 4.5
          }
        }}
        watchOverflow={true}
        navigation={{ prevEl, nextEl }}
        slidesOffsetAfter={16}
        slidesOffsetBefore={16}
        spaceBetween={16}
      >
        {eventData && (
          <>
            {eventData.map((event) => (
              <SwiperSlide key={event.id}>
                <Card
                  id={event.id}
                  url={`/events/${event.id}`}
                  title={`${event.title}`}
                  artwork={event.artwork}
                  artworkKey={event.artworkKey}
                  artworkMode={event.artworkMode}
                  isPrivate={event.isPrivate()}
                  isActive={event.isLive()}
                  updatedTimestamp={event.lastUpdatedAt}
                  type={'event'}
                  color={event.color}
                />
              </SwiperSlide>
            ))}
          </>
        )}
        {recordingData && (
          <>
            {recordingData.map((recording) => (
              <SwiperSlide key={recording.id}>
                <Card
                  key={recording.id}
                  id={recording.id}
                  url={`/recordings/${recording.id}`}
                  artwork={recording.artwork}
                  artworkKey={recording.artworkKey}
                  artworkMode={recording.artworkMode}
                  title={`${recording.title}`}
                  type={'recording'}
                  duration={recording.duration}
                  color={recording.color}
                />
              </SwiperSlide>
            ))}
          </>
        )}
        <button
          ref={(node) => setPrevEl(node)}
          className={classNames(
            styles['swiper-list__prev'],
            buttonStyles['button--icon']
          )}
        >
          <Icon
            height={24}
            icon={'arrowLeft'}
            hidden={true}
            label={''}
            width={24}
          />
          <span className={'visually-hidden'}>Prev slide</span>
        </button>
        <button
          ref={(node) => setNextEl(node)}
          className={classNames(
            styles['swiper-list__next'],
            buttonStyles['button--icon']
          )}
        >
          <Icon
            height={24}
            icon={'arrowRight'}
            hidden={true}
            label={''}
            width={24}
          />
          <span className={'visually-hidden'}>Next slide</span>
        </button>
      </Swiper>
    </div>
  );
};
