import React, { FunctionComponent, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ErrorWrapper } from 'components/ErrorPages/ErrorWrapper';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const Error404: FunctionComponent = () => {
  const { isNativeAppWebview } = useContext(UserAgentContext);

  return (
    <ErrorWrapper type={'event'}>
      <strong>404</strong>
      <h1>Page not found!</h1>
      <p>{"We've looked everywhere and we still can't find this page."}</p>
      <p>{"It's possible the link no longer exists."}</p>
      {!isNativeAppWebview && <Link to="/">Return to homepage</Link>}
    </ErrorWrapper>
  );
};
