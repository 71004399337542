import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Event } from 'features/events/Event';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.scss';
import { AuthModal } from 'features/auth/AuthModal';
import ReactModal from 'react-modal';
import { Channel } from 'features/channel/Channel';
import { AutoplayPrompt } from 'features/events/AutoplayPrompt';
import { ChannelEmbed } from 'features/channel/components/ChannelEmbed';
import { ChannelHome } from 'features/channel/components/ChannelHome';
import { ChannelAbout } from 'features/channel/components/ChannelAbout';
import { ChannelEvents } from 'features/channel/components/ChannelEvents';
import { ChannelRecordings } from 'features/channel/components/ChannelRecordings';
import { AnimatePresence } from 'framer-motion';
import { Alert } from 'features/alert/alert';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { Privacy } from 'features/privacy/privacy';
import { ChannelSubscription } from 'features/channel_subscription/channelSubscription';
import { EventMiniPlayer } from 'features/events/components/EventMiniPlayer';
import { RecordingMiniPlayer } from 'features/recordings/components/RecordingMiniPlayer';
import { Error404 } from 'components/ErrorPages/Error404';
import { ErrorEmbed } from 'components/ErrorPages/ErrorEmbed';
import { Search } from 'features/search/Search';
import { StateTester } from 'features/statetester/StateTester';
import { Recording } from 'features/recordings/Recording';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { useAudio } from 'features/audio/audio';
import { useDispatch } from 'react-redux';
import { useBannedChannelChecker } from 'hooks/useBannedChannelChecker';
import { useBackButtonListener } from 'hooks/useBackButtonListener';
import { WebSocketContext } from 'features/hub/hub';
import { useOnceEffect } from 'hooks/useOnceEffect';
import { setCurrentVolume } from 'features/audio/audioSlice';
import { SendRecordingListenEvents } from 'features/recordings/components/SendRecordingListenEvents';
import { HelmetTags } from 'components/Helmet';
import { ChannelCollections } from 'features/channel/components/ChannelCollections';
import { Collection } from 'features/collections/Components/Collection';
import './tailwind.css';
import { LiveBanner } from 'features/events/components/LiveBanner';
import { ErrorDeleted } from 'components/ErrorPages/ErrorDeleted';

const AnimatedRouteSwitch: FunctionComponent = () => {
  const location = useLocation();

  useBackButtonListener();
  useBannedChannelChecker();

  useEffect(() => {
    return () => {
      window.history.replaceState({}, document.title);
    };
  }, []);

  return (
    <AnimatePresence initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route element={<Channel />}>
          <Route path="/" element={<ChannelHome />} />
          <Route path="/about" element={<ChannelAbout />} />
          <Route path="/events" element={<ChannelEvents />} />
          <Route path="/recordings" element={<ChannelRecordings />} />
          <Route
            path="/recordings/page/:page"
            element={<ChannelRecordings />}
          />
          <Route path="/collections" element={<ChannelCollections />} />
          <Route
            path="/collections/page/:page"
            element={<ChannelCollections />}
          />
          <Route
            path="/collections/deleted"
            element={<ErrorDeleted type={'recording'} />}
          />
          <Route
            path="/collections/not-found"
            element={<ErrorEmbed type={'recording'} />}
          />
          <Route path="/collections/:id" element={<Collection />} />
          <Route path="/collections/:id/page/:page" element={<Collection />} />
        </Route>
        <Route path="/embed" element={<ChannelEmbed />} />
        <Route path="/events/:id" element={<Event />} />
        <Route
          path="/events/deleted"
          element={<ErrorDeleted type={'event'} />}
        />
        <Route
          path="/recordings/not-found"
          element={<ErrorEmbed type={'recording'} />}
        />
        <Route
          path="/recordings/deleted"
          element={<ErrorDeleted type={'recording'} />}
        />
        <Route path="/recordings/:id" element={<Recording />}>
          <Route path="/recordings/:id/embed" element={<Recording />} />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </AnimatePresence>
  );
};

const App: FunctionComponent = () => {
  const { isNativeAppWebview, isDACreatorApp, isListenerAppWebview } =
    useContext(UserAgentContext);
  const {
    postMessage,
    dynamicSizeUpdatesEvent,
    qWebChannel,
    eventWindowEvents
  } = useNativeEvents();
  const { connectToSocket, leaveCrowd } = useContext(WebSocketContext);
  const { setVolume, volume, stop } = useAudio();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation() as any;

  const { requiresAccesscode, requiresChannelSubscription } = useSelector(
    (state: RootState) => {
      return {
        requiresAccesscode: state.channel.requiresAccesscode,
        requiresChannelSubscription: state.channel.requiresChannelSubscription
      };
    }
  );

  useEffect(
    function stopAudioFromEventErrorRedirect() {
      if (location.state && location.state.stopAudio) {
        stop(true);
      }
    },
    [location, stop]
  );

  useEffect(() => {
    if (isNativeAppWebview) {
      postMessage('javascriptLoaded');
      dynamicSizeUpdatesEvent();
    }
  }, [postMessage, dynamicSizeUpdatesEvent, isNativeAppWebview]);

  useEffect(() => {
    connectToSocket();
  }, [connectToSocket]);

  useEffect(() => {
    return () => {
      leaveCrowd();
    };
  }, [leaveCrowd]);

  useEffect(() => {
    if (isDACreatorApp) {
      qWebChannel();
    }
  }, [isDACreatorApp, qWebChannel]);

  useEffect(() => {
    if (isNativeAppWebview) {
      eventWindowEvents(pathname, navigate);
    }
  }, [isNativeAppWebview, eventWindowEvents, pathname, navigate]);

  useEffect(() => {
    if (isListenerAppWebview) {
      postMessage({
        requiresAccesscode: requiresAccesscode
      });
    }
  }, [requiresAccesscode, isListenerAppWebview, postMessage]);

  useOnceEffect(() => {
    dispatch(setCurrentVolume(volume));
  });

  useEffect(() => {
    const handleUnload = () => {
      if (volume === 0) {
        setVolume(1);
      }
      leaveCrowd();
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [volume, setVolume, leaveCrowd]);

  if (requiresAccesscode) {
    return (
      <>
        <HelmetTags />
        <Privacy />
      </>
    );
  }

  if (requiresChannelSubscription) {
    return (
      <>
        <HelmetTags />
        <ChannelSubscription />
        <AuthModal />
      </>
    );
  }

  return (
    <>
      <HelmetTags />
      <Alert />
      <AnimatedRouteSwitch />
      <AuthModal />
      <AutoplayPrompt />
      <LiveBanner />
      <EventMiniPlayer />
      <RecordingMiniPlayer />
      <SendRecordingListenEvents />
      <Search />
      {(process.env.REACT_APP_ENV_NAME === 'development' ||
        process.env.REACT_APP_ENV_NAME === 'staging') && <StateTester />}
    </>
  );
};

ReactModal.setAppElement('#root');

export default App;
