import React, { FunctionComponent, useContext } from 'react';
import { motion } from 'framer-motion';
import styles from './styles.module.scss';
import { useReducedMotion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { isAnimatingLayout } from 'features/ui/uiSlice';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useLocation } from 'react-router-dom';
import { usePageLoaded } from 'hooks/usePageLoaded';

interface PageAnimationFullScreenProps {
  children?: React.ReactNode;
}

export const PageAnimationFullScreen: FunctionComponent<
  PageAnimationFullScreenProps
> = (props) => {
  const shouldReduceMotion = useReducedMotion();
  const dispatch = useDispatch();
  const location = useLocation() as any;
  const { isNativeAppWebview } = useContext(UserAgentContext);

  usePageLoaded();

  const onAnimationStart = () => {
    if (!location.state) {
      dispatch(isAnimatingLayout(true));
    }
  };

  const onAnimationComplete = () => {
    dispatch(isAnimatingLayout(false));
    window.history.replaceState({}, document.title);
  };

  const animateTransition: boolean = location.state && location.state.animate;
  const animationAttributes = isNativeAppWebview
    ? {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.2 }
      }
    : {
        initial: { opacity: 0, y: animateTransition ? '100%' : 0 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: animateTransition ? '100%' : 0 },
        transition: { duration: shouldReduceMotion ? 0 : 0.3 }
      };

  return (
    <motion.div
      className={styles['page__full-screen']}
      {...animationAttributes}
      onAnimationStart={onAnimationStart}
      onAnimationComplete={onAnimationComplete}
    >
      {props.children}
    </motion.div>
  );
};
