import React, { FunctionComponent, ReactNode } from 'react';

interface FullScreenControlsProps {
  children: ReactNode[];
}

export const FullScreenControls: FunctionComponent<FullScreenControlsProps> = ({
  children
}) => {
  if (!children) {
    return null;
  }

  return (
    <ul className="tw-flex tw-list-none tw-p-0 tw-m-0 tw-my-4 md:tw-my-6 [.embed_&]:tw-m-0 tw-space-x-4 [.ios_&]:tw-space-x-2 tw-relative tw-z-30 tw-justify-center tw-items-center">
      {children.map((child: ReactNode, i) => (
        <li key={i}>{child}</li>
      ))}
    </ul>
  );
};
