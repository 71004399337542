import React, { FunctionComponent, memo } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';

interface VisualizerFallbackProps {
  numberOfBars: number;
  isMini?: boolean;
}

export const VisualizerFallback: FunctionComponent<VisualizerFallbackProps> = ({
  numberOfBars,
  isMini
}) => {
  const isAnimatingLayout = useSelector(
    (state: RootState) => state.ui.isAnimatingLayout
  );

  const loading = useSelector((state: RootState) => state.audio.loading);
  const playing = useSelector((state: RootState) => state.audio.playing);
  const connecting = useSelector((state: RootState) => state.audio.connecting);

  const VisualizerBars = () => {
    return (
      <AnimatePresence>
        {!isAnimatingLayout && (
          <motion.div
            className={classNames(styles['player-visualizer__fallback'], {
              [styles['player-visualizer__fallback--mini']]: isMini,
              [styles['player-visualizer__fallback--empty']]: connecting
            })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {[...Array(Math.floor(numberOfBars))].map((e, i) => {
              return <div key={i} id={`bar${i}`}></div>;
            })}
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  if (!playing || loading) {
    return null;
  }

  return <VisualizerBars />;
};

export default memo(VisualizerFallback);
