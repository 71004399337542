"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.soundcloud = void 0;
var types_1 = require("../types");
exports.soundcloud = {
    name: 'soundcloud',
    matches: [
        {
            match: '(https?://)?(www.)?soundcloud.com/({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://soundcloud.com/{PROFILE_ID}',
        },
        { match: '({PROFILE_ID})', group: 1 },
    ],
};
