import React, { FunctionComponent, ReactNode } from 'react';
import { FullScreenWrapper } from 'components/Layouts/FullScreenView/FullScreenWrapper';
import { EventModel } from 'features/events/EventModel';
import { UnlistedBanner } from '../../components/UnlistedBanner';

interface EventWrapperProps {
  children: ReactNode;
  event: EventModel | null;
}

export const EventWrapper: FunctionComponent<EventWrapperProps> = ({
  children,
  event
}) => {
  if (!event) {
    return null;
  }

  return (
    <FullScreenWrapper
      artwork={event.artwork}
      artworkMode={event.artworkMode}
      updatedTimestamp={event.lastUpdatedAt}
      type={'event'}
      color={event.color}
    >
      <UnlistedBanner />
      {children}
    </FullScreenWrapper>
  );
};
