import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useReducer
} from 'react';
import { Icon } from '../Icons';
import { useCurrentEvent } from 'features/events/eventHooks';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { useAppSelector } from 'hooks/redux';
import { currentBroadcastListenerCountSelector } from 'features/broadcast/selectors';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useMediaQuery } from 'react-responsive';
import { pluralize } from 'utilities/formatters';
import { useIsBroadcaster } from 'features/current-user/hooks';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const ListenerCount: FunctionComponent = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const event = useCurrentEvent();
  const listenerCount = useAppSelector(currentBroadcastListenerCountSelector);
  const isBroadcaster = useIsBroadcaster();
  const isPageVisible = usePageVisibility();
  const playing = useSelector((state: RootState) => state.audio.playing);
  const hideText = useMediaQuery({
    query: '(max-width: 340px)'
  });
  const { isCreatorAppWebview } = useContext(UserAgentContext);

  const labelCount = () => {
    if (!listenerCount && playing) {
      if (hideText) {
        return '1';
      }
      return '1 Listener';
    }
    if (hideText) {
      return `${listenerCount || 0}`;
    }
    return pluralize(listenerCount || 0, 'Listener');
  };

  useEffect(() => {
    if (isPageVisible) {
      forceUpdate();
    }
  }, [isPageVisible]);

  if (event && !event.statsVisible && !(isCreatorAppWebview && isBroadcaster)) {
    return null;
  }

  return (
    <div
      data-testid="listener-count"
      className="tw-flex tw-items-center tw-space-x-[6px]"
    >
      <Icon
        icon={'listeners'}
        height={16}
        hidden={true}
        label={labelCount()}
        width={16}
      />
      <span>{labelCount()}</span>
    </div>
  );
};
