import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { getStore } from './store';
import UserAgentProvider from 'features/user-agent/userAgent';
import ThemeProvider from 'features/theme/theme';
import { WebsocketProvider } from 'features/hub/hub';
import { DataProvider } from 'api/dataProvider';
import { AudioPlayerProvider } from 'features/audio/audio';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactGA.initialize(process.env.REACT_APP_GA4_ID);

// Remove console logs on production
if (process.env.REACT_APP_ENV_NAME === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={getStore()}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      >
        <DataProvider>
          <UserAgentProvider>
            <WebsocketProvider>
              <AudioPlayerProvider>
                <ThemeProvider>
                  <Router>
                    <App />
                  </Router>
                </ThemeProvider>
              </AudioPlayerProvider>
            </WebsocketProvider>
          </UserAgentProvider>
        </DataProvider>
      </GoogleReCaptchaProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
