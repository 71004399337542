import React, { FunctionComponent, ReactNode } from 'react';
import { Button } from 'components/Buttons/Button';
import { Loader } from 'components/Loader';

interface FormSubmitButtonProps {
  label: string | ReactNode;
  disabled?: boolean;
}

export const FormSubmitButton: FunctionComponent<FormSubmitButtonProps> = (
  props
) => {
  return (
    <Button disabled={props.disabled}>
      {props.disabled ? <Loader fillComponent={true} /> : <>{props.label}</>}
    </Button>
  );
};
