import { Loader } from 'components/Loader';
import React, { FunctionComponent } from 'react';
import { FullImage } from 'components/Images/FullImage';
import { useAppSelector } from 'hooks/redux';
import { SquareImage } from 'components/Images/SquareImage';
import parse from 'html-react-parser';

export const ChannelDetails: FunctionComponent = () => {
  const channel = useAppSelector((state) => state.channel.channelDetails);
  const channelisLoaded = useAppSelector((state) => state.channel.isLoaded);

  if (!channelisLoaded || channel == null) {
    return <Loader fillComponent={true} />;
  }

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-relative tw-h-full tw-w-full tw-p-8">
      <FullImage
        artworkUrl={channel.channelArtwork.image_blur.large || null}
        artworkMode={channel.artworkKey ? 'channel' : 'theme'}
        type={'event'}
        color={channel.themeColor}
      />
      <div className="tw-max-w-[280px] tw-w-full tw-relative tw-z-20 tw-space-y-4 md:tw-space-y-8">
        <SquareImage
          artworkUrl={channel.channelArtwork.image.large}
          cssClasses={'tw-shadow-glow'}
        />
        <h1 className="tw-text-white tw-text-4xl sm:tw-text-6xl tw-text-center">
          {parse(
            `<span class="tw-text-2xl tw-block tw-mb-2">Welcome to</span> ${channel.username}`
          )}
        </h1>
      </div>
    </div>
  );
};
