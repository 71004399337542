"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.behance = void 0;
var types_1 = require("../types");
exports.behance = {
    name: 'behance',
    matches: [
        {
            match: '(https?://)?(www.)?behance.net/({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://behance.net/{PROFILE_ID}'
        },
        { match: '({PROFILE_ID})', group: 1 },
    ]
};
