import { createSelector } from 'reselect';
import { RootState } from 'rootReducer';
import { EventMap } from 'features/events/eventsSlice';
import { EventModel, compareByDate } from 'features/events/EventModel';

const wrapEventModel = (events: EventMap): EventModel[] => {
  return Object.keys(events).reduce(
    (eventsArray: EventModel[], key: string): EventModel[] => {
      eventsArray.push(EventModel(events[key]));

      return eventsArray;
    },
    []
  );
};

export const upcomingOrLiveSelector = createSelector(
  [(state: RootState) => state.events, (state: RootState) => state.broadcasts],
  (events): EventModel[] => {
    return wrapEventModel({
      ...events.events,
      ...events.privateEvents
    })
      .filter((event) => event.isUpcomingOrLive())
      .filter((event) => event.isListed())
      .sort(compareByDate);
  }
);

// Upcoming currently defined as:
//   Current time is before the event's scheduled end at
//   Event is not live (Current broadcast's event id does not match)
export const upcomingEventSelector = createSelector(
  [(state: RootState) => state.events, (state: RootState) => state.broadcasts],
  (events): EventModel[] => {
    const upcomingEvents = wrapEventModel({
      ...events.events,
      ...events.privateEvents
    })
      .filter((event) => event.isUpcoming())
      .filter((event) => event.isListed());

    return upcomingEvents.sort(compareByDate).slice(0, 4);
  }
);

export const liveEventSelector = createSelector(
  // We need to select broadcasts here so that this is updated
  // when the broadcasts state changes (which affects isLive)
  [(state: RootState) => state.events, (state: RootState) => state.broadcasts],
  (events): EventModel | null => {
    const liveEvents = wrapEventModel({
      ...events.events,
      ...events.privateEvents
    })
      .filter((event) => event.isLive())
      .filter((event) => event.isListed());

    return liveEvents[0];
  }
);

export const eventSelector = createSelector(
  [
    (state: RootState) => state.events.events,
    (state: RootState, id?: string | null) => id
  ],
  (events: EventMap, id?: string | null): EventModel | null => {
    if (!id) {
      return null;
    }
    const event = events[id];

    if (!event) {
      return null;
    }

    return EventModel(event);
  },
  {
    memoizeOptions: {
      maxSize: 10
    }
  }
);

export const privateEventSelector = createSelector(
  [
    (state: RootState) => state.events.privateEvents,
    (state: RootState, id?: string | null) => id
  ],
  (events: EventMap, id?: string | null): EventModel | null => {
    if (!id) {
      return null;
    }
    const event = events[id];

    if (!event) {
      return null;
    }

    return EventModel(event);
  }
);
