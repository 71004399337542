"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stackoverflow = void 0;
var types_1 = require("../types");
exports.stackoverflow = {
    name: 'stackoverflow',
    matches: [
        {
            match: '(https?://)?(www.)?stackoverflow.com/users/({PROFILE_ID})(/[A-Za-z0-9_\\-\\.]+)?/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://stackoverflow.com/users/{PROFILE_ID}'
        },
        { match: '({PROFILE_ID})', group: 1 },
    ]
};
