import React, { FunctionComponent } from 'react';
import { ErrorWrapper } from 'components/ErrorPages/ErrorWrapper';

interface ErrorDeletedProps {
  type: 'event' | 'recording' | 'private';
}

export const ErrorDeleted: FunctionComponent<ErrorDeletedProps> = ({
  type
}) => {
  return (
    <ErrorWrapper type={type}>
      <h1>{type} deleted</h1>
    </ErrorWrapper>
  );
};
