import { AppThunk } from 'store';
import { HubEventMessage } from 'features/hub/hub';
import { setAutoplay } from 'features/events/eventsSlice';
import { NavigateFunction } from 'react-router-dom';
import { LoadAudioFunction } from 'features/audio/audio';
import { loadEvent } from 'thunks/navigationThunks';
import { nextLiveBroadcastSelector } from 'features/broadcast/selectors';
import { eventSelector } from 'features/events/eventsSelectors';

export const queueAutoPlay =
  ({ event: newEvent }: HubEventMessage): AppThunk =>
  async (dispatch, getState) => {
    const {
      events: { currentEventId }
    } = getState();

    if (currentEventId !== newEvent.id.toString()) {
      dispatch(setAutoplay(true));
    }
  };

export const autoplayNextEvent =
  (
    loadAudio: LoadAudioFunction,
    navigate: NavigateFunction,
    isListenerAppWebView: boolean,
    isEmbed: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    const broadcast = nextLiveBroadcastSelector(getState());

    const {
      channel: { miniPlayerVisible }
    } = getState();

    if (!broadcast || !broadcast.eventId) {
      if (isEmbed) {
        navigate(`/embed`, { state: { animate: false } });
      }
      return;
    }

    const event = eventSelector(getState(), broadcast.eventId);

    if (miniPlayerVisible) {
      dispatch(loadEvent(broadcast.eventId));
    } else {
      navigate(`/events/${broadcast.eventId}`, { state: { animate: false } });
    }
    if (event && !isListenerAppWebView) {
      loadAudio({
        audioSrc: broadcast.url,
        autoPlay: true,
        audioType: 'event'
      });
    }
    dispatch(setAutoplay(false));
  };
