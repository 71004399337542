import { queueAutoPlay } from 'features/events/thunks';
import { addNewMessage, deleteMessageSuccess } from 'features/chat/chatSlice';
import {
  setCrowdList,
  addCrowdMember,
  removeCrowdMembers
} from 'features/crowd/crowdSlice';
import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import ReconnectingWebSocket from 'reconnecting-websocket';
import {
  setSocketId,
  setHubBroadcastConnected,
  setHubConnected,
  setHubCrowdConnected
} from './hubSlice';
import {
  updateEvent,
  fetchEvent,
  updateOrFetchEvent,
  addEvents,
  removeEvent
} from 'features/events/eventsSlice';
import {
  mapHubEventToEvent,
  mapHubRecordingToRecording
} from 'features/hub/stateMappers';
import {
  handleBroadcastFinished,
  handleEventStopped,
  handleListenerCountMessage,
  handleActionCreated
} from 'features/hub/hubThunks';
import { useSubdomain } from 'hooks/useSubdomain';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { useCurrentUser } from 'features/current-user/hooks';
import { mapHubBroadcastStart } from 'features/broadcast/mappers';
import { addBroadcast } from 'features/broadcast/broadcastsSlice';
import { createAlert } from 'features/alert/alertSlice';
import { UserAgentContext } from 'features/user-agent/userAgent';
import {
  handleRecordingCreated,
  handleRecordingTranscoded,
  removeRecording,
  resetPaginationStartIds,
  updateRecording
} from 'features/recordings/recordingsSlice';
import { useAppSelector } from 'hooks/redux';
import { Media } from 'api/v3/types/media';

interface ContextProps {
  connectToSocket: () => void;
  disconnectFromSocket: () => void;
  subscribeToBroadcast: (value: string) => void;
  disconnectBroadcast: (value: string) => void;
  joinCrowd: () => void;
  leaveCrowd: () => void;
}

const WebSocketContext = createContext({} as ContextProps);

export { WebSocketContext };

export interface SocketMessage {
  channel: string;
  type: string;
  data: unknown;
}

export interface HubCommentCreatedMessage {
  id: number;
  content: string;
  name: string;
  user_id: string;
  user_slug: string;
  created_at: Date;
  image_url: string;
}

export interface HubCommentDeletedMessage {
  comment_id: number;
}

export interface HubBroadcastStart {
  broadcast: HubBroadcastState;
}

export interface HubBroadcastState {
  uid: string;
  title: string;
  duration: number | null;
  category_name: string;
  streams: HubBroadcastStreams;
  listener_count: number;
  heart_count?: number;
  started_at: string;
  event_id: string | null;
}

interface HubBroadcastStreams {
  progressive: HubBroadcastStreamUrl;
}

interface HubBroadcastStreamUrl {
  url: string;
}

export interface HubMember {
  id: string;
  user: {
    id: string;
    username: string;
    profile_image_url: string;
    entered_at: number;
  };
}

export interface HubActionCreated {
  total: number;
  broadcast_uid: string;
  username: string;
  user_id: number;
  created_at: string;
}

export interface HubActionHeartCreated {
  display_name: string;
  user_id: number;
  created_at: string;
}

export interface HubListenerCount {
  count: number;
}

export interface HubEventPayload {
  id: number;
  starts_at: string;
  ends_at: string;
  started_at: string;
  ended_at: string;
  active: boolean;
  title: string;
  description: string;
  artwork: Record<string, never> | { url: string; original_key: string };
  artwork_mode: string;
  timezone: string;
  adhoc: boolean | null;
  accesscode_enabled: boolean | null;
  chat_enabled: boolean | null;
  hearting_enabled: boolean | null;
  stats_visible: boolean | null;
  broadcaster: {
    id: number;
  };
  category: {
    id: number;
  };
  url: string;
  access_level: string;
  updated_at: string;
  color: string;
  media: {
    artwork: Media;
  };
}

export interface HubEventMessage {
  event: HubEventPayload;
}

enum RecordingAccessLevel {
  public = 'public',
  only_me = 'only_me',
  unlisted = 'unlisted'
}

export interface HubRecordingPayload {
  access_level: RecordingAccessLevel;
  artwork_key: string | null;
  broadcast_id: number | null;
  bytes: number | null;
  category_id: number | null;
  created_at: string;
  deleted_at: string | null;
  description: string | null;
  duration: number | null;
  event_id: number | null;
  file_format: string | null;
  id: number;
  published: boolean;
  should_display_on_channel: boolean;
  saved_to_r2: boolean | null;
  stats: string | null;
  title: string;
  transcoding_failed_at: string | null;
  transcoding_failure_reason: string | null;
  transcoding_finished_at: string | null;
  transcoding_started_at: string | null;
  trash_after: string | null;
  uid: string;
  updated_at: string;
  url: string;
  user_id: number;
  color: string;
  media: {
    artwork: Media;
  };
  artwork_mode: string;
}

export interface HubRecordingMessage {
  recording: HubRecordingPayload;
}

// We declare the socket here outside the scope of the component
// so that with StrictMode 2 sockets aren't created
let socket: ReconnectingWebSocket | null = null;

interface HubProps {
  children: React.ReactNode;
}

export const WebsocketProvider = ({ children }: HubProps): React.ReactNode => {
  const channelId = useAppSelector(
    (state) => state.channel.channelDetails?.ownerId
  );

  const hubServer = process.env.REACT_APP_WSSERVER_URL;
  const dispatch = useDispatch();
  const subdomain = useSubdomain();
  const { postMessage } = useNativeEvents();
  const currentUser = useCurrentUser();
  const { isDACreatorApp, isEmbed } = useContext(UserAgentContext);

  const connectToSocket = () => {
    if (!channelId) {
      return;
    }

    if (!hubServer) {
      console.error('no wsserver environment file defined.');
      return;
    }

    if (!socket) {
      console.log('hubServer', hubServer);
      socket = new ReconnectingWebSocket(hubServer);

      const userChannel = `${process.env.REACT_APP_HUB_ENV};user;${channelId}`;

      socket.onopen = ({ target }) => {
        console.log('[open] Connection established');
        socket = target as ReconnectingWebSocket;
        connectToHub();
        dispatch(setHubConnected(true));
        console.log('env ', process.env.REACT_APP_HUB_ENV);
        socket.send(`subscribe:${userChannel}`);
      };

      socket.onmessage = (e) => {
        const data = JSON.parse(e.data);

        if (!data) {
          return;
        }

        if (!('type' in data)) {
          if ('socketId' in data) {
            dispatch(setSocketId(data.socketId));
          }

          return;
        }

        const jsonData = data as SocketMessage;
        switch (jsonData.type) {
          case 'comment:created': {
            if (jsonData.channel !== userChannel) {
              break;
            }
            console.log('[hub] Comment created', jsonData.data);
            const comment = jsonData.data as HubCommentCreatedMessage;
            dispatch(addNewMessage(comment));
            break;
          }
          case 'comment:deleted': {
            console.log('[hub] Comment deleted', jsonData.data);
            const { comment_id } = jsonData.data as HubCommentDeletedMessage;
            dispatch(deleteMessageSuccess(comment_id.toString()));
            break;
          }
          case 'broadcast:start': {
            console.log('[hub] Broadcast Start', jsonData.data);
            const broadcastData = data.data as HubBroadcastStart;
            postMessage('broadcastStarted');
            dispatch(addBroadcast(mapHubBroadcastStart(broadcastData)));
            break;
          }
          case 'listener_count':
            console.log('[hub] Listener Count', jsonData.data);
            dispatch(handleListenerCountMessage(data));
            break;
          case 'action:created':
            console.log('[hub] Action Created', jsonData.data);
            dispatch(handleActionCreated(data));
            break;
          case 'member:list': {
            console.log('[hub] Member List', jsonData.data);
            const members = jsonData.data as HubMember[];
            dispatch(setCrowdList(members));
            break;
          }
          case 'member:added': {
            console.log('[hub] Member Added', jsonData.data);
            const member = jsonData.data as HubMember[];
            dispatch(addCrowdMember(member));
            break;
          }
          case 'member:removed': {
            console.log('[hub] Member Removed', jsonData.data);
            const memberArray = jsonData.data as Array<string>;
            dispatch(removeCrowdMembers(memberArray));
            break;
          }
          case 'event:started': {
            console.log('[hub] Event started', jsonData.data);
            const message = jsonData.data as HubEventMessage;
            dispatch(
              updateOrFetchEvent(subdomain, message.event as HubEventPayload)
            );
            dispatch(queueAutoPlay(message));
            break;
          }
          case 'event:updated': {
            console.log('[hub] Event Updated', jsonData.data);
            const message = jsonData.data as HubEventMessage;
            const event = mapHubEventToEvent(message.event);
            if (
              event.accessLevel === 'only_me' &&
              (!currentUser ||
                (currentUser && event.broadcasterId !== currentUser.id))
            ) {
              dispatch(removeEvent(event.id));
            } else {
              dispatch(updateEvent(event));
              postMessage({
                eventTitle: message.event.title,
                categoryId: message.event.category.id
              });
            }
            if (isDACreatorApp) {
              dispatch(
                createAlert({ message: 'Event updated', type: 'success' })
              );
            }
            break;
          }
          case 'event:created': {
            console.log('[hub] Event Created', jsonData.data);
            const message = jsonData.data as HubEventMessage;
            const event = mapHubEventToEvent(message.event);
            if (event.accesscodeEnabled) {
              dispatch(fetchEvent(subdomain, event.id));
            } else {
              dispatch(addEvents([event]));
            }
            break;
          }
          case 'event:past': {
            console.log('[hub] Event Past', jsonData.data);
            if (isEmbed) {
              const message = jsonData.data as HubEventMessage;
              dispatch(removeEvent(message.event.id.toString()));
            }
            break;
          }
          case 'event:destroyed': {
            console.log('[hub] Event Destroyed', jsonData.data);
            const message = jsonData.data as HubEventMessage;
            dispatch(removeEvent(message.event.id.toString()));
            break;
          }
          case 'event:stopped': {
            console.log('[hub] Event Stopped', jsonData.data);
            dispatch(handleEventStopped(jsonData.data as HubEventMessage));
            break;
          }
          case 'finished':
            console.log('[hub] Broadcast Finished', jsonData.data);
            dispatch(handleBroadcastFinished(data, disconnectBroadcast));
            break;
          case 'recording:created': {
            console.log('[hub] Recording Created', jsonData.data);
            const message = jsonData.data as HubRecordingMessage;
            console.log('[hub] message =', message);
            const recording = mapHubRecordingToRecording(message.recording);
            dispatch(handleRecordingCreated(recording));
            break;
          }
          case 'recording:transcoded': {
            console.log('[hub] Recording Transcoded', jsonData.data);
            const message = jsonData.data as HubRecordingMessage;
            console.log('[hub] message =', message);
            const recording = mapHubRecordingToRecording(message.recording);
            dispatch(handleRecordingTranscoded(recording));
            break;
          }
          case 'recording:updated': {
            console.log('[hub] Recording Updated', jsonData.data);
            const message = jsonData.data as HubRecordingMessage;
            console.log('[hub] message =', message);
            const recording = mapHubRecordingToRecording(message.recording);
            console.log('[hub] recording =', recording);
            dispatch(updateRecording(recording));
            break;
          }
          case 'recording:destroyed': {
            console.log('[hub] Recording Destroyed', jsonData.data);
            const message = jsonData.data as HubRecordingMessage;
            dispatch(removeRecording(message.recording.id.toString()));
            dispatch(resetPaginationStartIds());
            break;
          }
          default:
            console.log(
              `Unknown message type: ${jsonData.type} with data: ${jsonData.data}`
            );
        }
      };

      socket.onclose = (event) => {
        dispatch(setHubConnected(false));
        dispatch(setHubBroadcastConnected(false));
        dispatch(setHubCrowdConnected(false));
        if (event.wasClean) {
          console.log(
            `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
          );
        } else {
          // e.g. server process killed or network down
          // event.code is usually 1006 in this case
          console.log('[close] Connection died');
        }
      };
    }
  };

  const disconnectFromSocket = () => {
    if (socket) {
      socket.close();
    }
  };

  const connectToHub = () => {
    let auth = {};

    if (currentUser) {
      auth = {
        id: currentUser.id,
        username: currentUser.username,
        profile_image_url: currentUser.profile_image_url
      };

      if (socket?.readyState === WebSocket.OPEN) {
        console.log('[Hub] - Connecting to hub');
        socket.send(`authenticate:${JSON.stringify(auth)}`);
      }
    }
  };

  // Subscribe to Broadcast Channel when broadcast is started
  const subscribeToBroadcast = (broadcastUID: string) => {
    if (socket?.readyState === WebSocket.OPEN) {
      console.log('[Hub] - Subscribing to broadcast');
      socket.send(
        `subscribe:${process.env.REACT_APP_HUB_ENV};broadcast;${broadcastUID}`
      );
      dispatch(setHubBroadcastConnected(true));
      console.log(`[broadcast] Connected Broadcast UID: ${broadcastUID}`);
    } else {
      console.log('[broadcast] Connection not open - not connecting broadcast');
    }
  };

  const disconnectBroadcast = (broadcastUID: string) => {
    if (socket?.readyState === WebSocket.OPEN) {
      console.log('[Hub] - Unsubscribing broadcast');
      socket.send(
        `unsubscribe:${process.env.REACT_APP_HUB_ENV};broadcast;${broadcastUID}`
      );
      dispatch(setHubBroadcastConnected(false));
      console.log(`[broadcast] Unsubscribing broadcast: ${broadcastUID}`);
    } else {
      console.log('[broadcast] Connection not open - not connecting broadcast');
    }
  };

  const joinCrowd = () => {
    connectToHub();
    if (socket?.readyState === WebSocket.OPEN) {
      console.log('[Hub] - Subscribing to crowd');
      socket.send(
        `crowd:presence-${process.env.REACT_APP_HUB_ENV};crowd;${channelId}`
      );
      dispatch(setHubCrowdConnected(true));
      console.log(`[Hub] - Connected crowd ID: ${channelId}`);
    } else {
      console.log('[broadcast] Connection not open - not connecting crowd');
    }
  };

  const leaveCrowd = () => {
    if (socket?.readyState === WebSocket.OPEN) {
      socket.send(
        `leave_crowd:presence-${process.env.REACT_APP_HUB_ENV};crowd;${channelId}`
      );
      dispatch(setHubCrowdConnected(false));
      console.log(`[Hub] - Unsubscribing from crowd: ${channelId}`);
    } else {
      console.log('[crowd] Connection not open - not unsubscribing from crowd');
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        connectToSocket,
        disconnectFromSocket,
        subscribeToBroadcast,
        disconnectBroadcast,
        joinCrowd,
        leaveCrowd
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
