import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEvent } from 'features/events/eventHooks';
import { EventDate } from 'features/events/EventDate';
import { EventCountdownTimer } from 'features/events/components/EventCountdownTimer';
import { FullScreenTitle } from 'components/Layouts/FullScreenView/FullScreenTitle';

export const EventCountdown: FunctionComponent = () => {
  const { id } = useParams();
  const event = useEvent(id);

  const calculateTimeLeft = (): string | undefined => {
    return event?.durationToEvent()?.toFormat('hh:mm:ss');
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isOverdue, setIsOverdue] = useState(event?.isOverdue());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      setIsOverdue(event?.isOverdue());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const Overdue = () => {
    if (event?.endedAt) {
      return (
        <p className="tw-mb-2">
          This live event has ended, stay tuned for the next!
        </p>
      );
    } else {
      return <p className="tw-mb-2">This event has not started yet.</p>;
    }
  };

  if (!event) {
    return null;
  }

  if (event.isAdhoc()) {
    return (
      <div className="tw-text-center tw-mx-auto tw-max-w-[620px] tw-full">
        <FullScreenTitle title={event.title} />
        <Overdue />
      </div>
    );
  }

  return (
    <div className="tw-text-center tw-mx-auto tw-max-w-[620px] tw-full tw-space-y-4">
      <FullScreenTitle title={event.title} />
      <EventDate eventId={event.id} hideTime={event.isIn24hrs()} />
      {isOverdue || event.startedAt ? (
        <Overdue />
      ) : (
        <EventCountdownTimer event={event} timeLeft={timeLeft} />
      )}
    </div>
  );
};
