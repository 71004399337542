import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { setSearchType } from 'features/search/searchSlice';
import { Button } from 'components/Buttons/Button';
import { useAppSelector } from 'hooks/redux';
import styles from './styles.module.scss';

export const SearchFilters: FunctionComponent = () => {
  const dispatch = useDispatch();
  const searchType = useAppSelector((state) => state.search.searchType);

  const setSearchTypeEvents = () => {
    dispatch(setSearchType('events'));
  };

  const setSearchTypeRecordings = () => {
    dispatch(setSearchType('recordings'));
  };

  return (
    <div className={styles['search__filters']}>
      <Button onClick={setSearchTypeEvents} disabled={searchType === 'events'}>
        Events
      </Button>
      <Button
        onClick={setSearchTypeRecordings}
        disabled={searchType === 'recordings'}
      >
        Recordings
      </Button>
    </div>
  );
};
