import { SmallButton } from 'components/Buttons/Button';
import {
  blockUserFromChat,
  unblockUserFromChat
} from 'features/chat/chatSlice';
import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

export interface BlockButtonsProps {
  selectedProfileId: string;
  blockedIds: string[];
}

export const BlockButtons: FunctionComponent<BlockButtonsProps> = ({
  blockedIds,
  selectedProfileId
}: BlockButtonsProps) => {
  const isBlocked: boolean = blockedIds.includes(selectedProfileId);
  const dispatch = useDispatch();

  if (isBlocked) {
    return (
      <SmallButton
        onClick={() => {
          dispatch(unblockUserFromChat(selectedProfileId));
        }}
      >
        Unblock
      </SmallButton>
    );
  } else {
    return (
      <SmallButton
        onClick={() => {
          dispatch(blockUserFromChat(selectedProfileId));
        }}
      >
        Block
      </SmallButton>
    );
  }
};
