import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import { Event } from 'features/events/eventsSlice';
import { Card } from 'components/Card';

interface SearchResultEventProps {
  lastElement: boolean;
  result: Event;
}

const SearchResultEvent: ForwardRefRenderFunction<
  HTMLLIElement,
  SearchResultEventProps
> = (props, ref) => {
  return (
    <li
      className="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-4"
      ref={props.lastElement ? ref : null}
    >
      <Card
        id={props.result.id}
        url={`/events/${props.result.id}`}
        title={props.result.title}
        artwork={props.result.artwork}
        artworkKey={props.result.artworkKey}
        artworkMode={props.result.artworkMode}
        type={'event'}
        color={props.result.color}
      />
    </li>
  );
};

export default forwardRef<HTMLLIElement, SearchResultEventProps>(
  SearchResultEvent
);
