"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.youtube = void 0;
var types_1 = require("../types");
exports.youtube = {
    name: 'youtube',
    matches: [
        {
            match: '(https?://)?(www.)?youtube.com/@?({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://youtube.com/@{PROFILE_ID}'
        },
        {
            match: '(https?://)?(www.)?youtube.com/channel/({PROFILE_ID})/?', group: 3
        },
        {
            match: '(https?://)?(www.)?youtube.com/user/({PROFILE_ID})/?', group: 3
        },
        {
            match: '(https?://)?m.youtube.com/c/({PROFILE_ID})/?', group: 2
        },
        { match: '({PROFILE_ID})', group: 1 },
    ]
};
