"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.twitter = void 0;
var types_1 = require("../types");
exports.twitter = {
    name: 'twitter',
    matches: [
        {
            match: '(https?://)?(www.)?twitter.com/@?({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://twitter.com/{PROFILE_ID}'
        },
        {
            match: '(https?://)?mobile.twitter.com/@?({PROFILE_ID})/?', group: 2, type: types_1.TYPE_MOBILE,
            pattern: 'https://mobile.twitter.com/{PROFILE_ID}'
        },
        { match: '@?({PROFILE_ID})', group: 1 },
    ]
};
