import React, { FunctionComponent, useContext } from 'react';
import { FullScreenContent } from 'components/Layouts/FullScreenView/FullScreenContent';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { RecordingPlayer } from 'features/recordings/components/RecordingPlayer';
import { FullScreenDetails } from 'components/Layouts/FullScreenView/FullScreenDetails';
import { RecordingMeta } from './RecordingMeta';
import { FullScreenControls } from 'components/Layouts/FullScreenView/FullScreenControls';
import { Share } from 'components/Share';
import { SquareImage } from 'components/Images/SquareImage';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { RecordingInfo } from './RecordingInfo';

interface RecordingWrapperProps {
  recording: RecordingModel | null;
}

export const RecordingContent: FunctionComponent<RecordingWrapperProps> = ({
  recording
}) => {
  const { isListenerAppWebview } = useContext(UserAgentContext);

  if (!recording) {
    return null;
  }

  return (
    <FullScreenContent
      centerColumn={
        <>
          <FullScreenDetails
            title={recording.title}
            image={
              isListenerAppWebview ? null : (
                <SquareImage
                  artworkUrl={recording.artwork.image.large}
                  cssClasses={'tw-shadow-glow'}
                />
              )
            }
            meta={<RecordingMeta />}
            controls={
              <FullScreenControls>
                <Share title={recording.title} label={'recording'} />
                <RecordingInfo recording={recording} />
              </FullScreenControls>
            }
          />
        </>
      }
      player={<RecordingPlayer />}
      renderAtBottom={isListenerAppWebview}
    />
  );
};
