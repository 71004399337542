import React, { FunctionComponent } from 'react';
import { EventModel } from 'features/events/EventModel';
import { FullScreenDetails } from 'components/Layouts/FullScreenView/FullScreenDetails';
import { FullScreenFooter } from 'components/Layouts/FullScreenView/FullScreenFooter';
import { EventMeta } from 'features/events/components/EventMeta';
import { ListenerCount } from 'components/ListenerCount';
import { useIsEventLive } from 'features/events/eventHooks';
import { HeartButtonWrapper } from 'components/Hearts/HeartButtonWrapper';
import { Share } from 'components/Share';
import { FullScreenControls } from 'components/Layouts/FullScreenView/FullScreenControls';
import { VolumeControl } from 'components/Player/VolumeControl';

interface EventFooterProps {
  event: EventModel | null;
}

export const EventEmbedFooter: FunctionComponent<EventFooterProps> = ({
  event
}) => {
  const isLive = useIsEventLive(event);

  if (!event) {
    return null;
  }

  if (isLive) {
    return (
      <FullScreenFooter
        leftColumn={
          <>
            <VolumeControl />
            <FullScreenDetails
              title={event.title}
              meta={
                <>
                  <EventMeta />
                  <ListenerCount />
                </>
              }
            />
          </>
        }
        rightColumn={
          <FullScreenControls>
            <HeartButtonWrapper />
            <Share title={event.title} label={'event'} />
          </FullScreenControls>
        }
      />
    );
  }

  return null;
};
