import React, { FunctionComponent, useContext } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { UserAgentContext } from 'features/user-agent/userAgent';

interface LogoImageProps {
  children: React.ReactNode;
  size?: string;
  hasFallback?: boolean;
  isLink?: boolean;
}

export const LogoImage: FunctionComponent<LogoImageProps> = (props) => {
  const { children, size, hasFallback, isLink } = props;
  const { isEmbed } = useContext(UserAgentContext);

  const onClick = () => {
    window.open('/', '_blank');
  };

  const renderLink = isEmbed && !(isLink === false);

  const onClickHandler = renderLink ? onClick : undefined;

  return (
    <div
      className={classNames(
        styles['logo-image'],
        { [styles['logo-image--fallback']]: hasFallback },
        { [styles['logo-image--medium']]: size === 'medium' },
        { [styles['logo-image--small']]: size === 'small' },
        { [styles['logo-image--mini']]: size === 'mini' },
        { [styles['logo-image--tiny']]: size === 'tiny' },
        { [styles['logo-image--embed']]: size === 'embed' }
      )}
      onClick={onClickHandler}
    >
      {children}
    </div>
  );
};
