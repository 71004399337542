import { createSelector } from 'reselect';
import { RootState } from 'rootReducer';
import { Recording, RecordingMap } from 'features/recordings/recordingsSlice';
import {
  RecordingModel,
  compareRecordingsById
} from 'features/recordings/RecordingModel';

export const wrapRecordingModel = (
  recordings: RecordingMap
): RecordingModel[] => {
  return Object.keys(recordings).reduce(
    (recordingsArray: Recording[], key: string) => {
      recordingsArray.push(RecordingModel(recordings[key]));
      return recordingsArray;
    },
    []
  ) as RecordingModel[];
};

export const allPublicRecordingsSelector = createSelector(
  (state: RootState) => state.recordings.recordings,
  (recordings): RecordingModel[] => {
    const allPublicRecordings = wrapRecordingModel({
      ...recordings
    })
      .filter(
        (recording) => recording.isPublic() && recording.shouldDisplayOnChannel
      )
      .sort(compareRecordingsById);

    return allPublicRecordings;
  }
);

export const latestRecordingsSelector = createSelector(
  (state: RootState) => state.recordings.recordings,
  (recordings): RecordingModel[] => {
    const allPublicRecordings = wrapRecordingModel({
      ...recordings
    })
      .filter(
        (recording) => recording.isPublic() && recording.shouldDisplayOnChannel
      )
      .sort(compareRecordingsById);

    return allPublicRecordings.slice(0, 4);
  }
);

export const recordingSelector = createSelector(
  [
    (state: RootState) => state.recordings.recordings,
    (state: RootState, id?: string | null) => id
  ],
  (recordings: RecordingMap, id?: string | null): RecordingModel | null => {
    if (!id) {
      return null;
    }
    const recording = recordings[id];

    if (!recording) {
      return null;
    }

    return RecordingModel(recording);
  },
  {
    memoizeOptions: {
      maxSize: 10
    }
  }
);
