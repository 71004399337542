import { V3Collections } from 'api/v3/types/collections';
import { V3CollectionAttributes } from 'api/v3/types/collections';
import { V3CollectionRecordings } from './types/collection_recordings';

const perFetch = 20;

export async function apiGetCollections(
  subdomain: string,
  pageNumber: number
): Promise<V3Collections> {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/v3/channels/${subdomain}/collections?page[size]=${perFetch}&page[number]=${pageNumber}`;
  const results = await fetch(url, { credentials: 'include' });

  if (results.ok) {
    return await results.json();
  } else {
    throw results.body;
  }
}

export async function apiGetCollection(
  channelSubdomain: string,
  collectionId: string
): Promise<V3CollectionAttributes> {
  const result = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/channels/${channelSubdomain}/collections/${collectionId}`
  );

  if (result.ok) {
    const json = await result.json();

    return json.data;
  } else {
    throw result;
  }
}

export async function apiGetCollectionRecordings(
  channelSubdomain: string,
  collectionId: string,
  pageNumber: number
): Promise<V3CollectionRecordings> {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/v3/channels/${channelSubdomain}/collections/${collectionId}/recordings?page[size]=${perFetch}&page[number]=${pageNumber}`;
  const results = await fetch(url, { credentials: 'include' });

  if (results.ok) {
    return await results.json();
  } else {
    throw results.body;
  }
}
