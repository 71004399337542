"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vk = void 0;
var types_1 = require("../types");
exports.vk = {
    name: 'vk',
    matches: [
        {
            match: '(https?://)?(www.)?vk.com/({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://vk.com/{PROFILE_ID}'
        },
        {
            match: '(https?://)?m.vk.com/@?({PROFILE_ID})/?', group: 2, type: types_1.TYPE_MOBILE,
            pattern: 'https://m.vk.com/{PROFILE_ID}'
        },
        { match: '@?({PROFILE_ID})', group: 1 },
    ]
};
