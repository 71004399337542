import React, { FunctionComponent, useContext } from 'react';
import { FullScreenHeader } from 'components/Layouts/FullScreenView/FullScreenHeader';
import { UserAgentContext } from 'features/user-agent/userAgent';

interface EventHeaderProps {
  minimiseUrl?: string;
}

export const EventHeader: FunctionComponent<EventHeaderProps> = ({
  minimiseUrl
}) => {
  const { isCreatorAppWebview } = useContext(UserAgentContext);

  if (isCreatorAppWebview) {
    return null;
  }

  return <FullScreenHeader minimiseUrl={minimiseUrl} />;
};
