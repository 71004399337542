"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.substack = void 0;
var types_1 = require("../types");
exports.substack = {
    name: 'substack',
    matches: [
        {
            match: '(https?://)?({PROFILE_ID}).substack.com/?', group: 2, type: types_1.TYPE_DESKTOP,
            pattern: 'https://{PROFILE_ID}.substack.com'
        },
        { match: '({PROFILE_ID})', group: 1 },
    ]
};
