import React, { FunctionComponent } from 'react';
import fallbackImage from 'images/mixlr-logo-single.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as EventBackground } from 'images/event-background.svg';
import { ReactComponent as RecordingBackground } from 'images/recording-background.svg';

interface ThumbImageProps {
  artworkUrl: string | null;
  artworkMode?: string | undefined;
  className?: string;
  alt?: string;
  type?: 'event' | 'recording';
}

export const ThumbImage: FunctionComponent<ThumbImageProps> = ({
  artworkUrl,
  artworkMode,
  className,
  alt,
  type
}) => {
  const addDefaultSrc = (event: any): void => {
    if (!event.target.src.endsWith(fallbackImage)) {
      event.target.src = fallbackImage;
    }
  };

  if (!artworkUrl && artworkMode === 'logo') {
    return (
      <img src={fallbackImage} alt="" className={styles['logo-fallback']} />
    );
  }

  if (!artworkUrl || artworkMode === 'theme') {
    return type === 'recording' ? <RecordingBackground /> : <EventBackground />;
  }

  return (
    <img
      src={artworkUrl}
      alt={alt ?? ''}
      loading="lazy"
      className={classNames(className, {
        [styles['logo-fallback']]: !artworkUrl
      })}
      onError={addDefaultSrc}
    />
  );
};
