// Es lint disabled for these as the escape characters are indeed necessary

//eslint-disable-next-line
export const iosCreatorUARegex = /\Mixlr iOS Creator app v([\w\.]+)/;

//eslint-disable-next-line
export const iosListenerUARegex = /\Mixlr iOS Listener app v([\w\.]+)/;

//eslint-disable-next-line
export const androidCreatorUARegex = /\Mixlr Android Creator app v([\w\.]+)/;

//eslint-disable-next-line
export const androidListenerUARegex = /\Mixlr Android app v([\w\.]+)/;

//eslint-disable-next-line
export const desktopUARegex = /\Mixlr (\w+) Creator app v([\w\.]+)/;

//eslint-disable-next-line
export const versionNumberRegex = /v\.?([0-9a-z]+(?:\.[0-9a-z]+)*)/i;

export const isNativeAppWebview = (): boolean => {
  return (
    iosCreatorUARegex.test(navigator.userAgent) ||
    iosListenerUARegex.test(navigator.userAgent) ||
    androidCreatorUARegex.test(navigator.userAgent) ||
    androidListenerUARegex.test(navigator.userAgent) ||
    desktopUARegex.test(navigator.userAgent)
  );
};
