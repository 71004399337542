import { userState } from 'features/current-user/currentUserSlice';
import { V3Me } from 'api/v3/types/me';

export const mapApiV3Me = ({
  data: { id, attributes: me }
}: V3Me): userState => {
  return {
    id: id.toString(),
    username: me.username,
    profile_image_url: me.profile_url,
    followedUserIds: [],
    blockedUserIds: me.blocked_user_ids
  };
};
