import React, { FunctionComponent, useContext } from 'react';
import { ReactComponent as MixlrLogo } from 'images/mixlr-logo.svg';
import styles from './styles.module.scss';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const ChannelFooter: FunctionComponent = () => {
  const url = process.env.REACT_APP_WEBSITE_ENDPOINT;
  const { isNativeAppWebview } = useContext(UserAgentContext);

  if (isNativeAppWebview) {
    return null;
  }

  return (
    <footer className={styles['mixlr-footer']}>
      Powered by
      <a href={url} className={styles['mixlr-footer__logo']}>
        <MixlrLogo />
      </a>
    </footer>
  );
};
