import React, { FunctionComponent } from 'react';
import { ReactComponent as RecordingsEmptySVG } from 'images/empty-recordings.svg';

export interface EmptyRecordingsProps {
  username: string;
  type: 'recordings' | 'collections';
}

export const EmptyRecordings: FunctionComponent<EmptyRecordingsProps> = ({
  username,
  type
}) => {
  return (
    <div className="empty-state">
      <div className="empty-state__content">
        <RecordingsEmptySVG />
        <p>
          {username} does not have any {type}.
        </p>
      </div>
    </div>
  );
};
