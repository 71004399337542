import React, { FunctionComponent } from 'react';
import { RootState } from 'rootReducer';
import { useSelector } from 'react-redux';

export const SubscriptionMessage: FunctionComponent = () => {
  const username = useSelector(
    (state: RootState) => state.channel.paidChannelDetails?.username || ''
  );

  return (
    <>
      <p className="intro mar-n">Subscribe to {username}&#39;s channel</p>
      <p>Get full access to live events and archived recordings</p>
    </>
  );
};
