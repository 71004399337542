import React, { FunctionComponent, useContext } from 'react';
import { FullScreenHeader } from 'components/Layouts/FullScreenView/FullScreenHeader';
import { UserAgentContext } from 'features/user-agent/userAgent';

interface RecordingHeaderProps {
  minimiseUrl: string;
}

export const RecordingHeader: FunctionComponent<RecordingHeaderProps> = ({
  minimiseUrl
}) => {
  const { isCreatorAppWebview } = useContext(UserAgentContext);

  if (isCreatorAppWebview) {
    return null;
  }

  return <FullScreenHeader minimiseUrl={minimiseUrl} />;
};
