import React, { FunctionComponent } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { VolumeControl } from './VolumeControl';
import { VisualizerToggle } from './VisualizerToggle';
import { useAppSelector } from 'hooks/redux';
import { IconButton } from 'components/Buttons/IconButton';
import { RewindButton } from './RewindButton';
import { ForwardButton } from './ForwardButton';
import { SeekBar } from './SeekBar';
import { Visualizer } from 'components/Player/Visualizer';
import { useLocalStorage } from 'usehooks-ts';

interface PlayerProps {
  showsControls?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  type: 'event' | 'recording';
}

export const Player: FunctionComponent<PlayerProps> = ({
  showsControls,
  onClick,
  type
}) => {
  const loading = useAppSelector((state) => state.audio.loading);
  const playing = useAppSelector((state) => state.audio.playing);
  const connecting = useAppSelector((state) => state.audio.connecting);
  const [visualizerVisible] = useLocalStorage('visualizer-visible', true);

  const stopOrPause = type === 'event' ? 'Stop' : 'Pause';

  const isAnimatingLayout = useAppSelector(
    (state) => state.ui.isAnimatingLayout
  );

  if (isAnimatingLayout) {
    return null;
  }

  return (
    <AnimatePresence>
      {!isAnimatingLayout && (
        <motion.div
          className={
            'tw-flex tw-items-center tw-flex-col tw-gap-4 [.embed_&]:tw-gap-0 tw-justify-center tw-mx-auto tw-max-w-[400px] tw-w-full tw-h-full tw-relative tw-z-50 tw-p-4 [.embed_&]:tw-p-0 [.embed_&]:tw-px-4'
          }
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="tw-relative tw-w-full">
            <div className="tw-absolute tw-left-0 tw-top-1/2 tw--translate-y-1/2 tw-w-full">
              {visualizerVisible && <Visualizer />}
            </div>
            <div className="tw-flex tw-gap-4 tw-w-full tw-items-center tw-justify-center tw-relative tw-z-[20]">
              <VolumeControl />
              {showsControls && <RewindButton />}
              <IconButton
                label={playing ? `${stopOrPause} audio` : 'Play audio'}
                onClick={onClick}
                icon={playing ? stopOrPause.toLowerCase() : 'play'}
                isLoading={loading || connecting}
                size={'large'}
                isDisabled={loading || connecting}
              />
              {showsControls && <ForwardButton />}
              <VisualizerToggle />
            </div>
          </div>
          {showsControls && <SeekBar />}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
