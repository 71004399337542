import React, { FunctionComponent, useContext } from 'react';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { InfiniteScrollCollectionRecordings } from './InfiniteScrollCollectionRecordings';
import { PaginatedCollectionRecordings } from './PaginatedCollectionRecordings';

export const CollectionRecordings: FunctionComponent = () => {
  const { isListenerAppWebview } = useContext(UserAgentContext);

  if (isListenerAppWebview) {
    return <InfiniteScrollCollectionRecordings itemsPerPage={20} />;
  }

  return <PaginatedCollectionRecordings itemsPerPage={20} />;
};
