import React, { FunctionComponent } from 'react';
import { ReactComponent as EventsEmptySVG } from 'images/empty-events.svg';
import { styleWrapper } from 'utilities/styleWrapper';

export interface EmptyEventsProps {
  username: string;
}

const Wrapper = styleWrapper('div', 'empty-state');
const Content = styleWrapper('div', 'empty-state__content');

export const EmptyEvents: FunctionComponent<EmptyEventsProps> = ({
  username
}) => {
  return (
    <Wrapper>
      <Content>
        <EventsEmptySVG />
        <p>{username} does not have any upcoming events.</p>
      </Content>
    </Wrapper>
  );
};
