import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AlertState {
  message: string;
  type: string;
}

interface AlertsState {
  alerts: AlertState[];
}

export const initialAlertsState: AlertsState = {
  alerts: []
};

const alertSlice = createSlice({
  name: 'alert',
  initialState: initialAlertsState,
  reducers: {
    createAlert(state, action: PayloadAction<AlertState>) {
      state.alerts.push({
        message: action.payload.message,
        type: action.payload.type
      });
    },
    resetAlert: () => initialAlertsState
  }
});

export const { createAlert, resetAlert } = alertSlice.actions;

export default alertSlice.reducer;
