import { FullImage } from 'components/Images/FullImage';
import { CollectionModel } from 'features/collections/CollectionModel';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { Share } from 'components/Share';
import tinycolor from 'tinycolor2';
import { SquareImage } from 'components/Images/SquareImage';

interface CollectionHeaderProps {
  collection: CollectionModel | null;
}

export const CollectionHeader: FunctionComponent<CollectionHeaderProps> = ({
  collection
}) => {
  const [colorContrastClass, setColorContrastClass] = useState<string>('');

  useEffect(
    function setCollectionColorContrast() {
      if (!collection) return;
      const color = tinycolor(collection.color);
      const colorIsLight = color.getLuminance() > 0.5;
      setColorContrastClass(colorIsLight ? 'light-contrast' : 'dark-contrast');
    },
    [collection, setColorContrastClass]
  );

  if (!collection) {
    return null;
  }

  return (
    <header
      className={`tw-overflow-hidden tw-relative tw-py-16 tw-flex tw-justify-center tw-items-center ${colorContrastClass}`}
    >
      <div className="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full">
        <FullImage
          artworkUrl={collection.artwork?.image_blur.large || null}
          artworkMode={collection.artworkMode}
          type={'recording'}
        />
      </div>
      {collection.artworkKey && (
        <div className="tw-absolute tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-bg-black/30"></div>
      )}
      <div
        className={`tw-grid tw-grid-cols-12 tw-gap-8 lg:tw-gap-16 tw-max-w-screen-xl tw-w-full tw-mx-auto tw-relative${
          collection.artworkKey
            ? ' tw-text-white'
            : ' tw-text-white [.light-contrast_&]:tw-text-black'
        }`}
      >
        <div className="tw-col-span-12 md:tw-col-span-6 tw-text-center md:tw-text-right">
          <div className="tw-max-w-[180px] md:tw-max-w-sm xl:tw-max-w-xl tw-w-full tw-inline-block">
            <SquareImage
              artworkUrl={collection.artwork?.image.large}
              cssClasses={'tw-shadow-glow'}
            />
          </div>
        </div>
        <div className="tw-col-span-12 md:tw-col-span-6 tw-text-center md:tw-text-left tw-flex md:tw-items-center">
          <div className="tw-w-full">
            <h2 className="tw-m-0 tw-mb-2 tw-text-3xl md:tw-text-5xl [.ios-listener_&]:tw-text-2xl [.android-listener_&]:tw-text-5xl">
              {collection.title}
            </h2>
            {collection.description && (
              <p className="tw-line-clamp-3 tw-mt-0">
                {collection.description}
              </p>
            )}
            <div className="tw-mt-4">
              <Share
                title={collection.title}
                label={'collection'}
                showLabel={false}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
