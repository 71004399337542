import React, { FunctionComponent } from 'react';
import { FullScreenContent } from 'components/Layouts/FullScreenView/FullScreenContent';
import { ConnectingAudio } from 'components/ConnectingAudio';
import { EventModel } from 'features/events/EventModel';
import { EventCreatorCountdown } from 'features/events/EventCreator/EventCreatorCountdown';
import { useIsEventLive } from 'features/events/eventHooks';
import { EventChat } from 'features/events//EventChat';
import { useAppSelector } from 'hooks/redux';
import { currentBroadcastFinishedSelector } from 'features/broadcast/selectors';
import { FullScreenDetails } from 'components/Layouts/FullScreenView/FullScreenDetails';
import { SquareImage } from 'components/Images/SquareImage';
import { FullScreenControls } from 'components/Layouts/FullScreenView/FullScreenControls';
import { Share } from 'components/Share';
import { EventInfo } from '../EventInfo';
import { HeartButtonWrapper } from 'components/Hearts/HeartButtonWrapper';
import { ChatButton } from 'features/chat/components';
import { EventMeta } from '../components/EventMeta';
import { ListenerCount } from 'components/ListenerCount';
import { useMediaQuery } from 'react-responsive';
import { EventChatWithPlayer } from '../EventChatWithPlayer';

interface EventWrapperProps {
  event: EventModel | null;
}

export const EventCreatorContent: FunctionComponent<EventWrapperProps> = ({
  event
}) => {
  const isLive = useIsEventLive(event);
  const nativeEventStarted = useAppSelector(
    (state) => state.events.nativeEventStarted
  );
  const nativeEventStopped = useAppSelector(
    (state) => state.events.nativeEventStopped
  );
  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const isLiveOrFinished =
    isLive || currentBroadcastFinished || nativeEventStopped;

  if (!event) {
    return null;
  }

  const renderChatView = () => {
    if (isMobile) {
      return <EventChatWithPlayer event={event} />;
    }
    return <EventChat event={event} />;
  };

  const renderMeta = () => {
    if (isLive) {
      return (
        <>
          <EventMeta />
          <ListenerCount />
        </>
      );
    }
    return <EventCreatorCountdown />;
  };

  const renderControls = () => {
    return (
      <FullScreenControls>
        {isLive && <HeartButtonWrapper />}
        {isLiveOrFinished && <ChatButton />}
        <Share title={event.title} label={'event'} />
        <EventInfo event={event} />
      </FullScreenControls>
    );
  };

  const renderRightColumn = isLiveOrFinished ? renderChatView() : null;

  if (nativeEventStarted) {
    return <FullScreenContent centerColumn={<ConnectingAudio />} />;
  }

  return (
    <FullScreenContent
      centerColumn={
        <FullScreenDetails
          title={isLiveOrFinished ? event.title : null}
          image={
            <SquareImage
              artworkUrl={event.artwork.image.large}
              cssClasses={'tw-shadow-glow'}
            />
          }
          meta={renderMeta()}
          controls={renderControls()}
        />
      }
      rightColumn={renderRightColumn}
    />
  );
};
