// Initial code used from https://github.com/E-Kuerschner/useAudioPlayer/blob/main/src/useAudioPosition.ts
// Updated to use our audio context and add seeking state to pause requestAnimationFrame
// A separate hook was created to manage this state in order to avoid many rerenders of components that don't need the live data feed.

import {
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect
} from 'react';
import { AudioPlayerContext, AudioPositionContext } from 'features/audio/audio';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';

interface AudioPosition {
  seeking: boolean;
  position: number;
  duration: number;
  percentComplete: number;
  setSeeking: (seeking: boolean) => void;
  seek: (position: number) => void;
}

export const useAudioPosition = (): AudioPosition => {
  const playing = useSelector((state: RootState) => state.audio.playing);
  const duration = useSelector((state: RootState) => state.audio.duration);
  const { audio } = useContext(AudioPlayerContext);
  const { seeking, setSeeking, position, setPosition } =
    useContext(AudioPositionContext);

  const animationFrameRef = useRef<number>();

  // sets position on player initialization and when the audio is stopped
  useEffect(() => {
    if (audio) {
      setPosition(audio.currentTime);
    }
  }, [audio, setPosition]);

  useEffect(() => {
    if (seeking && animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
  }, [seeking]);

  // updates position on a 60fps loop for high refresh rate setting
  useLayoutEffect(() => {
    if (seeking) {
      return;
    }

    const animate = () => {
      if (audio) {
        setPosition(audio.currentTime);
      }
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    // kick off a new animation cycle when the player is defined and starts playing
    if (audio && playing) {
      animationFrameRef.current = requestAnimationFrame(animate);
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [audio, playing, setPosition, seeking]);

  const seek = useCallback(
    (position) => {
      if (!audio) return 0;
      const result = (audio.currentTime = position);
      const updatedPos = audio.currentTime;
      setPosition(result);
      return updatedPos;
    },
    [audio, setPosition]
  );

  const percentComplete = useMemo(() => {
    return (position / duration) * 100 || 0;
  }, [duration, position]);

  return { position, duration, seek, percentComplete, seeking, setSeeking };
};
