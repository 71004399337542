"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocialLinks = exports.DEFAULT_CONFIG = exports.PREDEFINED_PROFILES = void 0;
var profiles_1 = require("./profiles/");
Object.defineProperty(exports, "PREDEFINED_PROFILES", { enumerable: true, get: function () { return profiles_1.PREDEFINED_PROFILES; } });
var types_1 = require("./types");
__exportStar(require("./types"), exports);
var PROFILE_ID = '[A-Za-z0-9_\\-\\.]+';
var QUERY_PARAM = '(\\?.*)?';
var createRegexp = function (profileMatch, config) {
    var str = profileMatch.match.replace('{PROFILE_ID}', "" + PROFILE_ID);
    var isTyped = typeof profileMatch.type !== 'undefined';
    var regexp = new RegExp(__spread([
        '^', str
    ], (config.allowQueryParams && isTyped ? [QUERY_PARAM] : []), [
        '$'
    ]).join(''));
    return regexp;
};
var findIndex = function (matches, link, config) {
    return (matches !== null && matches !== void 0 ? matches : []).findIndex(function (match) { return createRegexp(match, config).test(link); });
};
exports.DEFAULT_CONFIG = {
    usePredefinedProfiles: true,
    trimInput: true,
    allowQueryParams: false,
};
var SocialLinks = /** @class */ (function () {
    function SocialLinks(config) {
        var _this = this;
        if (config === void 0) { config = exports.DEFAULT_CONFIG; }
        if (typeof config === 'boolean') {
            config = { usePredefinedProfiles: config };
        }
        this.config = __assign(__assign({}, exports.DEFAULT_CONFIG), config);
        this.profiles = new Map();
        if (this.config.usePredefinedProfiles) {
            profiles_1.PREDEFINED_PROFILES.map(function (_a) {
                var name = _a.name, matches = _a.matches;
                return _this.addProfile(name, matches);
            });
        }
    }
    SocialLinks.prototype.trim = function (input) {
        return this.config.trimInput ? input.trim() : input;
    };
    SocialLinks.prototype.addProfile = function (profileName, profileMatches) {
        if (this.hasProfile(profileName))
            return false;
        this.profiles.set(profileName, profileMatches);
        return true;
    };
    SocialLinks.prototype.cleanProfiles = function () {
        this.profiles.clear();
    };
    SocialLinks.prototype.isValid = function (profileName, link) {
        if (!this.hasProfile(profileName))
            return false;
        var matches = this.profiles.get(profileName);
        return findIndex(matches, this.trim(link), this.config) !== -1;
    };
    SocialLinks.prototype.getProfileId = function (profileName, link) {
        var _a, _b;
        if (!this.hasProfile(profileName))
            throw new Error("There is no profile " + profileName + " defined");
        var matches = (_a = this.profiles.get(profileName)) !== null && _a !== void 0 ? _a : [];
        var trimmed = this.trim(link);
        var idx = findIndex(matches, trimmed, this.config);
        if (idx === -1)
            throw new Error("Link has not matched with profile " + profileName);
        return ((_b = trimmed.match(createRegexp(matches[idx], this.config))) !== null && _b !== void 0 ? _b : [])[matches[idx].group];
    };
    SocialLinks.prototype.getLink = function (profileName, id, type) {
        var _a, _b;
        if (type === void 0) { type = types_1.TYPE_DEFAULT; }
        if (!this.hasProfile(profileName))
            throw new Error("There is no profile " + profileName + " defined");
        var matches = (_a = this.profiles.get(profileName)) !== null && _a !== void 0 ? _a : [];
        var weakType = type === types_1.TYPE_DEFAULT ? types_1.TYPE_DESKTOP : type;
        var idx = matches.findIndex(function (match) {
            if (type === types_1.TYPE_DEFAULT)
                return true;
            return match.type === weakType;
        });
        if (idx === -1)
            throw new Error("There is no pattern for profile " + profileName);
        return ((_b = matches[idx].pattern) !== null && _b !== void 0 ? _b : '').replace('{PROFILE_ID}', "" + this.trim(id));
    };
    SocialLinks.prototype.sanitize = function (profileName, link, type) {
        var _a, _b;
        if (type === void 0) { type = types_1.TYPE_DEFAULT; }
        var trimmed = this.trim(link);
        var profileId = this.getProfileId(profileName, trimmed);
        var matches = (_a = this.profiles.get(profileName)) !== null && _a !== void 0 ? _a : [];
        var idx = findIndex(matches, trimmed, this.config);
        var matchedType = type !== types_1.TYPE_DEFAULT ? type : ((_b = matches[idx].type) !== null && _b !== void 0 ? _b : types_1.TYPE_DEFAULT);
        return this.getLink(profileName, profileId, matchedType);
    };
    SocialLinks.prototype.hasProfile = function (profileName) {
        return this.profiles.has(profileName);
    };
    SocialLinks.prototype.getProfileNames = function () {
        return __spread(this.profiles.keys());
    };
    // list all matching profiles sorted by score
    SocialLinks.prototype.scoreProfiles = function (link) {
        var _this = this;
        return this.getProfileNames().map(function (profileName) {
            var matches = _this.profiles.get(profileName);
            var score = (matches || []).reduce(function (sum, match) {
                return sum + (createRegexp(match, _this.config).test(link) ? 1 : 0);
            }, 0);
            return { profileName: profileName, score: score };
        })
            .filter(function (obj) { return obj.score > 0; })
            .sort(function (a, b) { return b.score - a.score; });
    };
    // return first matching profile
    SocialLinks.prototype.detectProfile = function (link) {
        var scores = this.scoreProfiles(link);
        if (scores.length === 0)
            return '';
        return scores[0].profileName;
    };
    return SocialLinks;
}());
exports.SocialLinks = SocialLinks;
exports.default = SocialLinks;
