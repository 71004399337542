import React, { FunctionComponent } from 'react';
import { MiniPlayer } from 'components/Player/MiniPlayer';
import { useCurrentRecording } from 'features/recordings/recordingHooks';
import { RecordingMeta } from 'features/recordings/components/RecordingMeta';

export const RecordingMiniPlayer: FunctionComponent = () => {
  const recording = useCurrentRecording();

  if (!recording) {
    return null;
  }

  return (
    <MiniPlayer
      title={recording.title}
      url={`/recordings/${recording.id}`}
      artwork={recording.artwork}
      meta={<RecordingMeta />}
      showVolumeControl={true}
      color={recording.color}
    />
  );
};
