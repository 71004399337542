"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pinterest = void 0;
var types_1 = require("../types");
exports.pinterest = {
    name: 'pinterest',
    matches: [
        {
            match: '(https?://)?([a-z]{1,3}.)?pinterest.com/({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://pinterest.com/{PROFILE_ID}'
        },
        { match: '@?({PROFILE_ID})', group: 1 },
    ]
};
