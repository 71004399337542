import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEvent } from 'features/events/eventHooks';
import { EventDate } from 'features/events/EventDate';
import { EventCountdownTimer } from 'features/events/components/EventCountdownTimer';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { FullScreenTitle } from 'components/Layouts/FullScreenView/FullScreenTitle';

export const EventCreatorCountdown: FunctionComponent = () => {
  const { id } = useParams();
  const event = useEvent(id);
  const nativeEventStopped = useSelector(
    (state: RootState) => state.events.nativeEventStopped
  );
  const calculateTimeLeft = (): string | undefined => {
    return event?.durationToEvent()?.toFormat('hh:mm:ss');
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isOverdue, setIsOverdue] = useState(event?.isOverdue());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      setIsOverdue(event?.isOverdue());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const Overdue = () => {
    if (event?.startedAt || event?.endedAt) {
      return <p className="tw-m-0">Restart your live event</p>;
    }
    return <p className="tw-m-0">Event overdue, your listeners are waiting!</p>;
  };

  if (!event) {
    return null;
  }

  if (nativeEventStopped) {
    return null;
  }

  if (event.isAdhoc()) {
    return (
      <div className="tw-text-center tw-mx-auto tw-max-w-[620px] tw-full">
        {!event?.endedAt ? (
          <>
            Click <strong>Start</strong> to go live with...
            <FullScreenTitle title={event.title} />
          </>
        ) : (
          <Overdue />
        )}
      </div>
    );
  }

  return (
    <div className="tw-text-center tw-mx-auto tw-max-w-[620px] tw-full">
      {!event.endedAt && <span>Next event:</span>}
      <FullScreenTitle title={event.title} />
      <EventDate eventId={event.id} hideTime={event.isIn24hrs()} />
      <div className="tw-mt-2">
        {isOverdue || event.startedAt ? (
          <Overdue />
        ) : (
          <EventCountdownTimer event={event} timeLeft={timeLeft} />
        )}
      </div>
    </div>
  );
};
