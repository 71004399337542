import React, { FunctionComponent } from 'react';
import { useChannelEventEmbedNavigation } from 'features/events/EventEmbed/eventEmbedHooks';
import { EventModel } from 'features/events/EventModel';
import { PageAnimationOpacity } from 'components/LayoutAnimations/PageAnimationOpacity';
import { EventWrapper } from 'features/events/EventWrapper';
import { EventEmbedHeader } from 'features/events/EventEmbed/EventEmbedHeader';
import { EventEmbedContent } from 'features/events/EventEmbed/EventEmbedContent';
import { EventEmbedFooter } from 'features/events/EventEmbed/EventEmbedFooter';

interface EventEmbedProps {
  event: EventModel | null;
}

export const EventEmbed: FunctionComponent<EventEmbedProps> = ({ event }) => {
  useChannelEventEmbedNavigation(event);

  return (
    <PageAnimationOpacity>
      <EventWrapper event={event}>
        <EventEmbedHeader event={event} />
        <EventEmbedContent event={event} />
        <EventEmbedFooter event={event} />
      </EventWrapper>
    </PageAnimationOpacity>
  );
};
