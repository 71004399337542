import React, { FunctionComponent, useEffect } from 'react';
import { EventModel } from 'features/events/EventModel';
import { PageAnimationOpacity } from 'components/LayoutAnimations/PageAnimationOpacity';
import { EventWrapper } from 'features/events/EventWrapper';
import { EventCreatorContent } from 'features/events/EventCreator/EventCreatorContent';
import { useDispatch } from 'react-redux';
import {
  setNativeEventStarted,
  setNativeEventStopped
} from 'features/events/eventsSlice';
import { useIsEventLive } from 'features/events/eventHooks';

interface EventEmbedProps {
  event: EventModel | null;
}

export const EventCreator: FunctionComponent<EventEmbedProps> = ({ event }) => {
  const dispatch = useDispatch();
  const isLive = useIsEventLive(event);

  useEffect(
    function resetNativeAudioState() {
      if (isLive) {
        dispatch(setNativeEventStarted(false));
        dispatch(setNativeEventStopped(false));
      }
    },
    [isLive, dispatch]
  );

  return (
    <PageAnimationOpacity>
      <EventWrapper event={event}>
        <EventCreatorContent event={event} />
      </EventWrapper>
    </PageAnimationOpacity>
  );
};
