import React, { FunctionComponent } from 'react';
import { useRecording } from 'features/recordings/recordingHooks';
import { DateTime } from 'luxon';

interface RecordingDateProps {
  recordingId: string;
  cssClass?: string;
}

export const RecordingDate: FunctionComponent<RecordingDateProps> = (props) => {
  const recording = useRecording(props.recordingId);

  if (!recording) {
    return null;
  }

  return (
    <time dateTime={recording.createdAt.toString()} className={props.cssClass}>
      {DateTime.fromISO(recording.createdAt).toLocaleString(DateTime.DATE_MED)}
    </time>
  );
};
