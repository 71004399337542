import { Loader } from 'components/Loader';
import { fetchChatHistory, setChatVisible } from 'features/chat/chatSlice';
import { useIsBroadcaster, useCurrentUser } from 'features/current-user/hooks';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { AnimatePresence } from 'framer-motion';
import {
  ChatScrollWrapper,
  ChatAnimation,
  ChatInput,
  ChatProfile,
  ChatHeader,
  ChatComments,
  ChatLoginPrompt,
  styles
} from './components';
import {
  useRecentMessages,
  useSlidePanelForCrowd,
  useEventChatWithPlayer
} from 'features/chat/chatHooks';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { Crowd } from 'features/crowd/Crowd';
import { useCurrentEvent } from 'features/events/eventHooks';

interface ChatProps {
  fetchHistory: boolean;
}

export const Chat: FunctionComponent<ChatProps> = ({ fetchHistory }) => {
  const isLoaded = useSelector((state: RootState) => state.chat.isLoaded);
  const isVisible = useSelector((state: RootState) => state.chat.isVisible);
  const slidePanelForCrowd = useSlidePanelForCrowd();
  const messages = useRecentMessages();
  const isPageVisible = usePageVisibility();
  const isCrowdVisible = useSelector(
    (state: RootState) => state.crowd.isCrowdVisible
  );

  const channelId = useSelector(
    (state: RootState) => state.channel.channelDetails?.ownerId
  );

  const currentUser = useCurrentUser();
  const isBroadcaster = useIsBroadcaster();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const event = useCurrentEvent();

  const eventChatWithPlayer = useEventChatWithPlayer();

  useEffect(() => {
    if (width >= 640) {
      dispatch(setChatVisible(isVisible));
    }
  }, [dispatch, width, isVisible]);

  useEffect(() => {
    if (channelId && fetchHistory && isPageVisible) {
      dispatch(fetchChatHistory(channelId));
    }
  }, [dispatch, channelId, fetchHistory, isPageVisible]);

  if (event && !event.chatEnabled) {
    return null;
  }

  const renderInner = () => {
    if (!isLoaded || !channelId) {
      return <Loader fillComponent={true} />;
    }

    return (
      <div className="tw-flex tw-relative tw-h-full">
        <div
          className={`tw-bg-background sm:tw-shadow-glow tw-w-full tw-absolute tw-top-0 tw-bottom-0 tw-flex ${
            isCrowdVisible && width < 1024
              ? 'sm:tw-rounded-tr-2xl sm:tw-rounded-br-2xl'
              : 'sm:tw-rounded-2xl'
          }`}
        >
          {slidePanelForCrowd && <Crowd />}
          <div className="tw-flex tw-flex-col tw-h-full tw-flex-1 tw-relative tw-z-50 tw-text-label">
            <ChatHeader />
            <ChatScrollWrapper>
              {messages.length > 0 ? (
                <ChatComments messages={messages} />
              ) : (
                <div className={styles['chat__empty']}>
                  <h4>
                    {currentUser && isBroadcaster
                      ? 'Chat about your live audio'
                      : "Like what you're hearing?"}
                  </h4>
                  <p>
                    {currentUser && isBroadcaster
                      ? 'Tell visitors what they’re\n listening to, say hello 👋'
                      : 'Send a heart, say hello 👋'}
                  </p>
                </div>
              )}
            </ChatScrollWrapper>
            {currentUser ? (
              <ChatInput channelId={channelId} />
            ) : (
              <ChatLoginPrompt />
            )}
          </div>
        </div>
      </div>
    );
  };

  if (eventChatWithPlayer) {
    return (
      <>
        {renderInner()}
        <ChatProfile />
      </>
    );
  }

  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <>
          <ChatAnimation>{renderInner()}</ChatAnimation>
          <ChatProfile />
        </>
      )}
    </AnimatePresence>
  );
};
