import { Action, configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import rootReducer, { RootState } from './rootReducer';

let store = configureStore({
  reducer: rootReducer
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const setStore = (newStore: EnhancedStore): void => {
  store = newStore;
};

export const getStore = (): EnhancedStore => {
  return store;
};

export const getState = (): RootState => {
  return getStore().getState() as RootState;
};

export const getDispatch = (): AppDispatch => {
  return getStore().dispatch as AppDispatch;
};
