import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  useCallback
} from 'react';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { Card } from 'components/Card';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Loader';
import { useSubdomain } from 'hooks/useSubdomain';
import { useDispatch } from 'react-redux';
import { InView } from 'react-intersection-observer';
import {
  allPublicAndUnlistedCollectionRecordingsSelector,
  collectionRecordingsPageCount
} from 'features/collections/collectionsSelectors';
import { EmptyCollectionRecordings } from './EmptyCollectionRecordings';
import { fetchCollectionRecordings } from 'features/collections/collectionsSlice';
import { useCurrentCollection } from 'features/collections/collectionHooks';
import { useOnceEffect } from 'hooks/useOnceEffect';
import { useAppSelector } from 'hooks/redux';

export interface InfiniteScrollCollectionRecordingsProps {
  itemsPerPage: number;
}

export const InfiniteScrollCollectionRecordings: FunctionComponent<
  InfiniteScrollCollectionRecordingsProps
> = () => {
  const [currentItems, setCurrentItems] = useState<RecordingModel[]>([]);
  const currentPageRef = useRef(1);
  const loading = useAppSelector(
    (state) => state.collections.collectionRecordingsLoading
  );
  const subdomain = useSubdomain();
  const dispatch = useDispatch();
  const collection = useCurrentCollection();
  const recordings: RecordingModel[] = useSelector(
    allPublicAndUnlistedCollectionRecordingsSelector
  );
  const recordingsPageCount = useSelector(collectionRecordingsPageCount);

  useOnceEffect(function fetchInitialCollectionRecordings() {
    if (!collection) return;
    console.log('Fetching initial recordings for collection: ', collection.id);
    dispatch(fetchCollectionRecordings(subdomain, collection.id, 1));
  }, collection);

  useEffect(
    function setCurrentRecordings() {
      if (loading) return;
      setCurrentItems((prevState) => [...prevState, ...recordings]);
    },
    [recordings, setCurrentItems, loading]
  );

  const fetchRecordingsOnScroll = useCallback(
    (inView: boolean) => {
      if (!collection) return;
      if (inView && currentPageRef.current < recordingsPageCount) {
        console.log(
          `Loading collection recordings on scroll for page ${
            currentPageRef.current + 1
          }`
        );
        dispatch(
          fetchCollectionRecordings(
            subdomain,
            collection.id,
            currentPageRef.current + 1
          )
        );
        currentPageRef.current += 1;
      }
    },
    [recordingsPageCount, subdomain, collection, dispatch]
  );

  if (recordings.length === 0 && !loading) {
    return <EmptyCollectionRecordings />;
  }

  return (
    <div className="container--wide">
      <ol className="list--reset tw-grid tw-grid-cols-12 tw-gap-4 md:tw-gap-8 lg:tw-gap-12">
        {currentItems.map((recording, index) => {
          const lastElement = index === currentItems.length - 1;
          return (
            <InView
              as="li"
              className="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-4"
              key={recording.id}
              onChange={(inView) =>
                lastElement && inView ? fetchRecordingsOnScroll(inView) : null
              }
            >
              <Card
                id={recording.id}
                url={`/recordings/${recording.id}`}
                title={`${recording.title}`}
                artwork={recording.artwork}
                artworkKey={recording.artworkKey}
                artworkMode={recording.artworkMode}
                type={'recording'}
                color={recording.color}
                duration={recording.duration}
              />
            </InView>
          );
        })}
      </ol>
      {loading && (
        <div className="text--center mar-t mar-b">
          <Loader />
        </div>
      )}
    </div>
  );
};
