"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instagram = void 0;
var types_1 = require("../types");
exports.instagram = {
    name: 'instagram',
    matches: [
        {
            match: '(https?://)?(www.)?instagram.com/({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://instagram.com/{PROFILE_ID}'
        },
        {
            match: '(https?://)?m.instagram.com/({PROFILE_ID})/?', group: 2, type: types_1.TYPE_MOBILE,
            pattern: 'https://m.instagram.com/{PROFILE_ID}'
        },
        { match: '@?({PROFILE_ID})', group: 1 },
    ]
};
