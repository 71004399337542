import React, { FunctionComponent, HTMLAttributes } from 'react';
import classNames from 'classnames';

export function styleWrapper<Type extends HTMLAttributes<HTMLElement>>(
  node: string | React.FunctionComponent<Type>,
  newClassName: string
): FunctionComponent<Type> {
  const StyledComponent: FunctionComponent<Type> = ({
    children,
    className,
    ...rest
  }) => {
    const classes = classNames(className, newClassName);

    const props: Type = { ...rest, className: classes } as Type;

    return React.createElement(node, props, children);
  };

  return StyledComponent;
}
