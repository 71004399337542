import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HubState {
  socketId: string;
  isHubConnected: boolean;
  isHubCrowdConnected: boolean;
  isHubBroadcastConnected: boolean;
  hubServer?: string;
}

export const initialHubState: HubState = {
  socketId: '',
  isHubConnected: false,
  isHubCrowdConnected: false,
  isHubBroadcastConnected: false
};

const hubSlice = createSlice({
  name: 'hub',
  initialState: initialHubState,
  reducers: {
    setSocketId(state, action: PayloadAction<string>) {
      state.socketId = action.payload;
    },
    setHubConnected(state, action) {
      state.isHubConnected = action.payload;
    },
    setHubBroadcastConnected(state, action) {
      state.isHubBroadcastConnected = action.payload;
    },
    setHubCrowdConnected(state, action) {
      state.isHubCrowdConnected = action.payload;
    },
    setHubServer(state, action: PayloadAction<string>) {
      state.hubServer = action.payload;
    }
  }
});

export const {
  setSocketId,
  setHubConnected,
  setHubBroadcastConnected,
  setHubCrowdConnected,
  setHubServer
} = hubSlice.actions;

export default hubSlice.reducer;
