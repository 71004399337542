import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { channelSupportLinkSelector } from 'features/channel/channelSelectors';
import styles from 'components/Buttons/styles.module.scss';
import { Icon } from '../Icons';

interface SupportButtonProps {
  showAslink?: boolean;
}

export const SupportButton: FunctionComponent<SupportButtonProps> = ({
  showAslink
}) => {
  const channelSupportLink = useSelector(channelSupportLinkSelector);

  if (!channelSupportLink) {
    return null;
  }

  return (
    <a
      href={channelSupportLink.url}
      className={classNames({
        [styles['button--icon']]: !showAslink,
        [styles['button--icon-text']]: !showAslink,
        [styles['button--icon-mini']]: !showAslink,
        [styles['button--link']]: showAslink
      })}
      target="_blank noopener noreferrer"
    >
      <Icon
        hidden={true}
        icon={'support'}
        label={'Support'}
        height={14}
        width={14}
      />
      Support
    </a>
  );
};
