import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface uiState {
  isAnimatingFullScreen: boolean;
  isAnimatingLayout: boolean;
}

export const initialUIState: uiState = {
  isAnimatingFullScreen: false,
  isAnimatingLayout: false
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialUIState,
  reducers: {
    isAnimatingFullScreen(state, action: PayloadAction<boolean>) {
      state.isAnimatingFullScreen = action.payload;
    },
    isAnimatingLayout(state, action: PayloadAction<boolean>) {
      state.isAnimatingLayout = action.payload;
    }
  }
});

export const { isAnimatingFullScreen, isAnimatingLayout } = uiSlice.actions;

export default uiSlice.reducer;
