import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { styleWrapper } from 'utilities/styleWrapper';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly inline?: boolean;
}

export const Button: FunctionComponent<ButtonProps> = ({
  inline,
  children,
  className,
  ...rest
}) => {
  const buttonClass = classNames(className, {
    [styles['button']]: true,
    [styles['button--inline']]: inline
  });

  return (
    <button className={buttonClass} {...rest}>
      {children}
    </button>
  );
};

export const MiniButton = styleWrapper<ButtonProps>(
  Button,
  styles['button--mini']
);

export const SmallButton = styleWrapper<ButtonProps>(
  Button,
  styles['button--small']
);

export const LargeButton = styleWrapper<ButtonProps>(
  Button,
  styles['button--large']
);
