import { createSlice, original, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { apiGetRecording, apiGetRecordings } from 'api/v3/recordings';
import { V3RecordingAttributes } from 'api/v3/types/recording';
import { setCurrentEventId } from 'features/events/eventsSlice';
import { v4 as uuidv4 } from 'uuid';
import { NavigateFunction } from 'react-router-dom';
import { unloadRecording } from 'thunks/navigationThunks';
import { Media } from 'api/v3/types/media';

export interface Recording {
  id: string;
  title: string;
  description: string;
  // category exists only if recording is linked to a broadcast
  categoryId: string | null;
  url: string;
  createdAt: string;
  duration: null | number;
  accessLevel: string;
  fileFormat: string;
  artworkKey: null | string;
  artworkUrl: null | string;
  artworkMode: string;
  userId: string;
  color: string;
  shouldDisplayOnChannel: boolean;
  artwork: Media;
}

export interface RecordingPage {
  pageNumber: number;
  recordingStartId: number;
}

export interface RecordingMap {
  [key: string]: Recording;
}

export interface RecordingsState {
  recordings: RecordingMap;
  currentRecording: Recording | null;
  currentRecordingId: string | null;
  currentRecordingSessionId: string | null;
  isLoading: boolean;
  nextUrl: null | string;
  pageCount: number;
  paginationStartIds: RecordingPage[];
}

export const initialRecordingsState: RecordingsState = {
  recordings: {},
  currentRecording: null,
  currentRecordingId: null,
  currentRecordingSessionId: null,
  isLoading: false,
  nextUrl: null,
  pageCount: 0,
  paginationStartIds: []
};

export const mapRecordingsToState = (
  recordings: V3RecordingAttributes[]
): Recording[] => {
  return recordings.map((recording) => {
    return {
      accessLevel: recording.attributes.access_level,
      artworkKey: recording.attributes.artwork_original_key,
      categoryId: recording.attributes.category_id,
      createdAt: recording.attributes.created_at,
      description: recording.attributes.description,
      duration: recording.attributes.duration,
      fileFormat: recording.attributes.file_format,
      id: recording.id,
      title: recording.attributes.title,
      url: recording.attributes.url,
      userId: recording.attributes.user_id,
      artworkUrl: recording.attributes.artwork_url,
      color: recording.attributes.color,
      artwork: recording.attributes.media.artwork,
      artworkMode: recording.attributes.artwork_mode,
      shouldDisplayOnChannel: recording.attributes.should_display_on_channel
    };
  });
};

const recordingsSlice = createSlice({
  name: 'recordings',
  initialState: initialRecordingsState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    getRecordingsSuccess(state, action: PayloadAction<Recording[]>) {
      const newRecordingMap: RecordingMap = {};
      action.payload.forEach((recording) => {
        newRecordingMap[recording.id] = recording;
      });
      state.recordings = { ...state.recordings, ...newRecordingMap };
    },
    getRecordingsFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
    removeRecording(state, { payload: id }: PayloadAction<string>) {
      console.log(`About to remove recording with id ${id}`);

      if (
        state.currentRecordingId === id &&
        state.currentRecording?.id === id
      ) {
        state.currentRecording = null;
        state.currentRecordingId = null;
      }

      const orig = original<RecordingsState>(state);
      if (!orig) {
        return;
      }

      const { [id]: _removedRecording, ...withoutRemovedRecording } =
        orig.recordings;

      return {
        ...state,
        recordings: withoutRemovedRecording
      };
    },
    setCurrentRecordingId(state, action: PayloadAction<string | null>) {
      state.currentRecordingId = action.payload;
    },
    setCurrentRecordingSessionId(state, action: PayloadAction<string | null>) {
      state.currentRecordingSessionId = action.payload;
    },
    setNextUrl(state, { payload: url }: PayloadAction<string | null>) {
      state.nextUrl = url;
    },
    setPageCount(state, { payload: count }: PayloadAction<number>) {
      state.pageCount = count;
    },
    setPaginationStartIds(state, action: PayloadAction<RecordingPage>) {
      const pageExists =
        state.paginationStartIds.findIndex(
          (page) => page.pageNumber === action.payload.pageNumber
        ) > -1;
      if (!pageExists) {
        state.paginationStartIds.push(action.payload);
      } else {
        state.paginationStartIds = state.paginationStartIds.map((page) =>
          page.pageNumber === action.payload.pageNumber ? action.payload : page
        );
      }
    },
    resetPaginationStartIds(state) {
      state.paginationStartIds = [];
    },
    resetRecordingsState() {
      return initialRecordingsState;
    },
    updateRecording(state, action: PayloadAction<Recording>) {
      console.log(`About to update recording with id ${action.payload.id}`);
      const recordingToUpdate = state.recordings[action.payload.id];
      if (recordingToUpdate) {
        state.recordings[recordingToUpdate.id] = {
          ...recordingToUpdate,
          ...action.payload
        };
      }
      if (
        state.currentRecordingId === recordingToUpdate.id &&
        state.currentRecording?.id === recordingToUpdate.id
      ) {
        state.currentRecording = recordingToUpdate;
        state.currentRecordingId = recordingToUpdate.id;
      }
    }
  }
});

export const {
  getRecordingsFailed,
  getRecordingsSuccess,
  removeRecording,
  resetPaginationStartIds,
  resetRecordingsState,
  setCurrentRecordingId,
  setCurrentRecordingSessionId,
  setNextUrl,
  setPageCount,
  setPaginationStartIds,
  startLoading,
  stopLoading,
  updateRecording
} = recordingsSlice.actions;

export default recordingsSlice.reducer;

export const fetchRecordings =
  (userId: string, pageNumber: number, redirect?: NavigateFunction): AppThunk =>
  async (dispatch, getState) => {
    const {
      recordings: { isLoading }
    } = getState();
    if (isLoading) return;

    dispatch(startLoading());

    let response;
    try {
      response = await apiGetRecordings(userId, pageNumber);
    } catch (err) {
      dispatch(getRecordingsFailed(err ? err.toString() : ''));
      dispatch(stopLoading());
    }

    if (response) {
      if (redirect && pageNumber > 1 && response.data.length === 0) {
        redirect(`../404`);
        return;
      }

      dispatch(setNextUrl(response.links.next || null));
      dispatch(setPageCount(response.meta.total_pages || 0));
      dispatch(
        setPaginationStartIds({
          pageNumber: pageNumber,
          recordingStartId: +response.data[0]?.id
        })
      );
      console.log(response.data);
      dispatch(getRecordingsSuccess(mapRecordingsToState(response.data)));
    }
    dispatch(stopLoading());
  };

export const fetchRecording =
  (
    channelSubdomain: string,
    recordingId: string,
    navigate?: NavigateFunction,
    isEmbed?: boolean,
    hideLoader?: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    const {
      recordings: { isLoading }
    } = getState();
    if (isLoading) return;

    if (!hideLoader) {
      dispatch(startLoading());
    }

    let response;
    try {
      response = await apiGetRecording(channelSubdomain, recordingId);
    } catch (err) {
      if (err.status == 404) {
        if (navigate) {
          dispatch(navigateErrorRecording(navigate, isEmbed ? true : false));
        }
      } else {
        dispatch(getRecordingsFailed(err ? err.toString() : ''));
        dispatch(stopLoading());
      }
    }

    if (response) {
      dispatch(getRecordingsSuccess(mapRecordingsToState([response])));
      dispatch(stopLoading());
    }
  };

export const handleRecordingCreated =
  (recording: Recording): AppThunk =>
  async (dispatch, getState) => {
    const {
      recordings: { recordings }
    } = getState();
    const publicRecording = recordings[recording.id];
    if (!publicRecording) {
      dispatch(
        setPaginationStartIds({
          pageNumber: 1,
          recordingStartId: +recording.id
        })
      );
    }
    dispatch(getRecordingsSuccess([recording]));
  };

export const handleRecordingTranscoded =
  (recording: Recording): AppThunk =>
  async (dispatch, getState) => {
    const {
      recordings: { recordings }
    } = getState();
    const publicRecording = recordings[recording.id];
    if (!publicRecording) {
      dispatch(
        setPaginationStartIds({
          pageNumber: 1,
          recordingStartId: +recording.id
        })
      );
    }
    dispatch(getRecordingsSuccess([recording]));
  };

export const setCurrentRecording =
  (recordingId: string | null): AppThunk =>
  async (dispatch, getState) => {
    const {
      recordings: { currentRecordingSessionId }
    } = getState();

    dispatch(setCurrentRecordingId(recordingId));
    dispatch(setCurrentEventId(null));

    if (!recordingId) {
      dispatch(setCurrentRecordingSessionId(null));
    } else {
      if (!currentRecordingSessionId) {
        const sessionId = uuidv4();
        dispatch(setCurrentRecordingSessionId(sessionId));
      }
    }
  };

export const navigateErrorRecording =
  (redirect: NavigateFunction, isEmbed: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(unloadRecording());
    dispatch(stopLoading());
    if (isEmbed) {
      redirect(`/recordings/not-found`);
    } else {
      redirect(`/`);
    }
  };
