import React, { FunctionComponent, ReactNode, useContext } from 'react';
import styles from './styles.module.scss';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { LogoImage } from 'components/LogoImage';
import { useDispatch } from 'react-redux';
import { setMiniPlayerVisible } from 'features/channel/channelSlice';
import { ThumbImage } from 'components/Images/ThumbImage';
import { SupportButton } from 'components/Support/SupportButton';
import classNames from 'classnames';
import { FollowButtons } from 'features/chat/components/FollowButtons';
import { useCurrentUser } from 'features/current-user/hooks';
import { DarkModeSwitch } from 'components/DarkModeSwitch';
import { PrivacyNotice } from 'features/privacy/privacyNotice';
import { CreatorSignUpButton } from 'components/CreatorSignUpButton';
import { IconButton } from 'components/Buttons/IconButton';
import { setEventMinimised } from 'features/events/eventsSlice';
import { useAppSelector } from 'hooks/redux';

interface FullScreenHeaderProps {
  statusPill?: ReactNode;
  minimiseUrl?: string;
}

export const FullScreenHeader: FunctionComponent<FullScreenHeaderProps> = (
  props
) => {
  const channel = useAppSelector((state) => state.channel.channelDetails);
  const { isCreatorAppWebview, isListenerAppWebview, isEmbed } =
    useContext(UserAgentContext);
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const onMinimise = () => {
    dispatch(setMiniPlayerVisible(true));
    dispatch(setEventMinimised(true));
  };

  if (!channel || isCreatorAppWebview) {
    return null;
  }

  const removeBranding: boolean = isEmbed && channel.removeEmbedBranding;

  const renderLinks = () => {
    if (isListenerAppWebview) {
      return null;
    } else {
      return (
        <div className="tw-flex tw-gap-4">
          {props.statusPill && props.statusPill}
          <DarkModeSwitch />
          <CreatorSignUpButton />
          {props.minimiseUrl && (
            <IconButton
              icon={'chevronDown'}
              label={'Minimize event'}
              href={props.minimiseUrl || '/'}
              link={true}
              onClick={onMinimise}
              locationState={{ hideLoader: true }}
              isTransparent={true}
            />
          )}
        </div>
      );
    }
  };

  const logoImageSize = (): string => {
    if (isEmbed) {
      return 'embed';
    }
    return 'small';
  };

  return (
    <header
      className={`tw-flex tw-items-center tw-justify-between tw-relative tw-z-50 tw-space-x-8 md:tw-space-x-12 tw-px-4 md:tw-px-8 tw-py-2 md:tw-py-4 tw-relative ${
        isListenerAppWebview ? 'tw-pr-[120px]' : ''
      } [.embed_&]:tw-px-4`}
    >
      <div className={styles['full-screen__logo']}>
        <LogoImage
          size={logoImageSize()}
          hasFallback={!channel.logoKey}
          isLink={!removeBranding}
        >
          <ThumbImage
            artworkUrl={channel.channelLogo.image.small}
            artworkMode={'logo'}
            alt={`Logo for ${channel.username}`}
          />
        </LogoImage>
        <div className={styles['full-screen__logo__details']}>
          <h2
            className={classNames(
              'truncate',
              styles['full-screen__logo__title']
            )}
          >
            {channel.username}
          </h2>
          {removeBranding === false && (
            <div className={styles['full-screen__logo__links']}>
              <SupportButton showAslink={true} />
              <FollowButtons
                selectedProfileId={channel.ownerId}
                followedIds={(currentUser && currentUser.followedUserIds) || []}
                showAslink={true}
              />
            </div>
          )}
        </div>
        <PrivacyNotice />
      </div>
      {renderLinks()}
    </header>
  );
};
