export type ApiGetFollowedIdsResponse = number[];

export async function apiGetFollowedIds(
  userId: string
): Promise<ApiGetFollowedIdsResponse> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/followed_ids`,
    { credentials: 'include' }
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}

export interface ApiFollowUserResponse {
  success: boolean;
}

export async function apiFollowUser(
  userId: string
): Promise<ApiFollowUserResponse> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/followers`,
    { credentials: 'include', method: 'POST' }
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}

export interface ApiUnfollowUserResponse {
  success: boolean;
}

export async function apiUnfollowUser(
  userId: string
): Promise<ApiFollowUserResponse> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/followers`,
    { credentials: 'include', method: 'DELETE' }
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}

export async function apiLogout(): Promise<Response> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/login`,
    {
      method: 'DELETE',
      credentials: 'include'
    }
  );

  return results;
}

export async function apiForgotPassword(email: string): Promise<Response> {
  const results = await fetch(
    `${
      process.env.REACT_APP_API_ENDPOINT
    }/v3/password_resets/new?email=${encodeURIComponent(email)}`,
    {
      method: 'GET',
      credentials: 'include'
    }
  );

  return results;
}
