import { createSelector } from 'reselect';
import { RootState } from 'rootReducer';

export const crowdSortedListSelector = createSelector(
  [
    (state: RootState) => state.crowd.crowd,
    (state: RootState) => state.channel.channelDetails
  ],
  (crowd, channelDetails) => {
    const filterCrowdChannel = crowd.filter(
      (member) => member.id !== channelDetails?.ownerId
    );
    return filterCrowdChannel
      .slice()
      .sort((x, y) => x.enteredAt - y.enteredAt)
      .reverse();
  }
);
