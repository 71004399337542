import React, {
  FunctionComponent,
  useContext,
  useState,
  useRef,
  memo
} from 'react';
import styles from '../EmojiPicker/styles.module.scss';
import { Icon } from 'components/Icons';
import { useOnClickOutside } from 'usehooks-ts';
import { ThemeContext } from 'features/theme/theme';
import { useAppSelector } from 'hooks/redux';
import GifPicker, { TenorImage, Theme, ContentFilter } from 'gif-picker-react';

interface GiphyPickerProps {
  onGifClick: (gif: TenorImage) => void;
}

export const GiphyPicker: FunctionComponent<GiphyPickerProps> = (props) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [pickerOpen, togglePicker] = useState<boolean>(false);
  const { darkMode } = useContext(ThemeContext);
  const gifsInChatEnabled = useAppSelector(
    (state) => state.channel.channelDetails?.gifsInChatEnabled
  );

  const handleGifPickerClickOutside = () => {
    togglePicker(false);
  };

  useOnClickOutside(pickerRef, handleGifPickerClickOutside);

  const onGifClick = (gif: TenorImage) => {
    props.onGifClick(gif);
    togglePicker(false);
  };

  if (!gifsInChatEnabled) {
    return null;
  }

  return (
    <div ref={pickerRef} className="tw-relative">
      <button
        onClick={() => togglePicker(!pickerOpen)}
        className={styles['emoji-picker__button']}
        type="button"
      >
        <Icon icon={'gif'} hidden={true} label={'Choose gif'} />
      </button>
      {pickerOpen && (
        <div className="tw-absolute tw-bottom-full tw-right-0 tw-shadow-xl tw-h-[280px] md:tw-h-[320px] mobile-landscape:tw-h-[220px] tw-z-50">
          <GifPicker
            tenorApiKey={`${process.env.REACT_APP_TENOR_API_KEY}`}
            autoFocusSearch={false}
            onGifClick={onGifClick}
            theme={darkMode ? Theme.DARK : Theme.LIGHT}
            contentFilter={ContentFilter.MEDIUM}
            height={'100%'}
            width={280}
          />
        </div>
      )}
    </div>
  );
};

export default memo(GiphyPicker);
