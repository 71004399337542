import { UserImage } from 'components/UserImage';
import { setSelectedChatUserId } from 'features/chat/chatSlice';
import { useCurrentUser, useIsBroadcaster } from 'features/current-user/hooks';
import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { BlockButtons } from './BlockButtons';
import styles from './styles.module.scss';
import fallbackImage from 'images/no-picture.svg';
import { ModalWrapper } from 'components/Modal/Wrapper';

export const ChatProfile: FunctionComponent = () => {
  const dispatch = useDispatch();

  const selectedProfile = useSelector(
    (state: RootState) => state.chat.selectedUser
  );
  const currentUser = useCurrentUser();
  const isBroadcaster = useIsBroadcaster();

  const addDefaultSrc = (event: any): void => {
    if (!event.target.src.endsWith(fallbackImage)) {
      event.target.src = fallbackImage;
    }
  };

  const closeProfile = () => {
    dispatch(setSelectedChatUserId(null));
  };

  if (!selectedProfile) {
    return <></>;
  }

  const isMeSelected: boolean = selectedProfile.id === currentUser?.id;

  return (
    <ModalWrapper
      isOpen={selectedProfile != null}
      title={'Profile'}
      onRequestClose={closeProfile}
    >
      <div className={styles['chat-profile']}>
        {selectedProfile.profileImageUrl && (
          <UserImage size={'large'}>
            <img
              src={selectedProfile.profileImageUrl}
              alt={`Profile image for ${selectedProfile.username}`}
              onError={addDefaultSrc}
            />
          </UserImage>
        )}
        <h2 className={styles['chat-profile__username']}>
          {selectedProfile.username}
        </h2>
        {!isMeSelected && currentUser && (
          <ul className={styles['chat-profile__actions']}>
            {isBroadcaster && (
              <li>
                <BlockButtons
                  selectedProfileId={selectedProfile.id}
                  blockedIds={currentUser.blockedUserIds}
                />
              </li>
            )}
          </ul>
        )}
      </div>
    </ModalWrapper>
  );
};
