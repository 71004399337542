import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';

export const useBannedChannelChecker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const unavailable = useSelector(
    (state: RootState) => state.channel.channelUnavailable
  );

  useEffect(() => {
    if (!unavailable || location.pathname === '/') {
      return;
    }

    navigate('/');
  }, [unavailable, location, navigate]);
};
