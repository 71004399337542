import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import { Card } from 'components/Card';
import { SearchRecording } from 'features/search/searchSlice';

interface SearchResultRecordingProps {
  lastElement: boolean;
  result: SearchRecording;
}

const SearchResultRecording: ForwardRefRenderFunction<
  HTMLLIElement,
  SearchResultRecordingProps
> = (props, ref) => {
  return (
    <li
      className="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-4"
      ref={props.lastElement ? ref : null}
    >
      <Card
        id={props.result.id}
        url={`/recordings/${props.result.id}`}
        title={props.result.title}
        artwork={props.result.artwork}
        artworkKey={props.result.artworkKey}
        artworkMode={'recording'}
        type={'recording'}
        color={props.result.color}
      />
    </li>
  );
};

export default forwardRef<HTMLLIElement, SearchResultRecordingProps>(
  SearchResultRecording
);
