import { Recording } from 'features/recordings/recordingsSlice';
import { getState } from 'store';

export interface RecordingModel extends Recording {
  isPublic(): boolean;
  isUnlisted(): boolean;
  isOnlyVisibleToCreator(): boolean;
}

export const compareRecordingsById = (
  recording1: Recording,
  recording2: Recording
): number => {
  if (parseInt(recording1.id) < parseInt(recording2.id)) {
    return 1;
  } else {
    return -1;
  }
};

export const RecordingModel = (recording: Recording): RecordingModel => {
  return {
    ...recording,
    isPublic() {
      return this.accessLevel == 'public';
    },
    isUnlisted() {
      return this.accessLevel == 'unlisted';
    },
    isOnlyVisibleToCreator() {
      const {
        currentUser: { me }
      } = getState();
      return this.accessLevel === 'only_me' && me?.id === this.userId;
    }
  };
};
