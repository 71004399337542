import React, { FunctionComponent, useContext } from 'react';
import { useCurrentRecording } from 'features/recordings/recordingHooks';
import { RecordingDate } from 'features/recordings/components/RecordingDate';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import useNetwork from 'hooks/useNetwork';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const RecordingMeta: FunctionComponent = () => {
  const recording = useCurrentRecording();
  const loading = useSelector((state: RootState) => state.audio.loading);
  const connecting = useSelector((state: RootState) => state.audio.connecting);
  const { isOnline } = useNetwork();
  const { isListenerAppWebview } = useContext(UserAgentContext);

  if (!recording) {
    return null;
  }

  const renderStatusText = () => {
    if (!isOnline) {
      return 'Offline';
    } else if (connecting || loading) {
      return <span className="loading-text">Connecting</span>;
    } else if (isListenerAppWebview) {
      return <RecordingDate recordingId={recording.id} />;
    }
    return <RecordingDate recordingId={recording.id} />;
  };

  return <>{renderStatusText()}</>;
};
