import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { userState } from './currentUserSlice';

export function useCurrentUser(): userState | null {
  const currentUser = useSelector((state: RootState) => state.currentUser.me);

  return currentUser || null;
}

export function useIsBroadcaster(): boolean {
  const currentUser = useCurrentUser();
  const channelId = useSelector(
    (state: RootState) => state.channel.channelDetails?.ownerId
  );

  return currentUser?.id === channelId;
}
