import { EventModel } from 'features/events/EventModel';
import { Broadcast } from 'features/broadcast/broadcastsSlice';
import { DateTime } from 'luxon';

export const startTimeChecker = (
  event: EventModel,
  broadcast: Broadcast | null
): DateTime | null => {
  if (broadcast && broadcast.startedAt) {
    return DateTime.fromISO(broadcast.startedAt).setZone(event.timezone);
  }

  return event.parsedStartedAt
    ? event.parsedStartedAt
    : event.parsedStartsAt || null;
};
