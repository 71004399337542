import { V3RecordingSearch } from 'api/v3/types/recording_search';
import { V3EventSearch } from 'api/v3/types/event_search';

const perFetch = 20;

export async function apiSearchEvents(
  query: string,
  channelId: string,
  pageNumber: number
): Promise<V3EventSearch> {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/v3/event_search?search[query]=${query}&search[channel_id]=${channelId}&page[size]=${perFetch}&page[number]=${pageNumber}`;
  const results = await fetch(url, { credentials: 'include' });

  if (results.ok) {
    return await results.json();
  } else {
    throw results.body;
  }
}

export async function apiSearchRecordings(
  query: string,
  channelId: string,
  pageNumber: number
): Promise<V3RecordingSearch> {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/v3/recording_search?search[query]=${query}&search[channel_id]=${channelId}&page[size]=${perFetch}&page[number]=${pageNumber}`;
  const results = await fetch(url, { credentials: 'include' });

  if (results.ok) {
    return await results.json();
  } else {
    throw results.body;
  }
}
