import React, { FunctionComponent } from 'react';
import { FullScreenContent } from 'components/Layouts/FullScreenView/FullScreenContent';
import { EventModel } from 'features/events/EventModel';
import { EventCountdown } from 'features/events/EventCountdown';
import { useIsEventLive } from 'features/events/eventHooks';
import { EventPlayer } from 'features/events/components/EventPlayer';
import { OffAir } from 'components/OffAir';

interface EventEmbedContentProps {
  event: EventModel | null;
}

export const EventEmbedContent: FunctionComponent<EventEmbedContentProps> = ({
  event
}) => {
  const isLive = useIsEventLive(event);

  if (!event) {
    return null;
  }

  if (isLive) {
    return <FullScreenContent centerColumn={<EventPlayer />} />;
  } else {
    return (
      <FullScreenContent
        centerColumn={
          <>
            <OffAir />
            <EventCountdown />
          </>
        }
      />
    );
  }
};
