"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.github = void 0;
var types_1 = require("../types");
exports.github = {
    name: 'github',
    matches: [
        {
            match: '(https?://)?(www.)?github.com/({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://github.com/{PROFILE_ID}'
        },
        { match: '({PROFILE_ID})', group: 1 },
    ]
};
