import React, { FunctionComponent } from 'react';
import styles from '../styles.module.scss';
import { EventModel } from 'features/events/EventModel';

interface EventCountdownTimerProps {
  event: EventModel | null;
  timeLeft: string | undefined;
}

export const EventCountdownTimer: FunctionComponent<
  EventCountdownTimerProps
> = ({ event, timeLeft }) => {
  if (!timeLeft) {
    return null;
  }

  if (event?.isIn24hrs()) {
    return <div className={styles['countdown__time']}>{timeLeft}</div>;
  }

  return null;
};
