import useLocalStorage from 'hooks/useLocalStorage';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import tinycolor from 'tinycolor2';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useDarkMode } from 'usehooks-ts';

interface ContextProps {
  theme: string;
  themeColor: string;
  setTheme: (theme: string) => void;
  setThemeColor: (color: string) => void;
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
  themeColorRGB: string;
  themeContrast: string;
  themeGradient: string;
}

export const ThemeContext = createContext<ContextProps>({
  theme: '',
  setTheme: () => null,
  themeColor: '',
  setThemeColor: () => null,
  darkMode: false,
  setDarkMode: () => null,
  themeColorRGB: '',
  themeContrast: '',
  themeGradient: ''
});

const Theme = ({
  children
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const channelThemeColor = useSelector(
    (state: RootState) => state.channel.channelDetails?.themeColor
  );
  const privateThemeColor = useSelector(
    (state: RootState) => state.channel.accessCodeDetails?.themeColor
  );
  const subscriptionThemeColor = useSelector(
    (state: RootState) => state.channel.paidChannelDetails?.themeColor
  );
  const { isListenerAppWebview } = useContext(UserAgentContext);
  const { isDarkMode } = useDarkMode();
  const [darkMode, setDarkMode] = useLocalStorage('dark-mode', false);
  const fallbackColor = '#47add8';
  const [theme, setTheme] = useLocalStorage('theme', 'light');
  const [themeColor, setThemeColor] = useState<string>(fallbackColor);
  const [themeColorRGB, setThemeColorRGB] = useState<string>('');
  const [themeGradient, setThemeGradient] = useState<string>('');
  const [themeContrast, setThemeContrast] = useState<string>('light-contrast');

  useEffect(() => {
    if (channelThemeColor || privateThemeColor || subscriptionThemeColor) {
      let baseColor =
        channelThemeColor ||
        privateThemeColor ||
        subscriptionThemeColor ||
        fallbackColor;
      let color = tinycolor(baseColor);
      const isDarkTheme = color.getLuminance() < 0.01;
      const themeColorIsWhite = color.getLuminance() > 0.95;
      if (baseColor === '#ffffff' || themeColorIsWhite) {
        // Just incase they manage to set a theme color to white
        baseColor = '#000000';
        color = tinycolor(baseColor);
      }
      if (
        (isListenerAppWebview && isDarkTheme && isDarkMode) ||
        (isDarkTheme && darkMode)
      ) {
        baseColor = '#999999';
        color = tinycolor(baseColor);
      }
      const colorToRGB = tinycolor(baseColor).toRgb();
      const colorToRGBString = `${colorToRGB.r},${colorToRGB.g},${colorToRGB.b}`;
      const themeColorIsLight = color.getLuminance() > 0.5;
      const colorDarken = tinycolor(baseColor).darken(
        themeColorIsLight ? 35 : 20
      );
      const colorToGradient = `linear-gradient(20deg, 
        ${colorDarken.toHslString()},
        ${color.toHslString()}
        )`;

      setThemeColor(baseColor);
      setThemeColorRGB(colorToRGBString);
      setThemeGradient(colorToGradient);
      setThemeContrast(themeColorIsLight ? 'light-contrast' : 'dark-contrast');
    }
  }, [
    channelThemeColor,
    privateThemeColor,
    subscriptionThemeColor,
    setThemeColor,
    setThemeColorRGB,
    setThemeGradient,
    setThemeContrast,
    isListenerAppWebview,
    isDarkMode,
    darkMode
  ]);

  useEffect(() => {
    if (themeColorRGB) {
      document.body.style.setProperty('--color__primary', themeColorRGB);
    }
  }, [themeColorRGB]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        themeColor,
        setThemeColor,
        themeColorRGB,
        themeContrast,
        themeGradient,
        darkMode,
        setDarkMode
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default Theme;
