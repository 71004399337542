import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { Loader } from 'components/Loader';
import { useSubdomain } from 'hooks/useSubdomain';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { EmptyRecordings } from 'features/channel/components/ChannelRecordings/EmptyRecordings';
import { Menu } from '../ChannelRecordings/Menu';
import { allPublicCollectionsSelector } from 'features/collections/collectionsSelectors';
import { CollectionModel } from 'features/collections/CollectionModel';
import { useAppSelector } from 'hooks/redux';
import { CollectionListItem } from 'features/collections/Components/CollectionListItem';
import { fetchCollections } from 'features/collections/collectionsSlice';

export interface InfiniteScrollCollectionsProps {
  itemsPerPage: number;
}

export const InfiniteScrollCollections: FunctionComponent<
  InfiniteScrollCollectionsProps
> = ({ itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState<CollectionModel[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const currentPageRef = useRef(1);
  const loading = useAppSelector((state) => state.recordings.isLoading);
  const pageCount = useAppSelector((state) => state.collections.pageCount);
  const subdomain = useSubdomain();
  const dispatch = useDispatch();
  const { ref, inView } = useInView({
    threshold: 0
  });
  const channelName = useSelector(
    (state: RootState) => state.channel.channelDetails?.username
  );
  const collections: CollectionModel[] = useAppSelector(
    allPublicCollectionsSelector
  );

  useEffect(
    function fetchInitialCollections() {
      dispatch(fetchCollections(subdomain, 1));
    },
    [dispatch, subdomain]
  );

  useEffect(
    function setCurrentRecordings() {
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading collections from ${itemOffset} to ${endOffset}`);
      setCurrentItems(collections);
    },
    [itemOffset, itemsPerPage, collections]
  );

  useEffect(
    function fetchCollectionsOnScroll() {
      if (inView && currentPageRef.current <= pageCount) {
        dispatch(fetchCollections(subdomain, currentPageRef.current + 1));
        const newOffset = currentPageRef.current * itemsPerPage;
        setItemOffset(newOffset);
        currentPageRef.current += 1;
      }
    },
    [inView, dispatch, itemsPerPage, pageCount, subdomain]
  );

  if (collections.length === 0 && !loading) {
    return (
      <div className="tw-flex tw-flex-col tw-h-full container--wide">
        <Menu />
        <EmptyRecordings username={channelName || ''} type={'collections'} />
      </div>
    );
  }

  return (
    <div className="container--wide">
      <Menu />
      <h1 className="tw-sr-only">Recordings</h1>
      <ul className="list--reset">
        {currentItems.map((collection, index) => {
          const lastElement = index === currentItems.length - 1;
          return (
            <li
              key={collection.id}
              ref={lastElement ? ref : null}
              className="tw-mb-4 md:tw-mb-8"
            >
              <CollectionListItem collection={collection} />
            </li>
          );
        })}
      </ul>
      {loading && inView && (
        <div className="text--center mar-t mar-b">
          <Loader />
        </div>
      )}
    </div>
  );
};
