import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'components/Icons';

interface ChannelMenuLinkProps {
  url: string;
  icon: string;
  label: string;
}

export const ChannelMenuLink: FunctionComponent<ChannelMenuLinkProps> = ({
  url,
  icon,
  label
}) => {
  return (
    <NavLink
      to={url}
      state={{ animate: true }}
      className="tw-flex tw-gap-3 tw-items-center tw-justify-center tw-w-[36px] tw-w-[36px] md:tw-justify-right md:tw-w-auto md:tw-h-auto"
    >
      <span className="tw-flex tw-items-center tw-justify-center tw-relative -tw-top-[1px] md:tw-hidden">
        <Icon hidden={true} icon={icon} label={label} height={20} width={20} />
      </span>
      <span className="tw-hidden md:tw-block">{label}</span>
    </NavLink>
  );
};
