import React, { FunctionComponent, useContext } from 'react';
import { useAppSelector } from 'hooks/redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Loader } from 'components/Loader';
import { ChannelHeader } from 'features/channel/components/ChannelHeader';
import { ChannelFooter } from 'features/channel/components/ChannelFooter';
import { ChannelBanned } from 'features/channel/components/ChannelBanned/ChannelBanned';
import { SEOHeaderTags } from 'components/SEO/SEOHeaderTags';
import { PageAnimationOpacity } from 'components/LayoutAnimations/PageAnimationOpacity';
import { useSEOTitleDescription } from 'hooks/useSEOTitleDescription';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { ChannelDeepLinkBanner } from './components/ChannelDeepLinkBanner';
import { ChannelInfo } from './components/ChannelInfo';

export const Channel: FunctionComponent = () => {
  const channel = useAppSelector((state) => state.channel.channelDetails);
  const channelisLoaded = useAppSelector((state) => state.channel.isLoaded);
  const channelUrl = window.location.href;

  const unavailable = useAppSelector(
    (state) => state.channel.channelUnavailable
  );
  const navigate = useNavigate();

  const { pageTitle, pageDescription } = useSEOTitleDescription();
  const { isNativeAppWebview } = useContext(UserAgentContext);

  if (unavailable) {
    return <ChannelBanned />;
  }

  if (!channelisLoaded || channel == null) {
    return <Loader fillComponent={true} hideOnNative={isNativeAppWebview} />;
  }

  if (channel.embedOnly === true) {
    navigate('../404');
    return null;
  }

  return (
    <>
      <SEOHeaderTags
        title={pageTitle}
        description={pageDescription}
        image={channel.channelArtwork.image_seo.og_image}
        url={channelUrl}
      />
      <PageAnimationOpacity>
        <ChannelDeepLinkBanner />
        <ChannelHeader />
        <ChannelInfo />
        <main className="app__main" role="main">
          <Outlet />
        </main>
        <ChannelFooter />
      </PageAnimationOpacity>
    </>
  );
};
