import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HubMember } from 'features/hub/hub';
import { uniqBy } from 'lodash';

const CROWD_LIMIT = 500;

export interface CrowdMemberState {
  id: string;
  username: string;
  profileImageUrl: string;
  enteredAt: number;
}

export interface CrowdState {
  crowd: CrowdMemberState[];
  crowdCount: number;
  isCrowdVisible: boolean;
}

export const initialCrowdState: CrowdState = {
  crowd: [],
  crowdCount: 0,
  isCrowdVisible: false
};

const checkMemberFields = (member: HubMember): boolean => {
  return (
    member.user &&
    'id' in member.user &&
    'username' in member.user &&
    'profile_image_url' in member.user
  );
};

const mapHubMemberListToState = (members: HubMember[]): CrowdMemberState[] => {
  return members.map(mapHubMemberToState);
};

const mapHubMemberToState = (member: HubMember): CrowdMemberState => {
  return {
    id: member.user.id,
    username: member.user.username,
    profileImageUrl: member.user.profile_image_url,
    enteredAt: member.user.entered_at
  };
};

const crowdSlice = createSlice({
  name: 'chat',
  initialState: initialCrowdState,
  reducers: {
    setCrowdList(state, action: PayloadAction<HubMember[]>) {
      const members = action.payload.filter((member) =>
        checkMemberFields(member)
      );
      const membersState = mapHubMemberListToState(members);
      state.crowd = membersState.slice(0, CROWD_LIMIT);
      state.crowdCount = members.length;
    },
    addCrowdMember(state, action: PayloadAction<HubMember[]>) {
      const membersFiltered = action.payload.filter((member) =>
        checkMemberFields(member)
      );
      const members = [
        ...state.crowd,
        ...mapHubMemberListToState(membersFiltered)
      ];
      const membersUniq = uniqBy(members, 'id');
      state.crowd = membersUniq;
      state.crowdCount = membersUniq.length;
    },
    removeCrowdMembers(state, action: PayloadAction<Array<string>>) {
      const membersToRemove = state.crowd.filter(
        (member) => !action.payload.includes(member.id)
      );
      state.crowd = membersToRemove;
      state.crowdCount = membersToRemove.length;
    },
    setCrowdVisible(state, action) {
      state.isCrowdVisible = action.payload;
    }
  }
});

export const {
  setCrowdVisible,
  setCrowdList,
  addCrowdMember,
  removeCrowdMembers
} = crowdSlice.actions;

export default crowdSlice.reducer;
