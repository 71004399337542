import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from 'features/current-user/hooks';
import { showAuthenticationEmailPromptForm } from 'features/current-user/currentUserSlice';
import { ButtonLink } from 'components/Buttons/ButtonLink';

export const SubscriptionLoginPrompt: FunctionComponent = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  if (currentUser) {
    return null;
  }

  return (
    <small>
      Already subscribed to this channel?
      <br />{' '}
      <ButtonLink
        label={'Login to your account'}
        onClick={() => dispatch(showAuthenticationEmailPromptForm())}
        renderInText={true}
      />
    </small>
  );
};
