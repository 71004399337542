export async function apiV3ValidateAccesscode(
  channel: string,
  accesscode: string
): Promise<boolean> {
  const formData = new FormData();
  formData.set('accesscode', accesscode);

  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/channels/${channel}/accesscode_validation`,
    {
      method: 'post',
      credentials: 'include',

      body: formData
    }
  );

  if (results.ok) {
    return true;
  } else {
    return false;
  }
}
