import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { channelSupportLinkSelector } from 'features/channel/channelSelectors';
import styles from './styles.module.scss';

export const Support: FunctionComponent = () => {
  const channelName = useSelector(
    (state: RootState) => state.channel.channelDetails?.username
  );
  const channelSupportLink = useSelector(channelSupportLinkSelector);

  if (!channelSupportLink) {
    return null;
  }

  return (
    <div className={styles['support']}>
      <p className={styles['support__text']}>Support your favourite creators</p>
      <a
        href={channelSupportLink.url}
        className="button button--primary"
        target="_blank noopener noreferrer"
      >
        <span className="truncate">Support {channelName}</span>
      </a>
    </div>
  );
};
