import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useAppSelector } from 'hooks/redux';
import Modal from 'react-modal';
import styles from './styles.module.scss';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { UserAgentContext } from 'features/user-agent/userAgent';

interface SearchWrapperProps {
  children?: React.ReactNode;
}

export const SearchWrapper: FunctionComponent<SearchWrapperProps> = ({
  children
}) => {
  const isVisible = useAppSelector((state) => state.search.isVisible);
  const { postMessage } = useNativeEvents();
  const { isListenerAppWebview } = useContext(UserAgentContext);

  useEffect(
    function postMessageOnSearchVisibility() {
      if (isListenerAppWebview) {
        postMessage({
          searchVisible: isVisible
        });
      }
    },
    [isVisible, postMessage, isListenerAppWebview]
  );

  return (
    <Modal
      isOpen={isVisible}
      className={styles['search']}
      overlayClassName={styles['search__overlay']}
      ariaHideApp={false}
      contentLabel={'Search events & recordings'}
    >
      <div className={styles['search__wrapper']}>{children}</div>
    </Modal>
  );
};
