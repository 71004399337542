import { useEffect, useState, useContext } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { UserAgentContext } from 'features/user-agent/userAgent';

export function useSEOTitleDescription(
  title?: string | null,
  description?: string | null,
  isPrivate?: boolean
) {
  let channelUsername = useAppSelector(
    (state) => state.channel.channelDetails?.username
  );
  const channelDescription = useAppSelector(
    (state) => state.channel.channelDetails?.aboutMe
  );
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageDescription, setPageDescription] = useState<string>('');
  const { pathname } = useLocation();
  const { isListenerAppWebview } = useContext(UserAgentContext);

  if (isPrivate) {
    channelUsername = title || null || undefined;
  }

  useEffect(() => {
    const isAboutPath = matchPath('/about', pathname);
    const isEventsPath = matchPath('/events', pathname);
    const isRecordingsPath = matchPath('/recordings', pathname);
    const isCollectionsPath = matchPath('/collections', pathname);
    const isEventPath = matchPath('/events/:id', pathname);
    const isRecordingPath = matchPath('/recordings/:id', pathname);
    const isCollectionPath = matchPath('/collections/:id', pathname);

    const fallbackDescription = () => {
      const suffix =
        'Mixlr is a simple way to share live audio online. Broadcast using any source and invite others to listen and chat in real-time.';
      return `${channelUsername} is on Mixlr. ${suffix}`;
    };

    const appendTitle = (): string => {
      if (isEventPath || isRecordingPath || isCollectionPath) {
        if (isListenerAppWebview || isPrivate) {
          return title || channelUsername || 'Live event';
        }
        return title ? `${channelUsername} | ${title}` : `${channelUsername}`;
      } else if (isEventsPath) {
        return `${channelUsername} | Live Audio Upcoming Events`;
      } else if (isRecordingsPath) {
        return `${channelUsername} | Audio Recordings`;
      } else if (isCollectionsPath) {
        return `${channelUsername} | Audio Collections`;
      } else if (isAboutPath) {
        return `${channelUsername} | About`;
      } else {
        return `${channelUsername}`;
      }
    };

    setPageTitle(appendTitle());
    setPageDescription(
      description || channelDescription || fallbackDescription()
    );
  }, [
    pathname,
    title,
    description,
    channelUsername,
    channelDescription,
    isListenerAppWebview,
    isPrivate
  ]);

  return { pageTitle, pageDescription };
}
