import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { Support } from 'components/Support';
import { ChannelSocialLinks } from 'features/channel/components/ChannelSocialLinks';
import { ChannelStats } from 'features/channel/components/ChannelStats';

export const ChannelAbout: FunctionComponent = () => {
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const channelisLoaded = useSelector(
    (state: RootState) => state.channel.isLoaded
  );

  if (!channelisLoaded || channel == null) {
    return null;
  }

  return (
    <div className="container--wide tw-mt-8 [.ios_&]:tw-mt-0 [.android_&]:tw-mt-0">
      <h1>About {channel.username}</h1>
      <div className="grid">
        {channel.aboutMe && (
          <div className="col-xss-12 col-md-8">
            <p className="textarea-format">{channel.aboutMe}</p>
          </div>
        )}
        <div className="col-xss-12 col-md-4">
          <ChannelStats />
          <ChannelSocialLinks />
          <Support />
        </div>
      </div>
    </div>
  );
};
