import { useState, useEffect, useRef } from 'react';

function useNetwork() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const timeoutRef = useRef(null);

  const updateNetwork = () => {
    setIsOnline(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('online', updateNetwork);
    window.addEventListener('offline', updateNetwork);

    return () => {
      window.removeEventListener('online', updateNetwork);
      window.removeEventListener('offline', updateNetwork);
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return {
    isOnline
  };
}

export default useNetwork;
