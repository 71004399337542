import React, { FunctionComponent, useContext } from 'react';
import { useAppSelector } from 'hooks/redux';
import { LogoImage } from 'components/LogoImage';
import { Link } from 'react-router-dom';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { ThumbImage } from 'components/Images/ThumbImage';
import { CurrentUserMenu } from 'features/current-user/components/CurrentUserMenu';
import { DarkModeSwitch } from 'components/DarkModeSwitch';
import { toggleSearchVisibility } from 'features/search/searchSlice';
import { useDispatch } from 'react-redux';
import { IconButton } from 'components/Buttons/IconButton';
import { PrivacyNotice } from 'features/privacy/privacyNotice';
import { ChannelMenuLink } from './Components/ChannelMenuLink';
import { CreatorSignUpButton } from 'components/CreatorSignUpButton';

export const ChannelHeader: FunctionComponent = () => {
  const channel = useAppSelector((state) => state.channel.channelDetails);
  const dispatch = useDispatch();

  const { isNativeAppWebview } = useContext(UserAgentContext);

  const showSearch = () => {
    dispatch(toggleSearchVisibility(true));
  };

  if (!channel || isNativeAppWebview) {
    return null;
  }

  return (
    <header
      data-testid="header"
      className="tw-flex md:tw-grid tw-grid-cols-12 tw-items-center tw-px-8 tw-py-4 tw-relative"
    >
      <Link
        to="/"
        className="tw-col-span-2 tw-mr-4 sm:tw-mr-8 md:tw-mr-0 tw-cursor-pointer tw-block tw-flex tw-items-center"
      >
        <LogoImage size={'small'} hasFallback={!channel.logoKey}>
          <ThumbImage
            artworkUrl={channel.channelLogo.image.small}
            artworkMode={'logo'}
            alt={`Logo for ${channel.username}`}
          />
        </LogoImage>
      </Link>
      <nav id="navigation" className="tw-col-span-8 tw-mr-auto md:tw-mr-0">
        <ul className="tw-list-none tw-flex tw-m-0 tw-p-0 tw-items-center tw-justify-center tw-gap-4 sm:tw-gap-8 md:tw-gap-12">
          <li className="tw-hidden sm:tw-block">
            <ChannelMenuLink url={'/'} icon={'home'} label={'Home'} />
          </li>
          <li>
            <ChannelMenuLink
              url={'/events'}
              icon={'calendar'}
              label={'Events'}
            />
          </li>
          <li>
            <ChannelMenuLink
              url={'/recordings'}
              icon={'recordings'}
              label={'Recordings'}
            />
          </li>
          <li>
            <ChannelMenuLink url={'/about'} icon={'info'} label={'About'} />
          </li>
          <li>
            <IconButton
              label={'Show search'}
              onClick={showSearch}
              icon={'search'}
              noBackground={true}
            />
          </li>
        </ul>
      </nav>
      <div className="tw-flex tw-items-center tw-justify-end tw-gap-4 tw-col-span-2">
        <DarkModeSwitch />
        <CreatorSignUpButton />
        <CurrentUserMenu />
      </div>
      <PrivacyNotice />
    </header>
  );
};
