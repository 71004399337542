import React, { FunctionComponent } from 'react';
import { ReactComponent as RecordingsEmptySVG } from 'images/empty-recordings.svg';

export const EmptyCollectionRecordings: FunctionComponent = () => {
  return (
    <div className="tw-flex tw-justify-center tw-items-center tw-h-[400px]">
      <div className="tw-py-16 tw-text-center">
        <RecordingsEmptySVG className="tw-fill-theme-color tw-mb-6" />
        <p>No recordings in collection</p>
      </div>
    </div>
  );
};
