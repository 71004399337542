import { v4 as uuidv4 } from 'uuid';

export const enum EventPayloadType {
  RECORDING_LISTEN_EVENT = 'recordings::listen_event'
}
interface EventPayload {
  type: EventPayloadType;
}

export interface RecordingListenEventPayload extends EventPayload {
  recording_id: string;
  owner_id: string;
  minute: number;
  session_id: string;
  version: string;
}

export async function sendEvent(payload: EventPayload): Promise<boolean> {
  const controller = new AbortController();
  const signal = controller.signal;

  const result = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_SEND_EVENT}`,
    {
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'x-amzn-RequestId': uuidv4()
      },
      method: 'POST',
      signal
    }
  );

  if (result.ok) {
    console.log('[Analytics] Sending event: ', payload);
    controller.abort();
    return true;
  } else {
    return false;
  }
}
