import React, { FunctionComponent, useState, useEffect } from 'react';
import { EventModel } from 'features/events/EventModel';
import { Card } from 'components/Card';
import ReactPaginate from 'react-paginate';
import paginationStyles from 'components/Pagination/styles.module.scss';
import { useMediaQuery } from 'react-responsive';

export interface PaginatedEventsProps {
  events: EventModel[];
  itemsPerPage: number;
}

export const PaginatedEvents: FunctionComponent<PaginatedEventsProps> = ({
  events,
  itemsPerPage
}) => {
  const [currentItems, setCurrentItems] = useState<EventModel[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(events.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(events.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, events]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % events.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="container--wide tw-mt-8">
      <h1>Events</h1>
      {currentItems.length && (
        <ol className="list--reset tw-grid tw-grid-cols-12 tw-gap-4 md:tw-gap-8 lg:tw-gap-12 tw-mt-2">
          {currentItems.map((event) => (
            <li
              className="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-4"
              key={event.id}
            >
              <Card
                id={event.id}
                url={`/events/${event.id}`}
                title={`${event.title}`}
                artwork={event.artwork}
                artworkKey={event.artworkKey}
                artworkMode={event.artworkMode}
                updatedTimestamp={event.lastUpdatedAt}
                isPrivate={event.isPrivate()}
                isActive={event.isLive()}
                type={'event'}
                color={event.color}
              />
            </li>
          ))}
        </ol>
      )}
      {pageCount > 1 && (
        <ReactPaginate
          containerClassName={paginationStyles['pagination']}
          pageClassName={paginationStyles['pagination__page']}
          pageLinkClassName={paginationStyles['pagination__link']}
          activeLinkClassName={paginationStyles['pagination__link--active']}
          previousLinkClassName={paginationStyles['pagination__link--prev']}
          nextLinkClassName={paginationStyles['pagination__link--next']}
          disabledLinkClassName={paginationStyles['pagination__link--disabled']}
          breakLabel="..."
          nextLabel="next ›"
          onPageChange={handlePageClick}
          marginPagesDisplayed={1}
          pageRangeDisplayed={isMobile ? 2 : 5}
          pageCount={pageCount}
          previousLabel="‹ prev"
          renderOnZeroPageCount={() => null}
        />
      )}
    </div>
  );
};
