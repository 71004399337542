import React, { FunctionComponent, useContext, useEffect } from 'react';
import { CollectionHeader } from '../CollectionHeader';
import { useLocation, useParams } from 'react-router-dom';
import {
  useCollection,
  useFetchCollection
} from 'features/collections/collectionHooks';
import { useAppSelector } from 'hooks/redux';
import { Loader } from 'components/Loader';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { CollectionRecordings } from '../CollectonRecordings';
import { useDispatch } from 'react-redux';
import { setCurrentCollectionId } from 'features/collections/collectionsSlice';
import { CollectionHeaderTags } from '../CollectionHeaderTags';
import { CollectionBackLink } from '../CollectionBackLink';

export const Collection: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const collection = useCollection(id);
  const collectionisLoading = useAppSelector(
    (state) => state.collections.collectionLoading
  );
  const location = useLocation() as any;
  const hideLoader: boolean = location.state && location.state.hideLoader;
  const { isNativeAppWebview } = useContext(UserAgentContext);

  useFetchCollection(id, collection, hideLoader);

  useEffect(() => {
    if (id) {
      dispatch(setCurrentCollectionId(id));
    }
  }, [dispatch, id]);

  if (collectionisLoading) {
    return <Loader fillComponent={true} hideOnNative={isNativeAppWebview} />;
  }

  return (
    <>
      <CollectionHeaderTags collection={collection} />
      <CollectionBackLink />
      <CollectionHeader collection={collection} />
      <CollectionRecordings />
    </>
  );
};
