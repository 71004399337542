import React, { FunctionComponent } from 'react';
import { FullScreenHeader } from 'components/Layouts/FullScreenView/FullScreenHeader';
import { EventModel } from 'features/events/EventModel';
import { LiveNow } from 'components/LiveNow';
import { useIsEventLive } from 'features/events/eventHooks';

interface EventEmbedHeaderProps {
  event: EventModel | null;
}

export const EventEmbedHeader: FunctionComponent<EventEmbedHeaderProps> = ({
  event
}) => {
  const isLive = useIsEventLive(event);

  if (!isLive) {
    return null;
  }

  return <FullScreenHeader statusPill={<LiveNow isLive={isLive} />} />;
};
