import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { Event } from 'features/events/eventsSlice';
import { V3RecordingSearchResult } from 'api/v3/types/recording_search';
import { V3EventSearchResult } from 'api/v3/types/event_search';
import { apiSearchEvents, apiSearchRecordings } from 'api/v3/search';
import { NavigateFunction } from 'react-router-dom';
import { Media } from 'api/v3/types/media';

export interface SearchRecording {
  id: string;
  title: string;
  createdAt: string;
  duration: null | number;
  accessLevel: string;
  artworkKey: null | string;
  color: string;
  artwork: Media;
}

export interface searchState {
  results: Event[] | SearchRecording[];
  isLoading: boolean;
  isVisible: boolean;
  isEmpty: boolean;
  nextUrl: null | string;
  pageCount: number;
  searchQuery: string;
  searchType: 'events' | 'recordings';
}

export const initialSearchState: searchState = {
  results: [],
  isLoading: false,
  isVisible: false,
  isEmpty: false,
  nextUrl: null,
  pageCount: 0,
  searchQuery: '',
  searchType: 'events'
};

export const mapEventsToState = (events: V3EventSearchResult[]): Event[] => {
  return events.map((event) => {
    return {
      id: event.id,
      title: event.attributes.title,
      artworkUrl: event.attributes.artwork_url,
      artworkKey: event.attributes.artwork_original_key,
      startsAt: event.attributes.starts_at,
      endsAt: event.attributes.ends_at,
      startedAt: event.attributes.started_at,
      endedAt: event.attributes.ended_at,
      description: event.attributes.description,
      adhoc: event.attributes.adhoc,
      timezone: event.attributes.timezone,
      active: event.attributes.active,
      accesscodeEnabled: event.attributes.accesscode_enabled,
      accesscodeLink: event.attributes.accesscode_link,
      accesscodeLinkType: event.attributes.accesscode_link_type,
      accesscodeCopy: event.attributes.accesscode_copy,
      accessFailed: false,
      chatEnabled: event.attributes.chat_enabled,
      heartingEnabled: event.attributes.hearting_enabled,
      statsVisible: event.attributes.stats_visible,
      broadcasterId: event.attributes.broadcaster_id.toString(),
      legacyUrl: event.attributes.legacy_url,
      artworkMode: event.attributes.artwork_mode,
      lastUpdatedAt: event.attributes.updated_at,
      color: event.attributes.color,
      accessLevel: event.attributes.access_level,
      lastHubEventAt: null,
      artwork: event.attributes.media.artwork
    };
  });
};

export const mapRecordingsToState = (
  recordings: V3RecordingSearchResult[]
): SearchRecording[] => {
  return recordings.map((recording) => {
    return {
      accessLevel: recording.attributes.access_level,
      artworkKey: recording.attributes.artwork_original_key,
      createdAt: recording.attributes.created_at,
      duration: recording.attributes.duration,
      id: recording.id,
      title: recording.attributes.title,
      color: recording.attributes.color,
      artwork: recording.attributes.media.artwork
    };
  });
};

const searchSlice = createSlice({
  name: 'search',
  initialState: initialSearchState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setSearchVisible(state, action: PayloadAction<boolean>) {
      state.isVisible = action.payload;
    },
    setIsEmpty(state, action: PayloadAction<boolean>) {
      state.isEmpty = action.payload;
    },
    getEventsSuccess(state, action: PayloadAction<Event[]>) {
      state.results = [
        ...new Set([...state.results, ...action.payload])
      ] as Event[];
    },
    getRecordingsSuccess(state, action: PayloadAction<SearchRecording[]>) {
      state.results = [
        ...new Set([...state.results, ...action.payload])
      ] as SearchRecording[];
    },
    getSearchFailed(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
    setNextUrl(state, { payload: url }: PayloadAction<string | null>) {
      state.nextUrl = url;
    },
    setPageCount(state, { payload: count }: PayloadAction<number>) {
      state.pageCount = count;
    },
    setSearchQuery(state, { payload: query }: PayloadAction<string>) {
      state.searchQuery = query;
    },
    setSearchType(
      state,
      { payload: searchType }: PayloadAction<'events' | 'recordings'>
    ) {
      state.searchType = searchType;
    },
    resetResults(state) {
      state.results = [];
    },
    resetSearchState() {
      return initialSearchState;
    }
  }
});

export const {
  startLoading,
  stopLoading,
  setSearchVisible,
  setIsEmpty,
  getEventsSuccess,
  getRecordingsSuccess,
  getSearchFailed,
  setNextUrl,
  setPageCount,
  setSearchQuery,
  setSearchType,
  resetResults,
  resetSearchState
} = searchSlice.actions;

export default searchSlice.reducer;

export const toggleSearchVisibility =
  (visbility: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(setSearchVisible(visbility));

    if (!visbility) {
      dispatch(resetSearchState());
    }
  };

export const fetchSearchResults =
  (
    pageNumber: number,
    redirect?: NavigateFunction,
    searchQuery?: string,
    channelId?: string
  ): AppThunk =>
  async (dispatch, getState) => {
    const {
      search: { isLoading, searchType }
    } = getState();

    if (pageNumber === 1) {
      dispatch(resetResults());
    }

    if (!searchQuery || !channelId) return;
    if (isLoading) return;

    dispatch(startLoading());

    let response;
    try {
      if (searchType === 'events') {
        response = await apiSearchEvents(searchQuery, channelId, pageNumber);
      } else {
        response = await apiSearchRecordings(
          searchQuery,
          channelId,
          pageNumber
        );
      }
    } catch (err) {
      dispatch(getSearchFailed(err ? err.toString() : ''));
      dispatch(stopLoading());
    }

    if (response) {
      if (redirect && pageNumber > 1 && response.data.length === 0) {
        redirect(`../404`);
        return;
      }

      dispatch(setNextUrl(response.links.next || null));
      dispatch(setPageCount(response.meta.total_pages || 0));
      if (searchType === 'events') {
        dispatch(
          getEventsSuccess(
            mapEventsToState(response.data as V3EventSearchResult[])
          )
        );
      } else {
        dispatch(
          getRecordingsSuccess(
            mapRecordingsToState(response.data as V3RecordingSearchResult[])
          )
        );
      }
    }
    dispatch(stopLoading());
  };
