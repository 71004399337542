import { V3UserExistanceStatus } from 'api/v3/types/user_existence_status';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  apiFollowUser,
  apiGetFollowedIds,
  ApiGetFollowedIdsResponse,
  apiUnfollowUser,
  apiForgotPassword
} from 'api/user';
import {
  apiCreateUser,
  apiCreateUserActivation,
  apiGetUserExistenceStatus,
  apiLogin,
  apiResendActivation
} from 'api/v3/user';
import { createAlert } from 'features/alert/alertSlice';
import { AppThunk } from 'store';
import { airbrakeNetworkError } from 'utilities/airbrake';
import { mapApiV3Me } from 'features/current-user/mappers';
import { useSubdomain } from 'hooks/useSubdomain';
import { fetchChannelView } from 'features/channel/channelSlice';

export interface userState {
  id: string;
  username: string;
  profile_image_url: string;
  blockedUserIds: string[];
  followedUserIds: string[];
}

type CurrentAuthStep =
  | 'hidden'
  | 'emailPrompt'
  | 'login'
  | 'signup'
  | 'activation'
  | 'forgotPassword';

export interface currentUserState {
  me?: userState;
  forgotPasswordSubmitted: boolean;
  authPromptMessage: string | null;
  authenticationStepSubmitted: boolean;
  authenticationStepFailure: boolean;
  invalidCaptcha: boolean;
  signupErrors: Array<string>;
  currentAuthStep: CurrentAuthStep;
  displayCodeResent: boolean;
  codeDeliveryInProgress: boolean;
}

export const initialCurrentUserState: currentUserState = {
  me: undefined,
  forgotPasswordSubmitted: false,
  authenticationStepSubmitted: false,
  authenticationStepFailure: false,
  invalidCaptcha: false,
  authPromptMessage: null,
  signupErrors: [],
  currentAuthStep: 'hidden',
  displayCodeResent: false,
  codeDeliveryInProgress: false
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: initialCurrentUserState,
  reducers: {
    resetCurrentUserState() {
      return initialCurrentUserState;
    },
    fetchCurrentUserSuccess(
      state,
      { payload: fetchedUser }: PayloadAction<userState>
    ) {
      state.me = fetchedUser;
    },
    blockUserFromChatSuccess(state, action: PayloadAction<string>) {
      state.me?.blockedUserIds.push(action.payload);
    },
    unblockUserFromChatSuccess(state, action: PayloadAction<string>) {
      const index = state.me?.blockedUserIds.indexOf(action.payload);

      if (index != null && index > -1) {
        state.me?.blockedUserIds.splice(index, 1);
      }
    },
    getFollowedIdsSuccess(state, action: PayloadAction<string[]>) {
      if (state.me != null) {
        state.me.followedUserIds = action.payload;
      }
    },
    followUserSuccess(state, action: PayloadAction<string>) {
      state.me?.followedUserIds.push(action.payload);
    },
    unfollowUserSuccess(state, action: PayloadAction<string>) {
      const index = state.me?.followedUserIds.indexOf(action.payload);

      if (index != null && index > -1) {
        state.me?.followedUserIds.splice(index, 1);
      }
    },
    showAuthenticationEmailPromptForm(
      state,
      action: PayloadAction<string | undefined>
    ) {
      if (action.payload) {
        state.authPromptMessage = action.payload;
      }

      state.authenticationStepFailure = false;
      state.signupErrors = [];
      state.displayCodeResent = false;
      state.forgotPasswordSubmitted = false;

      state.currentAuthStep = 'emailPrompt';
    },
    cancelAuthForm(state) {
      state.currentAuthStep = 'hidden';
      state.authenticationStepFailure = false;
      state.authPromptMessage = null;
      state.signupErrors = [];
      state.displayCodeResent = false;
      state.forgotPasswordSubmitted = false;
    },
    userActivated(state, { payload: user }: PayloadAction<userState>) {
      state.currentAuthStep = 'hidden';
      state.me = user;
    },
    initializeActivationCodeDelivery(state) {
      state.codeDeliveryInProgress = true;
    },
    activationCodeResent(state) {
      state.codeDeliveryInProgress = false;
      state.displayCodeResent = true;
    },
    existenceStatusFetched(
      state,
      { payload: data }: PayloadAction<V3UserExistanceStatus>
    ) {
      if (data.data.attributes.is_existing) {
        state.currentAuthStep = 'login';
      } else {
        state.currentAuthStep = 'signup';
      }
    },
    authenticationStepSubmitted(state, action: PayloadAction<boolean>) {
      state.authenticationStepSubmitted = action.payload;
    },
    authenticationStepFailed(state) {
      state.authenticationStepSubmitted = false;
      state.authenticationStepFailure = true;
    },
    clearAuthStepErrors(state) {
      state.authenticationStepFailure = false;
      state.invalidCaptcha = false;
    },
    userSignedUp(state) {
      state.currentAuthStep = 'activation';
      state.signupErrors = [];
    },
    invalidCaptchaProvided(state) {
      state.invalidCaptcha = true;
    },
    signUpFailed(
      state,
      { payload: errorMessages }: PayloadAction<Array<string>>
    ) {
      state.signupErrors = errorMessages;
    },
    showForgotPassword(state) {
      state.currentAuthStep = 'forgotPassword';
    },
    forgotPasswordSubmitted(state) {
      state.forgotPasswordSubmitted = true;
    },
    loginSuccess(state, { payload: user }: PayloadAction<userState>) {
      state.authPromptMessage = null;
      state.currentAuthStep = 'hidden';
      state.me = user;
    },
    logoutSuccess(state) {
      state.authPromptMessage = null;
      state.me = undefined;
    },
    accountActivationNeeded(state) {
      state.currentAuthStep = 'activation';
    }
  }
});

export const {
  resetCurrentUserState,
  fetchCurrentUserSuccess,
  blockUserFromChatSuccess,
  unblockUserFromChatSuccess,
  getFollowedIdsSuccess,
  followUserSuccess,
  unfollowUserSuccess,
  showAuthenticationEmailPromptForm,
  cancelAuthForm,
  existenceStatusFetched,
  authenticationStepSubmitted,
  authenticationStepFailed,
  clearAuthStepErrors,
  userSignedUp,
  invalidCaptchaProvided,
  signUpFailed,
  loginSuccess,
  logoutSuccess,
  accountActivationNeeded,
  userActivated,
  activationCodeResent,
  initializeActivationCodeDelivery,
  showForgotPassword,
  forgotPasswordSubmitted
} = currentUserSlice.actions;

export default currentUserSlice.reducer;

const mapApiFollowedIdsToState = (ids: ApiGetFollowedIdsResponse): string[] => {
  return ids.map((id) => id.toString());
};

export const fetchFollowedIds =
  (userId: string): AppThunk =>
  async (dispatch) => {
    let ids: number[] = [];
    try {
      ids = await apiGetFollowedIds(userId);
    } catch (err) {
      airbrakeNetworkError(err);
    }
    dispatch(getFollowedIdsSuccess(mapApiFollowedIdsToState(ids)));
  };

export const followUser =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await apiFollowUser(userId);
      if (response.success === true) {
        dispatch(followUserSuccess(userId));
      }
    } catch (err) {
      console.log('Error: ', err);
    }
  };

export const unfollowUser =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await apiUnfollowUser(userId);
      if (response.success === true) {
        dispatch(unfollowUserSuccess(userId));
      }
    } catch (err) {
      console.log('Error: ', err);
    }
  };

export const login =
  (email: string, password: string, rememberMe: string): AppThunk =>
  async (dispatch) => {
    dispatch(authenticationStepSubmitted(true));
    dispatch(clearAuthStepErrors());
    const response = await apiLogin(email, password, rememberMe);

    if (response.ok) {
      const data = await response.json();
      const user = mapApiV3Me(data);

      dispatch(loginSuccess(user));
      // TODO: Fetch it again only if channel requires subscription
      const subdomain = useSubdomain();
      dispatch(fetchChannelView(subdomain));
    } else if (response.status === 403) {
      dispatch(accountActivationNeeded());
    } else if (response.status === 401) {
      dispatch(authenticationStepFailed());
    }

    dispatch(authenticationStepSubmitted(false));
  };

export const verifyUserExistence =
  (email: string): AppThunk =>
  async (dispatch) => {
    dispatch(authenticationStepSubmitted(true));
    try {
      dispatch(clearAuthStepErrors());
      const data = await apiGetUserExistenceStatus(email);
      dispatch(existenceStatusFetched(data));
    } catch (err) {
      dispatch(authenticationStepFailed());
    }
    dispatch(authenticationStepSubmitted(false));
  };

export const signUp =
  (
    email: string,
    username: string,
    password: string,
    captchaToken: string,
    channelOwnerId: string
  ): AppThunk =>
  async (dispatch) => {
    dispatch(authenticationStepSubmitted(true));
    try {
      dispatch(clearAuthStepErrors());
      await apiCreateUser(
        email,
        username,
        password,
        captchaToken,
        channelOwnerId
      );

      dispatch(userSignedUp());
    } catch (err) {
      if (err.status === 400) {
        dispatch(invalidCaptchaProvided());
      } else {
        const signupErrors = await err.json();
        const errorMessages = signupErrors.data.attributes.errors.map(
          (error: any) => error.detail
        );

        dispatch(signUpFailed(errorMessages));
      }
    }
    dispatch(authenticationStepSubmitted(false));
  };

export const activateAccount =
  (email: string, code: string): AppThunk =>
  async (dispatch) => {
    dispatch(authenticationStepSubmitted(true));
    try {
      dispatch(clearAuthStepErrors());
      const data = await apiCreateUserActivation(email, code);
      const user = mapApiV3Me(data);

      dispatch(userActivated(user));
      dispatch(
        createAlert({
          message: 'Account activated, welcome to Mixlr!',
          type: 'success'
        })
      );
    } catch (err) {
      dispatch(authenticationStepFailed());
    }
    dispatch(authenticationStepSubmitted(false));
  };

export const resendActivationCode =
  (email: string): AppThunk =>
  async (dispatch) => {
    dispatch(initializeActivationCodeDelivery());
    try {
      await apiResendActivation(email);
      dispatch(activationCodeResent());
    } catch (err) {
      console.log(err);
    }
  };

export const forgotPassword =
  (email: string): AppThunk =>
  async (dispatch) => {
    dispatch(authenticationStepSubmitted(true));
    const response = await apiForgotPassword(email);

    if (response.ok || response.status === 404) {
      dispatch(forgotPasswordSubmitted());
    } else {
      dispatch(
        createAlert({
          message: 'Unable to submit. Please try again later',
          type: 'error'
        })
      );
    }
    dispatch(authenticationStepSubmitted(false));
  };
