import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import {
  cancelAuthForm,
  showAuthenticationEmailPromptForm,
  forgotPassword
} from 'features/current-user/currentUserSlice';
import { ModalWrapper } from 'components/Modal/Wrapper';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { FormSubmitButton } from 'components/Forms/SubmitButton';
import { FormMessage } from 'components/Forms/Messages';

export const ForgotPassword: FunctionComponent<{ inputEmail: string }> = (
  props
) => {
  const dispatch = useDispatch();
  const {
    currentAuthStep,
    authenticationStepSubmitted,
    forgotPasswordSubmitted
  } = useSelector((state: RootState) => state.currentUser);

  const cancelForm = () => {
    dispatch(cancelAuthForm());
  };

  const login = () => {
    dispatch(showAuthenticationEmailPromptForm());
  };

  const submit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(forgotPassword(props.inputEmail));
  };

  return (
    <ModalWrapper
      isOpen={currentAuthStep === 'forgotPassword'}
      title={'Forgot Password'}
      onRequestClose={cancelForm}
      showChannelLogo
      showMixlrText
    >
      <p className="text--center">
        Request a password reset link for <strong>{props.inputEmail}</strong>
      </p>
      <form onSubmit={submit} className="form">
        <div className="form__row form__row--submit">
          <FormSubmitButton
            label={'Reset password'}
            disabled={authenticationStepSubmitted}
          />
        </div>
        <div className="form__row">
          {forgotPasswordSubmitted && (
            <FormMessage
              text={'Reset password mail was sent, please check your inbox.'}
              type={'success'}
              align={'center'}
            />
          )}
        </div>
        <ul className="form__links list--reset text--center">
          <li>
            <ButtonLink label={'Back'} onClick={login} />
          </li>
        </ul>
      </form>
    </ModalWrapper>
  );
};
