import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { AnimatePresence } from 'framer-motion';
import { ChatComment } from 'features/chat/components/ChatComment';
import { ChatMessageModel } from 'features/chat/ChatMessageModel';

interface Props {
  messages: ChatMessageModel[];
}

export const ChatComments: FunctionComponent<Props> = ({ messages }) => {
  return (
    <ul className={styles['chat__list']}>
      <AnimatePresence initial={false}>
        {messages.map((comment) => (
          <ChatComment
            key={comment.id}
            id={comment.id}
            comment={comment.content}
            user={comment.display_name}
            userId={comment.user_id}
            date={comment.parsedCreatedAt}
            img={comment.profile_image_url}
            type={comment.type}
            heartScale={comment.heartScale}
          />
        ))}
      </AnimatePresence>
    </ul>
  );
};
