import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CollectionModel } from 'features/collections/CollectionModel';
import { collectionSelector } from 'features/collections/collectionsSelectors';
import { fetchCollection } from './collectionsSlice';
import { useSubdomain } from 'hooks/useSubdomain';

export const useCollection = (id?: string): CollectionModel | null => {
  const collection = useSelector((state: RootState) =>
    collectionSelector(state, id)
  );

  return collection;
};

export const useCurrentCollection = (): CollectionModel | null => {
  const currentCollectionId = useSelector(
    (state: RootState) => state.collections.currentCollectionId
  );
  return useSelector((state: RootState) =>
    collectionSelector(state, currentCollectionId)
  );
};

export const useFetchCollection = (
  id: string | null | undefined,
  collection: CollectionModel | null,
  hideLoader?: boolean
): null => {
  const dispatch = useDispatch();
  const channelSubdomain = useSubdomain();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && channelSubdomain && !collection) {
      dispatch(fetchCollection(channelSubdomain, id, navigate, hideLoader));
    }
  }, [id, channelSubdomain, dispatch, navigate, hideLoader, collection]);

  return null;
};
