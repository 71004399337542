import React, { FunctionComponent } from 'react';
import { FullScreenContent } from 'components/Layouts/FullScreenView/FullScreenContent';
import { RecordingPlayer } from '../RecordingPlayer';
import { RecordingModel } from 'features/recordings/RecordingModel';

interface RecordingEmbedContentProps {
  recording: RecordingModel | null;
}

export const RecordingEmbedContent: FunctionComponent<
  RecordingEmbedContentProps
> = ({ recording }) => {
  if (!recording) {
    return null;
  }

  return <FullScreenContent centerColumn={<RecordingPlayer />} />;
};
