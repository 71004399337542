import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { Icon } from 'components/Icons';

interface ButtonLinkProps {
  label: any;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: string;
  iconSize?: number;
  disabled?: boolean;
  renderInText?: boolean;
}

export const ButtonLink: FunctionComponent<ButtonLinkProps> = (props) => {
  return (
    <button
      className={classNames(styles['button--link'], {
        [styles['button--link-inline']]: props.renderInText
      })}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.icon && (
        <Icon
          hidden={true}
          icon={props.icon}
          label={props.label}
          height={props.iconSize}
          width={props.iconSize}
        />
      )}
      {props.label}
    </button>
  );
};
