import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

interface SEOHeaderTagsProps {
  title: string;
  description: string;
  image?: string | null;
  url: string;
}

export const SEOHeaderTags: FunctionComponent<SEOHeaderTagsProps> = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta property="og:title" content={props.title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={props.description} />
      {props.image && <meta property="og:image" content={props.image} />}
      <meta property="og:url" content={props.url} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};
