import React, { FunctionComponent, useContext } from 'react';
import { Chat } from 'features/chat/Chat';
import { EventModel } from 'features/events/EventModel';
import { SquareImage } from 'components/Images/SquareImage';
import { useAppSelector } from 'hooks/redux';
import { useIsEventLive } from './eventHooks';
import { useAudio } from 'features/audio/audio';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import { IconButton } from 'components/Buttons/IconButton';
import { EventMeta } from './components/EventMeta';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { ListenerCount } from 'components/ListenerCount';
import { HeartCount } from 'components/Hearts/HeartCount';

interface EventChatWithPlayerProps {
  event: EventModel | null;
}

export const EventChatWithPlayer: FunctionComponent<
  EventChatWithPlayerProps
> = ({ event }) => {
  const isLive = useIsEventLive(event);
  const loading = useAppSelector((state) => state.audio.loading);
  const playing = useAppSelector((state) => state.audio.playing);
  const audioUrl = useAppSelector((state) => state.audio.url);
  const connecting = useAppSelector((state) => state.audio.connecting);
  const { audio, loadAudio, play, stop } = useAudio();
  const isChatVisible = useAppSelector((state) => state.chat.isVisible);
  const shouldReduceMotion = useReducedMotion();
  const { isCreatorAppWebview } = useContext(UserAgentContext);

  const onClick = () => {
    if (!audioUrl) return;
    if (audio) {
      if (playing) {
        stop();
      } else {
        play();
      }
    } else {
      loadAudio({
        audioSrc: audioUrl,
        autoPlay: true,
        audioType: 'event'
      });
    }
  };

  const animationVariant = {
    visible: { y: 0 },
    hidden: { y: '100%' }
  };

  if (!event || !event.chatEnabled) {
    return null;
  }

  return (
    <AnimatePresence initial={false}>
      {isChatVisible && (
        <motion.div
          className={`tw-flex tw-flex-col tw-fixed tw-bottom-0 tw-right-0 tw-left-0 ${
            isCreatorAppWebview ? 'tw-top-[110px]' : 'tw-top-[64px]'
          } tw-z-[500]`}
          transition={{ duration: shouldReduceMotion ? 0 : 0.2 }}
          initial={'hidden'}
          animate={'visible'}
          exit={'hidden'}
          variants={animationVariant}
        >
          <div className="tw-flex-none tw-p-4 tw-bg-theme-color tw-text-white [.light-contrast_&]:tw-text-black">
            <div className="tw-flex tw-items-center tw-gap-4">
              <div className="tw-h-[48px] tw-w-[48px] tw-flex-none">
                <SquareImage artworkUrl={event.artwork.image.small} />
              </div>
              <div className="tw-flex-1 tw-leading-8 tw-overflow-hidden">
                <strong className="tw-block tw-font-bold tw-truncate tw-text-ellipsis tw-m-0">
                  {event.title}
                </strong>
                <span className="tw-block tw-text-base tw-flex tw-space-x-4 [&_svg]:tw-h-[12px] [&_svg]:tw-w-[12px]">
                  <EventMeta />
                  {isCreatorAppWebview && <ListenerCount />}
                  <HeartCount />
                </span>
              </div>
              {isLive && !isCreatorAppWebview && (
                <div className="tw-flex-none">
                  <IconButton
                    label={playing ? `Stop audio` : 'Play audio'}
                    onClick={onClick}
                    icon={playing ? 'stop' : 'play'}
                    isLight={true}
                    isLoading={loading || connecting}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="tw-flex-1">
            <Chat fetchHistory={true} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
