"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.linkedin = void 0;
var types_1 = require("../types");
exports.linkedin = {
    name: 'linkedin',
    matches: [
        {
            match: '(https?://)?(www.)?linkedin.com/in/({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://linkedin.com/in/{PROFILE_ID}'
        },
        {
            match: '(https?://)?(www.)?linkedin.com/mwlite/in/({PROFILE_ID})/?', group: 3, type: types_1.TYPE_MOBILE,
            pattern: 'https://linkedin.com/mwlite/in/{PROFILE_ID}'
        },
        { match: '({PROFILE_ID})', group: 1 },
    ]
};
