import { FullImage } from 'components/Images/FullImage';
import { Collection } from 'features/collections/collectionsSlice';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import tinycolor from 'tinycolor2';

export interface CollectionListItemProps {
  collection: Collection;
}

export const CollectionListItem: FunctionComponent<CollectionListItemProps> = ({
  collection
}) => {
  const [colorContrastClass, setColorContrastClass] = useState<string>('');

  useEffect(
    function setCollectionColorContrast() {
      if (!collection) return;
      const color = tinycolor(collection.color);
      const colorIsLight = color.getLuminance() > 0.5;
      setColorContrastClass(colorIsLight ? 'light-contrast' : 'dark-contrast');
    },
    [collection, setColorContrastClass]
  );

  return (
    <article
      className={`tw-overflow-hidden tw-relative tw-h-[180px] md:tw-h-[260px] tw-flex tw-rounded-[12px] ${colorContrastClass}`}
    >
      <Link
        to={`/collections/${collection.id}`}
        state={{
          prevPath: location.pathname,
          animate: true,
          hideLoader: true
        }}
        className={`tw-flex tw-justify-center tw-items-center tw-flex-1 hover:tw-text-opacity-50${
          collection.artworkKey
            ? ' tw-text-white hover:tw-text-white'
            : ' tw-text-white hover:tw-text-white [.light-contrast_&]:tw-text-black [.light-contrast_&]:hover:tw-text-black'
        }`}
      >
        <FullImage
          artworkUrl={collection.artwork?.image.large || null}
          artworkMode={collection.artworkKey ? 'recording' : 'theme'}
          type={'recording'}
        />
        {collection.artworkKey && (
          <div className="tw-absolute tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-bg-black/30"></div>
        )}
        <div
          className={`tw-relative tw-text-center${
            collection.artworkKey
              ? ' tw-text-white hover:tw-text-theme-color'
              : ''
          }`}
        >
          <h2 className="tw-m-0 tw-text-3xl md:tw-text-5xl [.ios-listener_&]:tw-text-xl [.android-listener_&]:tw-text-3xl">
            {collection.title}
          </h2>
        </div>
      </Link>
    </article>
  );
};
