import React, { FunctionComponent } from 'react';
import { DeepLinkBanner } from 'components/DeepLinkBanner';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { useAppSelector } from 'hooks/redux';
import { cloudflareUrl } from 'utilities/cloudflareImageResizer';

export const EventDeepLinkBanner: FunctionComponent = () => {
  const { nativeUrlSchemeLink } = useNativeEvents();
  const requiresAccesscode = useAppSelector(
    (state) => state.channel.requiresAccesscode
  );
  const channelDetails = useAppSelector(
    (state) => state.channel.channelDetails
  );
  const channelAccesscodeDetails = useAppSelector(
    (state) => state.channel.accessCodeDetails
  );

  const deepLink = nativeUrlSchemeLink('mixlr', 'event', {
    url: window.location.href,
    channel_url: window.location.origin,
    channel_name: requiresAccesscode
      ? channelAccesscodeDetails?.username
      : channelDetails?.username,
    channel_theme_color: requiresAccesscode
      ? channelAccesscodeDetails?.themeColor
      : channelDetails?.themeColor,
    channel_logo_url: cloudflareUrl(
      requiresAccesscode
        ? channelAccesscodeDetails?.logoKey
        : channelDetails?.logoKey,
      {
        width: 100,
        quality: 80,
        baseUrl: process.env.REACT_APP_STORAGE_CDN_URL
      }
    )
  });

  return <DeepLinkBanner deepLink={deepLink} />;
};
