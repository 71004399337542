import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { channelSocialLinksSelector } from 'features/channel/channelSelectors';
import { removePunctuation } from 'utilities/formatters';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { SocialLinks } from 'social-links';
import { IconButton } from 'components/Buttons/IconButton';

interface SocialLinksProps {
  iconList?: boolean;
}

export const ChannelSocialLinks: FunctionComponent<SocialLinksProps> = (
  props
) => {
  const channelSocialLinks = useSelector(channelSocialLinksSelector);

  const renderIcon = (name: string, link: string) => {
    const socialLinks = new SocialLinks();
    // If creator adds a social link within blog / website
    const profileName = socialLinks.detectProfile(link);
    if ((name === 'Website' || name === 'Blog') && profileName)
      return profileName;
    return removePunctuation(name).toLowerCase();
  };

  if (!channelSocialLinks) {
    return null;
  }

  return (
    <ul
      className={classNames('list--reset', styles['social-links'], {
        [styles['social-links--icons']]: props.iconList
      })}
    >
      {channelSocialLinks.map((link) => (
        <li key={link.url}>
          <IconButton
            showLabel={!props.iconList}
            icon={renderIcon(link.name, link.url)}
            label={link.url}
            href={link.url}
            external={true}
            noBackground={true}
            target="_blank"
            rel="noopener noreferrer"
          />
        </li>
      ))}
    </ul>
  );
};
