import { DateTime } from 'luxon';
import { MessageState } from 'features/chat/chatSlice';

export interface ChatMessageModel extends MessageState {
  parsedCreatedAt: DateTime;
  isRecent(): boolean;
}

export const ChatMessageModel = (message: MessageState): ChatMessageModel => {
  return {
    parsedCreatedAt: DateTime.fromISO(message.created_at),
    ...message,

    isRecent() {
      const cutoff = DateTime.now().minus({ hours: 24 });

      return this.parsedCreatedAt > cutoff;
    }
  };
};
