import { Collection } from 'features/collections/collectionsSlice';

export interface CollectionModel extends Collection {
  isPublic(): boolean;
}

export const compareById = (
  collection1: Collection,
  collection2: Collection
): number => {
  if (parseInt(collection1.id) < parseInt(collection2.id)) {
    return 1;
  } else {
    return -1;
  }
};

export const CollectionModel = (collection: Collection): CollectionModel => {
  return {
    ...collection,
    isPublic() {
      return this.accessLevel == 'public';
    }
  };
};
