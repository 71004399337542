import { AppThunk } from 'store';
import { setMiniPlayerVisible } from 'features/channel/channelSlice';
import { setCurrentEventId } from 'features/events/eventsSlice';
import { setAudioEndedTimestamp } from 'features/broadcast/broadcastsSlice';
import { setAutoplay } from 'features/events/eventsSlice';
import { setCurrentRecording } from 'features/recordings/recordingsSlice';

export const unloadEvent = (): AppThunk => async (dispatch) => {
  dispatch(setMiniPlayerVisible(false));
  dispatch(setCurrentEventId(null));
  dispatch(setAutoplay(false));
  dispatch(setAudioEndedTimestamp(null));
};

export const unloadRecording = (): AppThunk => async (dispatch) => {
  dispatch(setMiniPlayerVisible(false));
  dispatch(setCurrentRecording(null));
  dispatch(setAutoplay(false));
};

export const loadEvent =
  (eventId: string | null): AppThunk =>
  async (dispatch) => {
    dispatch(setCurrentRecording(null));
    dispatch(setCurrentEventId(eventId || null));
    dispatch(setAutoplay(false));
    dispatch(setAudioEndedTimestamp(null));
  };
