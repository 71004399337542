import React, { FunctionComponent } from 'react';
import { Button } from 'components/Buttons/Button';
import buttonStyles from 'components/Buttons/styles.module.scss';
import { Loader } from 'components/Loader';

interface FormLoadingButtonProps {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

export const FormLoadingButton: FunctionComponent<FormLoadingButtonProps> = (
  props
) => {
  return (
    <Button
      className={buttonStyles['button--loader']}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.disabled && <Loader />}
      {props.label}
    </Button>
  );
};
