import { V3Event } from 'api/v3/types/event';
import { V3PrivateEvent } from 'api/v3/types/private_event';

export async function apiV3GetEvent(
  subdomain: string,
  eventId: string
): Promise<V3Event | V3PrivateEvent> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/channels/${subdomain}/events/${eventId}`,
    { credentials: 'include' }
  );

  if (results.ok) {
    const json = await results.json();

    return json;
  } else {
    throw results;
  }
}
