import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useCurrentEvent, useIsEventLive } from 'features/events/eventHooks';
import { liveEventSelector } from 'features/events/eventsSelectors';
import { AutoplayTimer } from 'features/events/components/AutoplayTimer';
import { setAutoplay } from 'features/events/eventsSlice';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

export const AutoplayPrompt: FunctionComponent = () => {
  const dispatch = useDispatch();
  const liveEvent = useSelector(liveEventSelector);
  const currentEvent = useCurrentEvent();
  const isCurrentEventLive = useIsEventLive(currentEvent);

  const lastAudioEndedTimestamp = useSelector(
    (state: RootState) => state.broadcasts.lastAudioEndedTimestamp
  );

  const autoplay = useSelector(
    (state: RootState) => state.events.autoPlayNextEvent
  );

  const cutoff = DateTime.now().minus({ minutes: 3 }).toSeconds();

  const audioRecentlyEnded =
    (lastAudioEndedTimestamp && lastAudioEndedTimestamp > cutoff) || false;

  const eventRecentlyEnded =
    (currentEvent?.parsedEndedAt &&
      currentEvent.parsedEndedAt.toSeconds() > cutoff) ||
    false;

  const recentlyEnded = audioRecentlyEnded || eventRecentlyEnded;

  const alreadyOnLiveEvent: boolean = liveEvent?.id === currentEvent?.id;

  const displayTimer: boolean =
    recentlyEnded && !isCurrentEventLive && !alreadyOnLiveEvent;

  useEffect(() => {
    if (autoplay && !displayTimer) {
      if (!isCurrentEventLive) {
        dispatch(setAutoplay(false));
      }
    }
  }, [
    autoplay,
    displayTimer,
    dispatch,
    isCurrentEventLive,
    recentlyEnded,
    audioRecentlyEnded,
    eventRecentlyEnded,
    alreadyOnLiveEvent,
    currentEvent?.parsedEndedAt,
    cutoff,
    lastAudioEndedTimestamp
  ]);

  return <AutoplayTimer enabled={displayTimer && autoplay} />;
};
