import { AppThunk } from 'store';
import { setHeartLoading } from 'features/broadcast/broadcastsSlice';
import { apiSendHeart } from 'api/hearts';
import { createAlert } from 'features/alert/alertSlice';

export const sendBroadcastHeart =
  (broadcastUID: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setHeartLoading(true));

      const response = await apiSendHeart(broadcastUID);
      if (response.status === 200) {
        setTimeout(() => {
          dispatch(setHeartLoading(false));
        }, 10000);
      }
    } catch (err) {
      dispatch(setHeartLoading(false));
      if (err?.status === 400) {
        const response = err as Response;
        const json = await response.json();
        dispatch(
          createAlert({
            message: json.error,
            type: 'error'
          })
        );
      }
    }
  };
