export async function apiSendHeart(broadcastUID: string): Promise<Response> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/broadcasts/${broadcastUID}/hearts`,
    {
      method: 'POST',
      credentials: 'include'
    }
  );

  if (results.ok) {
    return results;
  } else {
    throw results;
  }
}
