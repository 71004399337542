"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.medium = void 0;
var types_1 = require("../types");
exports.medium = {
    name: 'medium',
    matches: [
        {
            match: '(https?://)?(www.)?medium.com/@({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://medium.com/@{PROFILE_ID}'
        },
        { match: '@?({PROFILE_ID})', group: 1 },
    ]
};
