import React, { FunctionComponent } from 'react';
import { ErrorWrapper } from 'components/ErrorPages/ErrorWrapper';

interface ErrorEmbedProps {
  type: 'event' | 'recording' | 'private';
}

export const ErrorEmbed: FunctionComponent<ErrorEmbedProps> = ({ type }) => {
  return (
    <ErrorWrapper type={type}>
      <h1>{type} not available</h1>
    </ErrorWrapper>
  );
};
