import { createSelector } from 'reselect';
import { RootState } from 'rootReducer';

export const channelSocialLinksSelector = createSelector(
  (state: RootState) => state.channel.channelDetails?.links,
  (links) => {
    if (!links) return null;
    const filteredLinks = links.filter((link) => link.name !== 'Support');
    if (filteredLinks.length === 0) return null;
    return filteredLinks;
  }
);

export const channelSupportLinkSelector = createSelector(
  (state: RootState) => state.channel.channelDetails?.links,
  (links) => {
    if (!links) return null;
    const supportLink = links.filter((link) => link.name === 'Support');
    if (supportLink.length === 0) return null;
    return supportLink[0];
  }
);
