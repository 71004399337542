import { IFrame } from 'sanitize-html';

export const gifUrls =
  /\b(https?:\/\/(.+?\.)?(i.giphy|media(?:[0-9]).giphy|media.tenor|media(?:[0-9]).tenor)\S+(?:gif)\S*)\b/;

export const santizeConfig = () => {
  const config = {
    allowedTags: ['a', 'img', 'br'],
    allowedAttributes: {
      a: ['href', 'target', 'title'],
      img: ['src', 'alt'],
      allowedSchemes: ['https']
    },
    exclusiveFilter: (frame: IFrame) => {
      return frame.tag === 'img' && !gifUrls.test(frame.attribs.src);
    },
    textFilter: (text: string) => {
      return text.replace(gifUrls, `<img src="$&" alt="">`);
    }
  };
  return config;
};
