import { AppThunk } from 'store';
import { setConnecting, setLoading } from 'features/audio/audioSlice';

let abortController = new AbortController();
let fetchAudioTimeout: NodeJS.Timeout;

const fetchAudioUrl = async (
  src: string,
  audioType: string,
  setSrc: (_: string) => void,
  retries = 0
) => {
  if (audioType === 'recording') {
    const results = await fetch(src, { method: 'GET' });
    if (results?.status === 200) {
      setSrc(src);
    }
  } else {
    abortController = new AbortController();
    const signal = abortController.signal;
    const results = await fetch(src, { method: 'GET', signal });

    // Retry every 6 seconds for 5 minutes.
    if (results?.status !== 200 && retries < 50) {
      fetchAudioTimeout = setTimeout(() => {
        fetchAudioUrl(src, audioType, setSrc, retries + 1);
      }, 6000);
    } else {
      fetchAudioAbort();
      setSrc(src);
    }
  }
};

export const fetchAudioAbort = () => {
  if (abortController.signal.aborted) {
    return;
  }
  clearInterval(fetchAudioTimeout);
  abortController.abort();
};

export const checkAndPlayAudio =
  (src: string, audioType: string, setSrc: (_: string) => void): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setConnecting(true));

    await fetchAudioUrl(src, audioType, setSrc);
  };
