import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext
} from 'react';
import { apiV3GetPrices } from 'api/v3/channel_view';
import { V3ChannelSubscriptionPrices } from 'api/v3/types/channel_subscription_prices';
import { AccessView } from 'components/Layouts/AccessView';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { SubscriptionMessage } from './components/SubscriptionMessage';
import { SubscriptionNativeMessage } from './components/SubscriptionNativeMessage';
import { SubscriptionPrices } from './components/SubscriptionPrices';
import { SubscriptionLoginPrompt } from './components/SubscriptionLoginPrompt';
import { Loader } from 'components/Loader';
import { usePageLoaded } from 'hooks/usePageLoaded';
import { useAppSelector } from 'hooks/redux';

export const ChannelSubscription: FunctionComponent = () => {
  const [prices, setPrices] = useState<V3ChannelSubscriptionPrices>();
  const [loading, setLoading] = useState(true);

  const id =
    useAppSelector((state) => state.channel.paidChannelDetails?.id) || -1;
  const artwork = useAppSelector(
    (state) => state.channel.paidChannelDetails?.channelArtwork
  );
  const logo = useAppSelector(
    (state) => state.channel.paidChannelDetails?.channelLogo
  );
  const username = useAppSelector(
    (state) => state.channel.paidChannelDetails?.username || ''
  );
  const { isNativeAppWebview, isEmbed } = useContext(UserAgentContext);

  usePageLoaded();

  useEffect(() => {
    async function f() {
      const fetchedPrices = await apiV3GetPrices(Number(id));
      setPrices(fetchedPrices);
      setLoading(false);
    }
    f();
  }, [id]);

  const RenderSubscription = () => {
    if (loading) {
      return <Loader fillComponent={true} />;
    } else if (isNativeAppWebview || isEmbed) {
      return <SubscriptionNativeMessage />;
    }
    return (
      <>
        <h2 className="mar-n mar-t">{username}</h2>
        <SubscriptionMessage />
        <SubscriptionPrices prices={prices} />
        <SubscriptionLoginPrompt />
      </>
    );
  };

  return (
    <AccessView
      artwork={artwork}
      logo={logo}
      username={username}
      size={isNativeAppWebview || isEmbed ? null : 'wide'}
    >
      <RenderSubscription />
    </AccessView>
  );
};
