import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import {
  cancelAuthForm,
  login,
  showForgotPassword
} from 'features/current-user/currentUserSlice';
import { createAlert } from 'features/alert/alertSlice';
import { ModalWrapper } from 'components/Modal/Wrapper';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { FormSubmitButton } from 'components/Forms/SubmitButton';
import { FormMessage } from 'components/Forms/Messages';

export const LoginForm: FunctionComponent<{ inputEmail: string }> = (props) => {
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const dispatch = useDispatch();
  const {
    me,
    currentAuthStep,
    authenticationStepFailure,
    authenticationStepSubmitted
  } = useSelector((state: RootState) => state.currentUser);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleRememberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };

  const cancelForm = () => {
    dispatch(cancelAuthForm());
  };

  const submit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(login(props.inputEmail, password, String(rememberMe)));
  };

  const forgotPassword = () => {
    dispatch(showForgotPassword());
  };

  if (!(currentAuthStep === 'login')) return null;

  if (me && currentAuthStep === 'login') {
    dispatch(
      createAlert({ message: 'You are already logged in', type: 'error' })
    );
    return null;
  }

  return (
    <ModalWrapper
      isOpen={currentAuthStep === 'login'}
      title={'Account sign in'}
      onRequestClose={cancelForm}
      showChannelLogo
      showMixlrText
    >
      <p className="text--center">
        Enter your password to login with <strong>{props.inputEmail}</strong>
      </p>
      <form onSubmit={submit} className="form">
        <div className="form__row">
          <label className="form__label" htmlFor="password">
            Password
          </label>
          <input
            className="form__input"
            id="password"
            type="password"
            name="password"
            onChange={handlePasswordChange}
            required
          />
          {authenticationStepFailure && (
            <FormMessage
              text={"Username and password don't match."}
              type={'error'}
            />
          )}
        </div>
        <div className="form__row form__row--submit">
          <FormSubmitButton
            label={'Login'}
            disabled={authenticationStepSubmitted}
          />
        </div>
        <div className="form__row text--center">
          <label>
            <input
              className="form__checkbox"
              type="checkbox"
              name="rememberMe"
              onChange={handleRememberChange}
            />
            Keep me logged in
          </label>
        </div>
        <ul className="form__links list--reset text--center">
          <li>
            <ButtonLink label={'Forgot Password?'} onClick={forgotPassword} />
          </li>
        </ul>
      </form>
    </ModalWrapper>
  );
};
