import React, { FunctionComponent, useContext } from 'react';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { ThemeContext } from 'features/theme/theme';
import { IconButton } from 'components/Buttons/IconButton';
import { useIsFullScreenView } from 'features/theme/themeHooks';

export const DarkModeSwitch: FunctionComponent = () => {
  const { isNativeAppWebview, isEmbed } = useContext(UserAgentContext);
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const isFullScreenView = useIsFullScreenView();

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  if (isNativeAppWebview || isEmbed) {
    return null;
  }

  return (
    <IconButton
      label={'Toggle dark mode'}
      onClick={toggleDarkMode}
      icon={darkMode ? 'moon' : 'sun'}
      isTransparent={isFullScreenView}
      isLight={!isFullScreenView}
    />
  );
};
