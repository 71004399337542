import React, { FunctionComponent, useContext } from 'react';
import { FullScreenWrapper } from 'components/Layouts/FullScreenView/FullScreenWrapper';
import styles from './styles.module.scss';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { ThumbImage } from 'components/Images/ThumbImage';
import { LogoImage } from 'components/LogoImage';
import classNames from 'classnames';
import { ChannelDeepLinkBanner } from 'features/channel/components/ChannelDeepLinkBanner';
import { matchPath, useLocation } from 'react-router-dom';
import { EventDeepLinkBanner } from 'features/events/components/EventDeepLinkBanner';
import { Media } from 'api/v3/types/channel_view';

interface AccessViewProps {
  children: React.ReactNode;
  artwork: Media | undefined;
  logo: Media | undefined;
  username: string;
  size?: 'wide' | null;
}

export const AccessView: FunctionComponent<AccessViewProps> = ({
  children,
  logo,
  artwork,
  username,
  size
}) => {
  const { isEmbed } = useContext(UserAgentContext);
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);

  return (
    <div className={styles['access']}>
      {isEventPath ? <EventDeepLinkBanner /> : <ChannelDeepLinkBanner />}
      <FullScreenWrapper
        artwork={artwork}
        artworkMode={'private'}
        type={'private'}
      >
        <div
          className={classNames(styles['access-modal'], {
            [styles['access-modal--wide']]: size == 'wide'
          })}
        >
          <div className={styles['access-modal__content']}>
            <LogoImage size={isEmbed ? 'small' : 'medium'} hasFallback={!logo}>
              <ThumbImage
                artworkUrl={logo?.image.small || null}
                artworkMode={'logo'}
                alt={`Logo for ${username}`}
              />
            </LogoImage>
            {children}
          </div>
        </div>
      </FullScreenWrapper>
    </div>
  );
};
