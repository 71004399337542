import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import {
  activateAccount,
  cancelAuthForm,
  resendActivationCode
} from 'features/current-user/currentUserSlice';
import { createAlert } from 'features/alert/alertSlice';
import { ModalWrapper } from 'components/Modal/Wrapper';
import { FormSubmitButton } from 'components/Forms/SubmitButton';
import { FormLoadingButton } from 'components/Forms/LoadingButton';
import { FormMessage } from 'components/Forms/Messages';

export const AccountActivationForm: FunctionComponent<{
  inputEmail: string;
}> = (props) => {
  const [code, setCode] = useState<string>('');

  const dispatch = useDispatch();
  const {
    me,
    currentAuthStep,
    authenticationStepFailure,
    authenticationStepSubmitted,
    codeDeliveryInProgress,
    displayCodeResent
  } = useSelector((state: RootState) => state.currentUser);

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const cancelForm = () => {
    dispatch(cancelAuthForm());
  };

  const submit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(activateAccount(props.inputEmail, code));
  };

  const handleResendActivation = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(resendActivationCode(props.inputEmail));
  };

  if (!(currentAuthStep === 'activation')) return null;

  if (me && currentAuthStep === 'activation') {
    dispatch(
      createAlert({ message: 'You are already logged in', type: 'error' })
    );
    return null;
  }

  return (
    <ModalWrapper
      isOpen={currentAuthStep === 'activation'}
      title={'Activate your account'}
      onRequestClose={cancelForm}
      showChannelLogo
      showMixlrText
    >
      <form onSubmit={submit} className="form">
        <p className="text--center">
          We have sent you an email with an activation code. Please paste it
          below to activate your account.
        </p>
        <div className="form__row">
          <label className="form__label" htmlFor="username">
            Activation Code
          </label>
          <input
            className="form__input"
            id="code"
            type="number"
            name="code"
            onChange={handleCodeChange}
            required
          />
          {authenticationStepFailure && (
            <FormMessage text={'Invalid activation code'} type={'error'} />
          )}
        </div>
        <div className="form__row form__row--submit">
          <FormSubmitButton
            label={'Activate account'}
            disabled={authenticationStepSubmitted}
          />
          <FormLoadingButton
            label={'Resend activation code.'}
            onClick={handleResendActivation}
            disabled={codeDeliveryInProgress}
          />
        </div>
        {displayCodeResent && (
          <FormMessage
            text={
              'A new activation code has been sent to your inbox, please check your email.'
            }
            type={'success'}
            align={'center'}
          />
        )}
      </form>
    </ModalWrapper>
  );
};
