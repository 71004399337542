import React, { FunctionComponent } from 'react';
import { EventModel } from 'features/events/EventModel';
import { Card } from 'components/Card';

export interface InfiniteScrollEventsProps {
  events: EventModel[];
}

export const InfiniteScrollEvents: FunctionComponent<
  InfiniteScrollEventsProps
> = ({ events }) => {
  return (
    <div className="container--wide">
      <h1>Events</h1>
      <ol className="list--reset tw-grid tw-grid-cols-12 tw-gap-4 md:tw-gap-8 lg:tw-gap-12">
        {events.map((event) => {
          return (
            <li
              className="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-4"
              key={event.id}
            >
              <Card
                id={event.id}
                url={`/events/${event.id}`}
                title={`${event.title}`}
                artwork={event.artwork}
                artworkKey={event.artworkKey}
                artworkMode={event.artworkMode}
                updatedTimestamp={event.lastUpdatedAt}
                isPrivate={event.isPrivate()}
                isActive={event.isLive()}
                type={'event'}
                color={event.color}
              />
            </li>
          );
        })}
      </ol>
    </div>
  );
};
