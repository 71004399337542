import React, { FunctionComponent } from 'react';
import { IconButton } from 'components/Buttons/IconButton';
import { SearchForm } from 'features/search/Components/SearchForm';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import { toggleSearchVisibility } from 'features/search/searchSlice';

export const SearchBar: FunctionComponent = () => {
  const dispatch = useDispatch();

  const closeSearch = () => {
    dispatch(toggleSearchVisibility(false));
  };

  return (
    <div className={styles['search__bar']}>
      <SearchForm placeholder="Search events & recordings..." />
      <IconButton
        label={'Close search'}
        onClick={closeSearch}
        icon={'x'}
        outline={true}
      />
    </div>
  );
};
