import { createSelector } from 'reselect';
import { RootState } from 'rootReducer';
import {
  Collection,
  CollectionMap,
  CollectionRecordingsPage
} from 'features/collections/collectionsSlice';
import {
  CollectionModel,
  compareById
} from 'features/collections/CollectionModel';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { wrapRecordingModel } from 'features/recordings/recordingsSelectors';

const wrapCollectionModel = (collections: CollectionMap): CollectionModel[] => {
  return Object.keys(collections).reduce(
    (collectionsArray: Collection[], key: string) => {
      collectionsArray.push(CollectionModel(collections[key]));
      return collectionsArray;
    },
    []
  ) as CollectionModel[];
};

export const allPublicCollectionsSelector = createSelector(
  (state: RootState) => state.collections.collections,
  (collections): CollectionModel[] => {
    const allPublicCollections = wrapCollectionModel({
      ...collections
    })
      .filter((collection) => collection.isPublic())
      .sort(compareById);

    return allPublicCollections;
  }
);

export const allPublicAndUnlistedCollectionRecordingsSelector = createSelector(
  [
    (state: RootState) => state.collections.collections,
    (state: RootState) => state.collections.currentCollectionId
  ],
  (
    collections: CollectionMap,
    currentCollectionId: string | null
  ): RecordingModel[] => {
    if (!currentCollectionId) {
      return [];
    }

    const collectionRecordings = collections[currentCollectionId]?.recordings;

    const allPublicAndUnlistedCollectionRecordings = wrapRecordingModel({
      ...collectionRecordings
    }).filter(
      (recording) =>
        (recording.isPublic() || recording.isUnlisted()) &&
        recording.shouldDisplayOnChannel
    );

    return allPublicAndUnlistedCollectionRecordings;
  }
);

export const collectionRecordingsPaginationStartIds = createSelector(
  [
    (state: RootState) => state.collections.collections,
    (state: RootState) => state.collections.currentCollectionId
  ],
  (
    collections: CollectionMap,
    currentCollectionId: string | null
  ): CollectionRecordingsPage[] => {
    if (!currentCollectionId) {
      return [];
    }
    const recordingsPaginationStartIds =
      collections[currentCollectionId]?.recordingsPaginationStartIds;
    return recordingsPaginationStartIds;
  }
);

export const collectionRecordingsPageCount = createSelector(
  [
    (state: RootState) => state.collections.collections,
    (state: RootState) => state.collections.currentCollectionId
  ],
  (collections: CollectionMap, currentCollectionId: string | null): number => {
    if (!currentCollectionId) {
      return 1;
    }
    const recordingsPageCount =
      collections[currentCollectionId]?.recordingsPageCount;
    return recordingsPageCount;
  }
);

export const collectionSelector = createSelector(
  [
    (state: RootState) => state.collections.collections,
    (state: RootState, id?: string | null) => id
  ],
  (collections: CollectionMap, id?: string | null): CollectionModel | null => {
    if (!id) {
      return null;
    }
    const collection = collections[id];

    if (!collection) {
      return null;
    }

    return CollectionModel(collection);
  },
  {
    memoizeOptions: {
      maxSize: 10
    }
  }
);
