import React, { FunctionComponent, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { EventModel } from 'features/events/EventModel';
import { upcomingOrLiveSelector } from 'features/events/eventsSelectors';
import { EmptyEvents } from 'features/channel/components/ChannelEvents/EmptyEvents';
import { PaginatedEvents } from 'features/channel/components/ChannelEvents/PaginatedEvents';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { InfiniteScrollEvents } from './InfiniteScrollEvents';

export const ChannelEvents: FunctionComponent = () => {
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const channelisLoaded = useSelector(
    (state: RootState) => state.channel.isLoaded
  );
  const allUpcomingEvents: EventModel[] = useSelector(upcomingOrLiveSelector);
  const { isListenerAppWebview } = useContext(UserAgentContext);
  const itemsPerPage = 21;

  if (!channelisLoaded || channel == null) {
    return null;
  }

  if (allUpcomingEvents.length === 0) {
    return <EmptyEvents username={channel.username} />;
  }

  if (isListenerAppWebview) {
    return <InfiniteScrollEvents events={allUpcomingEvents} />;
  }

  return (
    <PaginatedEvents events={allUpcomingEvents} itemsPerPage={itemsPerPage} />
  );
};
