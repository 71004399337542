import React, { FunctionComponent } from 'react';
import { useEvent, usePrivateEvent } from 'features/events/eventHooks';
import { useAppSelector } from 'hooks/redux';
import { currentEventBroadcastSelector } from 'features/broadcast/selectors';
import { DateTime } from 'luxon';
import { startTimeChecker } from 'utilities/startTimeChecker';
import styles from './styles.module.scss';

interface EventDateProps {
  eventId: string;
  cssClass?: string;
  hideTime?: boolean;
}

export const EventDate: FunctionComponent<EventDateProps> = (props) => {
  const event = useEvent(props.eventId);
  const privateEvent = usePrivateEvent(props.eventId);
  const currentEvent = event ? event : privateEvent;
  const currentBroadcast = useAppSelector(currentEventBroadcastSelector);

  if (!currentEvent) {
    return null;
  }

  const broadcastStartedAt = startTimeChecker(currentEvent, currentBroadcast);

  if (!broadcastStartedAt || !currentEvent.parsedLocalStartsAt) {
    return null;
  }

  const renderTime = () => {
    if (props.hideTime || currentEvent.isOverdue()) {
      return null;
    } else {
      <span className={styles['event-date__soon']}>
        {broadcastStartedAt.toRelative()}
      </span>;
    }
  };

  return (
    <time
      dateTime={currentEvent.parsedLocalStartsAt.toString()}
      className={props.cssClass}
    >
      {renderTime()}
      {!currentEvent.isLive() && (
        <span>
          {currentEvent.parsedLocalStartsAt.toLocaleString(
            DateTime.DATETIME_MED
          )}
        </span>
      )}
    </time>
  );
};
