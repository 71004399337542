"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tiktok = void 0;
var types_1 = require("../types");
exports.tiktok = {
    name: 'tiktok',
    matches: [
        {
            match: '(https?://)?(www.)?tiktok.com/@({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://tiktok.com/@{PROFILE_ID}'
        },
        { match: '@?({PROFILE_ID})', group: 1 },
    ]
};
