import React, { FunctionComponent } from 'react';
import { Button } from 'components/Buttons/Button';
import { RootState } from 'rootReducer';
import { useSelector } from 'react-redux';

export const SubscriptionNativeMessage: FunctionComponent = () => {
  const username = useSelector(
    (state: RootState) => state.channel.paidChannelDetails?.username || ''
  );

  return (
    <>
      <p className="mar-t">
        Visit {username}&#39;s channel to access their events & recordings
      </p>
      <Button
        className="mar-t"
        onClick={() => window.open(window.location.href, '_blank')}
      >
        Visit channel
      </Button>
    </>
  );
};
