import React, { FunctionComponent } from 'react';
import { SEOHeaderTags } from 'components/SEO/SEOHeaderTags';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { useSEOTitleDescription } from 'hooks/useSEOTitleDescription';

interface RecordingHeaderTagsProps {
  recording: RecordingModel | null;
}

export const RecordingHeaderTags: FunctionComponent<
  RecordingHeaderTagsProps
> = ({ recording }) => {
  const { pageTitle, pageDescription } = useSEOTitleDescription(
    recording?.title,
    recording?.description
  );

  if (!recording) {
    return null;
  }

  return (
    <SEOHeaderTags
      title={pageTitle}
      description={pageDescription}
      image={recording.artwork.image_seo.og_image}
      url={window.location.href}
    />
  );
};
