import { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { unloadEvent, unloadRecording } from 'thunks/navigationThunks';
import { useAudio } from 'features/audio/audio';
import {
  useLocation,
  NavigationType,
  useNavigationType,
  matchPath
} from 'react-router-dom';
import { Action } from 'history';
import { WebSocketContext } from 'features/hub/hub';
import { useDispatch } from 'react-redux';

export const useBackButtonListener = () => {
  const [hasStopped, setHasStopped] = useState<boolean>(false);
  const { stop } = useAudio();
  const playing = useSelector((state: RootState) => state.audio.playing);
  const audioUrl = useSelector((state: RootState) => state.audio.url);
  const isAnimatingLayout = useSelector(
    (state: RootState) => state.ui.isAnimatingLayout
  );
  const navType: NavigationType = useNavigationType();
  const { leaveCrowd } = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // Handle clicking back button from homepage, events or recordings
    // and stopping audio if playing / resetting state
    if (
      navType === Action.Pop &&
      playing &&
      (location.pathname === '/' ||
        location.pathname === '/events' ||
        location.pathname === '/recordings' ||
        location.pathname === '/collections' ||
        matchPath('/collections/:id', location.pathname))
    ) {
      console.log('[Browser navigation] Stopping audio');
      stop(true);
      setHasStopped(true);
    }
  }, [navType, playing, stop, location]);

  useEffect(() => {
    // Make sure any route animations have
    // finished before cleaning up state
    if (!audioUrl && !isAnimatingLayout && hasStopped) {
      dispatch(unloadEvent());
      dispatch(unloadRecording());
      leaveCrowd();
    }
  }, [audioUrl, hasStopped, dispatch, leaveCrowd, isAnimatingLayout]);
};
