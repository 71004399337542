import Picker, { IEmojiPickerProps } from 'emoji-picker-react';
import React, { FunctionComponent, useRef, useState } from 'react';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { Icon } from 'components/Icons';
import styles from './styles.module.scss';

export const EmojiPicker: FunctionComponent<IEmojiPickerProps> = (props) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [pickerOpen, togglePicker] = useState<boolean>(false);

  const handleEmojiClickOutside = () => {
    togglePicker(false);
  };

  useOnClickOutside(pickerRef, handleEmojiClickOutside);

  return (
    <div className={styles['emoji-picker']} ref={pickerRef}>
      <button
        onClick={() => togglePicker(!pickerOpen)}
        className={styles['emoji-picker__button']}
        type="button"
      >
        <Icon icon={'emoji'} hidden={true} label={'Choose emoji'} />
      </button>
      {pickerOpen && <Picker {...props} />}
    </div>
  );
};
