"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.twitch = void 0;
var types_1 = require("../types");
exports.twitch = {
    name: 'twitch',
    matches: [
        {
            match: '(https?://)?(www.)?twitch.tv/({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://twitch.tv/{PROFILE_ID}'
        },
        {
            match: '(https?://)?m.twitch.tv/({PROFILE_ID})/?', group: 2, type: types_1.TYPE_MOBILE,
            pattern: 'https://m.twitch.tv/{PROFILE_ID}'
        },
        { match: '({PROFILE_ID})', group: 1 },
    ]
};
