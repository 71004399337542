import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { ErrorWrapper } from 'components/ErrorPages/ErrorWrapper';

export const ChannelBanned: FunctionComponent = () => {
  const unavailable = useSelector(
    (state: RootState) => state.channel.channelUnavailable
  );
  const liveEmbedPlayerEnabled = useSelector(
    (state: RootState) => state.channel.channelDetails?.liveEmbedPlayerEnabled
  );

  if (unavailable || !liveEmbedPlayerEnabled) {
    return (
      <ErrorWrapper type={'private'}>
        <h1>This channel is not available</h1>
      </ErrorWrapper>
    );
  }

  return null;
};
