"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spotify = void 0;
var types_1 = require("../types");
exports.spotify = {
    name: 'spotify',
    matches: [
        {
            match: '(https?://)?(open.)?spotify.com/artist/({PROFILE_ID})/?', group: 3, type: types_1.TYPE_DESKTOP,
            pattern: 'https://open.spotify.com/artist/{PROFILE_ID}'
        },
        { match: '({PROFILE_ID})', group: 1 },
    ]
};
