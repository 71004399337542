import { Icon } from 'components/Icons';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import React, { FunctionComponent, useContext } from 'react';
import styles from './styles.module.scss';
import { UserAgentContext } from 'features/user-agent/userAgent';
import classNames from 'classnames';

export const PrivacyNotice: FunctionComponent = () => {
  const isPrivate: boolean = useSelector((state: RootState) => {
    return state.channel.channelDetails?.isPrivate || false;
  });
  const { isNativeAppWebview, isListenerAppWebview } =
    useContext(UserAgentContext);

  if (!isPrivate || isListenerAppWebview) {
    return null;
  }

  return (
    <div
      className={classNames(styles['privacy-notice'], {
        [styles['privacy-notice--native']]: isNativeAppWebview
      })}
    >
      <Icon
        hidden={true}
        icon={'lock'}
        label={'Private channel'}
        height={12}
        width={12}
      />
      <span>Private</span>
    </div>
  );
};
