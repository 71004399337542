import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { recordingSelector } from 'features/recordings/recordingsSelectors';
import { fetchRecording } from './recordingsSlice';
import { useSubdomain } from 'hooks/useSubdomain';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { setChatVisible } from 'features/chat/chatSlice';

export const useRecording = (id?: string): RecordingModel | null => {
  const recording = useSelector((state: RootState) =>
    recordingSelector(state, id)
  );

  return recording;
};

export const useCurrentRecording = (): RecordingModel | null => {
  const currentRecordingId = useSelector(
    (state: RootState) => state.recordings.currentRecordingId
  );
  return useSelector((state: RootState) =>
    recordingSelector(state, currentRecordingId)
  );
};

export const useFetchRecording = (
  id: string | null | undefined,
  hideLoader?: boolean
): null => {
  const dispatch = useDispatch();
  const channelSubdomain = useSubdomain();
  const navigate = useNavigate();
  const { isEmbed } = useContext(UserAgentContext);

  useEffect(() => {
    if (id && channelSubdomain) {
      dispatch(
        fetchRecording(channelSubdomain, id, navigate, isEmbed, hideLoader)
      );
      dispatch(setChatVisible(false));
    }
  }, [id, channelSubdomain, dispatch, navigate, isEmbed, hideLoader]);

  return null;
};
