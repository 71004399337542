import React, { FunctionComponent, useContext } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { Loader } from 'components/Loader';
import { FullScreenWrapper } from 'components/Layouts/FullScreenView/FullScreenWrapper';

interface ErrorWrapperProps {
  children?: React.ReactNode;
  type: 'event' | 'recording' | 'private';
}

export const ErrorWrapper: FunctionComponent<ErrorWrapperProps> = ({
  children,
  type
}) => {
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const channelisLoaded = useSelector(
    (state: RootState) => state.channel.isLoaded
  );
  const { isNativeAppWebview } = useContext(UserAgentContext);

  if (!channelisLoaded) {
    return <Loader fillComponent={true} />;
  }

  return (
    <div
      className={classNames(styles['error-page'], {
        [styles['error-page__dark']]: isNativeAppWebview
      })}
    >
      <FullScreenWrapper
        artwork={channel?.channelArtwork}
        artworkMode={'channel'}
        type={type}
      >
        <div className={classNames(styles['error-page__content'])}>
          {children}
        </div>
      </FullScreenWrapper>
    </div>
  );
};
