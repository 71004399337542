import { AppThunk } from 'store';
import { apiLogout } from 'api/user';
import { apiV3GetMe } from 'api/v3/user';
import {
  logoutSuccess,
  fetchFollowedIds,
  fetchCurrentUserSuccess
} from 'features/current-user/currentUserSlice';
import { createAlert } from 'features/alert/alertSlice';
import { mapApiV3Me } from 'features/current-user/mappers';
import { airbrakeNetworkError } from 'utilities/airbrake';
import { fetchChannelView } from 'features/channel/channelSlice';
import { useSubdomain } from 'hooks/useSubdomain';

export const logout = (): AppThunk => async (dispatch) => {
  const response = await apiLogout();

  if (response.ok) {
    dispatch(logoutSuccess());

    const subdomain = useSubdomain();
    dispatch(fetchChannelView(subdomain));
    dispatch(
      createAlert({ message: 'You are now logged out', type: 'success' })
    );
  }
};

export const logoutListenerApp = (): AppThunk => async (dispatch) => {
  dispatch(logoutSuccess());
};

export const fetchCurrentUser = (): AppThunk => async (dispatch) => {
  try {
    const payload = await apiV3GetMe();
    const user = mapApiV3Me(payload);
    dispatch(fetchFollowedIds(user.id.toString()));
    dispatch(fetchCurrentUserSuccess(user));
  } catch (err) {
    if (err.status !== 401 || err.status !== 204) {
      airbrakeNetworkError(err);
    }
  }
};
