import React, { FunctionComponent, useContext } from 'react';
import { IconButton } from 'components/Buttons/IconButton';
import { useIsBroadcaster } from 'features/current-user/hooks';
import { useIsFullScreenView } from 'features/theme/themeHooks';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useMediaQuery } from 'react-responsive';
import { useAppSelector } from 'hooks/redux';
import { matchPath, useLocation } from 'react-router-dom';

export const CreatorSignUpButton: FunctionComponent = () => {
  const { isEmbed } = useContext(UserAgentContext);
  const hideOnSmallScreensOrEmbed =
    useMediaQuery({ query: '(max-width: 900px)' }) || isEmbed;
  const isFullScreenView = useIsFullScreenView();
  const isBroadcaster = useIsBroadcaster();
  const channelId = useAppSelector(
    (state) => state.channel.channelDetails?.channelId
  );
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);
  const isRecordingPath = matchPath('/recordings/:id', pathname);

  const href = (): string => {
    if (!channelId) return `${process.env.REACT_APP_WEBSITE_ENDPOINT}`;
    let utmMedium = 'channel_button';
    if (isEventPath) {
      utmMedium = 'live_event_button';
    } else if (isRecordingPath) {
      utmMedium = 'recording_button';
    }
    return `${process.env.REACT_APP_WEBSITE_ENDPOINT}?utm_source=${channelId}&utm_medium=${utmMedium}&utm_campaign=internal_link`;
  };

  if (hideOnSmallScreensOrEmbed) {
    return null;
  }

  if (isBroadcaster) {
    return null;
  }

  return (
    <IconButton
      showLabel={true}
      icon={'plusCircle'}
      label={'Create'}
      href={href()}
      external={true}
      isTransparent={isFullScreenView}
      isLight={!isFullScreenView}
      target="_blank"
      rel="noopener noreferrer"
    />
  );
};
