import {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useRef
} from 'react';
import { useCurrentRecording } from 'features/recordings/recordingHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useAudioPosition } from 'features/audio/useAudioPosition';
import {
  EventPayloadType,
  RecordingListenEventPayload,
  sendEvent
} from 'api/v3/send_event';

export const SendRecordingListenEvents: FunctionComponent = () => {
  const recording = useCurrentRecording();
  const currentRecordingSessionId = useSelector(
    (state: RootState) => state.recordings.currentRecordingSessionId
  );
  const { position } = useAudioPosition();
  const [currentMinute, setCurrentMinute] = useState(0);
  const currentMinuteRef = useRef(0);

  const sendListenEvent = useCallback(
    async (minute: number) => {
      if (recording && currentRecordingSessionId) {
        console.debug(
          'Minute:',
          minute,
          'Recording:',
          recording,
          'Session ID:',
          currentRecordingSessionId
        );
        const payload: RecordingListenEventPayload = {
          type: EventPayloadType.RECORDING_LISTEN_EVENT,
          recording_id: recording.id,
          owner_id: recording.userId,
          minute: minute,
          session_id: currentRecordingSessionId,
          version: `${process.env.REACT_APP_API_GATEWAY_SEND_EVENT_VERSION}`
        };
        await sendEvent(payload);
      }
    },
    [recording, currentRecordingSessionId]
  );

  useEffect(
    function setListenEventForCurrentMinute() {
      if (currentMinute > 0 && currentMinute != currentMinuteRef.current) {
        sendListenEvent(currentMinute);
        currentMinuteRef.current = currentMinute;
      }
    },
    [currentMinute, sendListenEvent]
  );

  useEffect(
    function setCurrentMinuteFromAudioPosition() {
      if (position > 0) {
        setCurrentMinute(Math.ceil(position / 60));
      }
    },
    [position, setCurrentMinute]
  );

  useEffect(
    function resetOnCurrentRecordingSessionIdChange() {
      setCurrentMinute(0);
      currentMinuteRef.current = 0;
    },
    [currentRecordingSessionId, setCurrentMinute]
  );

  return null;
};
