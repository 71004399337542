import React, { FunctionComponent } from 'react';
import { MiniPlayer } from 'components/Player/MiniPlayer';
import { useCurrentEvent, useIsEventLive } from 'features/events/eventHooks';
import { EventMeta } from 'features/events/components/EventMeta';
import { EventDate } from 'features/events/EventDate';

export const EventMiniPlayer: FunctionComponent = () => {
  const event = useCurrentEvent();
  const eventIsLive = useIsEventLive(event);

  if (!event) {
    return null;
  }

  return (
    <MiniPlayer
      title={event.title}
      url={`/events/${event.id}`}
      artwork={event.artwork}
      showVolumeControl={eventIsLive}
      color={event.color}
      meta={
        <>
          {event.active && <EventMeta />}
          {!eventIsLive && <EventDate eventId={event.id} />}
        </>
      }
    />
  );
};
