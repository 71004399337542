import React, { FunctionComponent } from 'react';
import { motion } from 'framer-motion';
import styles from './styles.module.scss';
import { matchPath, useLocation } from 'react-router-dom';
import { useReducedMotion } from 'framer-motion';
import { usePageLoaded } from 'hooks/usePageLoaded';

interface PageAnimationOpacityProps {
  children?: React.ReactNode;
}

export const PageAnimationOpacity: FunctionComponent<
  PageAnimationOpacityProps
> = (props) => {
  // We want to keep the channel router visible when
  // animating in an event or recording view
  // otherwise just animate opacity normally
  const location = useLocation() as any;
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);
  const isRecordingPath = matchPath('/recordings/:id', pathname);
  const isVisible = isEventPath || isRecordingPath;
  const shouldReduceMotion = useReducedMotion();
  const animateTransition: boolean = location.state && location.state.animate;

  usePageLoaded();

  return (
    <motion.div
      className={styles['page-opacity']}
      initial={{
        opacity: !animateTransition || isVisible || shouldReduceMotion ? 1 : 0
      }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: !animateTransition || isVisible || shouldReduceMotion ? 1 : 0
      }}
      transition={{ duration: isVisible ? 0.3 : 0.2 }}
    >
      {props.children}
    </motion.div>
  );
};
