import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import {
  cancelAuthForm,
  verifyUserExistence
} from 'features/current-user/currentUserSlice';
import { createAlert } from 'features/alert/alertSlice';
import { ModalWrapper } from 'components/Modal/Wrapper';
import { FormMessage } from 'components/Forms/Messages';
import { FormSubmitButton } from 'components/Forms/SubmitButton';

export const AuthenticationEmailPromptForm: FunctionComponent<{
  setInputEmail: React.Dispatch<React.SetStateAction<string>>;
}> = (props) => {
  const [email, setEmail] = useState<string>('');
  const dispatch = useDispatch();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const {
    me,
    authPromptMessage,
    authenticationStepFailure,
    currentAuthStep,
    authenticationStepSubmitted
  } = useSelector((state: RootState) => state.currentUser);

  const cancelForm = () => {
    dispatch(cancelAuthForm());
  };

  const submit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    props.setInputEmail(email);
    dispatch(verifyUserExistence(email));
  };

  if (!(currentAuthStep === 'emailPrompt')) return null;

  if (me && currentAuthStep === 'emailPrompt') {
    dispatch(
      createAlert({ message: 'You are already logged in', type: 'error' })
    );
    return null;
  }

  return (
    <ModalWrapper
      isOpen={currentAuthStep === 'emailPrompt'}
      title={'Log in or sign up'}
      onRequestClose={cancelForm}
      showChannelLogo
      showMixlrText
    >
      {authPromptMessage && (
        <p className="text--center">
          {authPromptMessage}, enter your email address.
        </p>
      )}
      <form onSubmit={submit} className="form">
        <div className="form__row">
          <label className="form__label" htmlFor="username">
            Enter email address
          </label>
          <input
            className="form__input"
            id="email"
            type="email"
            name="email"
            onChange={handleEmailChange}
            required
          />
          {authenticationStepFailure && (
            <FormMessage
              text={'Something went wrong, please try again later'}
              type={'error'}
            />
          )}
        </div>
        <div className="form__row form__row--submit">
          <FormSubmitButton
            label={'Submit'}
            disabled={authenticationStepSubmitted}
          />
        </div>
      </form>
    </ModalWrapper>
  );
};
