/* eslint-disable */

import React, { FunctionComponent, useState } from 'react';
import { DateTime, Duration } from 'luxon';
import { EventModel } from 'features/events/EventModel';
import { Broadcast } from 'features/broadcast/broadcastsSlice';
import { startTimeChecker } from 'utilities/startTimeChecker';
import useInterval from 'hooks/useInterval';
import humanizeDuration from 'humanize-duration';
import { pad } from 'utilities/formatters';

interface EventTimerProps {
  event: EventModel;
  broadcast: Broadcast;
}

const calculateCurrentPlayingTime = (startedAt: DateTime | null): Duration => {
  if (!startedAt) {
    return Duration.fromMillis(0);
  }

  return DateTime.now().diff(startedAt);
};

export const EventTimer: FunctionComponent<EventTimerProps> = ({
  event,
  broadcast
}) => {
  const startedAt = startTimeChecker(event, broadcast);
  const [currentPlayingTime, setCurrentPlayingTime] = useState<Duration>(
    calculateCurrentPlayingTime(startedAt)
  );

  useInterval(() => {
    setCurrentPlayingTime(calculateCurrentPlayingTime(startedAt));
  }, 1000);

  const formatTime = (currentPlayingTime: Duration): string => {
    if (currentPlayingTime.as('hours') > 24) {
      const currentPlayingTimeDuration = currentPlayingTime.valueOf();
      const hours = Math.trunc(currentPlayingTimeDuration / 3600000) % 24;
      const mins = Math.trunc(currentPlayingTimeDuration / 60000) % 60;
      const secs = Math.trunc(currentPlayingTimeDuration / 1000) % 60;
      const humanizedDuration = humanizeDuration(currentPlayingTimeDuration, {
        units: ['y', 'd'],
        round: true
      });
      return `+ ${humanizedDuration}, ${pad(hours, 2)}:${pad(mins, 2)}:${pad(
        secs,
        2
      )}`;
    }

    return currentPlayingTime.toFormat('hh:mm:ss');
  };

  return <span className="tnum">{formatTime(currentPlayingTime)}</span>;
};
