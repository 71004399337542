import { Notifier } from '@airbrake/browser';

export const airbrake = new Notifier({
  projectId: parseInt(process.env.REACT_APP_AIRBRAKE_PROJECT_ID || ''),
  projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY || '',
  environment: process.env.REACT_APP_ENV_NAME
});

airbrake.addFilter((notice) => {
  if (
    notice.context.environment == 'development' ||
    notice.context.environment == 'test'
  ) {
    // Ignore errors from development.
    return null;
  }
  return notice;
});

export const airbrakeNetworkError = async (response: Response) => {
  // Unfortunately due to how we currently throw errors, this can be something
  // other than a response object. Ideal solution is change how to throw errors
  // from the api.
  if (typeof response.text !== 'function') {
    return;
  }

  airbrake.notify({
    context: {
      environment: process.env.REACT_APP_ENV_NAME,
      response: {
        url: response.url,
        status: response.status
      }
    },
    error: `Unexpected network error: ${await response.text()}`
  });
};
