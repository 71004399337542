import React, { FunctionComponent, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { LoginForm } from './LoginForm';
import { AuthenticationEmailPromptForm } from './AuthenticationEmailPromptForm';
import { SignupForm } from './SignupForm';
import { ForgotPassword } from './ForgotPassword';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { AccountActivationForm } from './AccountActivationForm';

export const AuthModal: FunctionComponent = () => {
  const { isNativeAppWebview } = useContext(UserAgentContext);
  const { currentAuthStep } = useSelector(
    (state: RootState) => state.currentUser
  );
  const [inputEmail, setInputEmail] = useState<string>('');

  if (isNativeAppWebview) return null;
  switch (currentAuthStep) {
    case 'hidden':
      return null;
    case 'emailPrompt':
      return <AuthenticationEmailPromptForm setInputEmail={setInputEmail} />;
    case 'login':
      return <LoginForm inputEmail={inputEmail} />;
    case 'signup':
      return (
        <SignupForm inputEmail={inputEmail} setInputEmail={setInputEmail} />
      );
    case 'activation':
      return <AccountActivationForm inputEmail={inputEmail} />;
    case 'forgotPassword':
      return <ForgotPassword inputEmail={inputEmail} />;
  }
};
