import React, { FunctionComponent } from 'react';
import { Icon } from 'components/Icons';
import { useRecording } from 'features/recordings/recordingHooks';
import { useEvent } from 'features/events/eventHooks';

export const UnlistedBanner: FunctionComponent = () => {
  const event = useEvent();
  const recording = useRecording();

  if (
    !event?.isOnlyVisibleToCreator() ||
    !recording?.isOnlyVisibleToCreator()
  ) {
    return null;
  }

  return (
    <div className="tw-bg-theme-color tw-text-black [.dark-contrast_&]:tw-text-white tw-text-center tw-flex tw-items-center tw-justify-center tw-gap-4 tw-relative tw-z-50 tw-text-xl [.ios_&]:tw-text-sm tw-py-2 tw-px-4">
      <Icon
        hidden={true}
        icon={'lock'}
        label={'Private event'}
        height={12}
        width={12}
      />
      Only visible to you
    </div>
  );
};
