import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { useAppSelector } from 'hooks/redux';
import { currentBroadcastHeartCountSelector } from 'features/broadcast/selectors';
import { formatNumber } from 'utilities/formatters';
import { useMediaQuery } from 'react-responsive';
import { Icon } from 'components/Icons';

interface HeartCountProps {
  onlyCount?: boolean;
}

export const HeartCount: FunctionComponent<HeartCountProps> = (props) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const heartCount = useAppSelector(currentBroadcastHeartCountSelector);
  const label = heartCount === 1 ? 'Heart' : 'Hearts';
  const isPageVisible = usePageVisibility();
  const hideText = useMediaQuery({
    query: '(max-width: 340px)'
  });

  const labelCount = (): string => {
    if (hideText) {
      return formatNumber(heartCount);
    }
    return `${formatNumber(heartCount)} ${label}`;
  };

  useEffect(() => {
    if (isPageVisible) {
      forceUpdate();
    }
  }, [isPageVisible]);

  if (props.onlyCount) {
    return (
      <span className="tw-font-bold tw-min-w-[6ch] tw-text-left">
        {formatNumber(heartCount)}
      </span>
    );
  }

  return (
    <div className="tw-flex tw-items-center tw-space-x-[6px]">
      <Icon
        icon={'heart'}
        height={18}
        hidden={true}
        label={labelCount()}
        width={18}
      />
      <span>{labelCount()}</span>
    </div>
  );
};
