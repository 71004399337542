import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { cancelAuthForm, signUp } from 'features/current-user/currentUserSlice';
import { createAlert } from 'features/alert/alertSlice';
import { ModalWrapper } from 'components/Modal/Wrapper';
import { FormSubmitButton } from 'components/Forms/SubmitButton';
import { FormMessage } from 'components/Forms/Messages';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const SignupForm: FunctionComponent<{
  inputEmail: string;
  setInputEmail: React.Dispatch<React.SetStateAction<string>>;
}> = (props) => {
  const {
    me,
    currentAuthStep,
    signupErrors,
    invalidCaptcha,
    authPromptMessage,
    authenticationStepSubmitted
  } = useSelector((state: RootState) => state.currentUser);

  const channelOwnerId = useSelector(
    (state: RootState) => state.channel.channelDetails?.ownerId
  );

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return '';
    }
    const token = await executeRecaptcha('signup');
    return token;
  };

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>(props.inputEmail || '');

  const dispatch = useDispatch();
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const cancelForm = () => {
    dispatch(cancelAuthForm());
  };

  const submit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    props.setInputEmail(email);
    const captchaToken = await handleReCaptchaVerify();
    dispatch(
      signUp(email, username, password, captchaToken, channelOwnerId || '')
    );
  };

  const [emailInput, setEmailInput] = useState<HTMLInputElement | null>(null);

  const emailInputRef = useCallback((node) => {
    if (node !== null) {
      setEmailInput(node);
    }
  }, []);

  useEffect(() => {
    if (!props.inputEmail || !emailInput) {
      return;
    }

    emailInput.value = props.inputEmail;
  }, [props.inputEmail, emailInput]);

  if (!(currentAuthStep === 'signup')) return null;

  if (me && currentAuthStep === 'signup') {
    dispatch(
      createAlert({ message: 'You are already logged in', type: 'error' })
    );
    return null;
  }

  return (
    <ModalWrapper
      isOpen={currentAuthStep === 'signup'}
      title={'Account signup'}
      onRequestClose={cancelForm}
      showChannelLogo
      showMixlrText
    >
      {authPromptMessage && (
        <p className="text--center">
          {authPromptMessage}, you&apos;ll need to create an account.
        </p>
      )}
      <form onSubmit={submit} className="form">
        <div className="form__row">
          <label className="form__label" htmlFor="username">
            Email
          </label>
          <input
            className="form__input"
            id="email"
            type="email"
            name="email"
            ref={emailInputRef}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div className="form__row">
          <label className="form__label" htmlFor="username">
            Username
          </label>
          <input
            className="form__input"
            id="username"
            type="text"
            name="username"
            onChange={handleUsernameChange}
            required
          />
        </div>
        <div className="form__row">
          <label className="form__label" htmlFor="password">
            Password
          </label>
          <input
            className="form__input"
            id="password"
            type="password"
            name="password"
            onChange={handlePasswordChange}
            required
          />
          {invalidCaptcha && (
            <FormMessage
              text={'Something went wrong, please try again later.'}
              type={'error'}
            />
          )}
          {signupErrors.length > 0 && (
            <FormMessage
              text={
                <ul>
                  {signupErrors.map((error, i) => (
                    <li key={i}>{error}</li>
                  ))}
                </ul>
              }
              type={'error'}
            />
          )}
        </div>
        <div className="form__row text--center">
          <label htmlFor="terms">
            <input type="checkbox" id="terms" name="terms" required /> I agree
            to{' '}
            <a
              href={`${process.env.REACT_APP_WEBSITE_ENDPOINT}/terms-of-use`}
              target="_blank"
              rel="noreferrer"
            >
              Terms of use
            </a>
          </label>
        </div>
        <div className="form__row form__row--submit">
          <FormSubmitButton
            label={'Create account'}
            disabled={authenticationStepSubmitted}
          />
        </div>
      </form>
    </ModalWrapper>
  );
};
