var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeRequester } from './http_req';
import { NOTIFIER_NAME, NOTIFIER_VERSION } from './version';
// API version to poll.
var API_VER = '2020-06-18';
// How frequently we should poll the config API.
var DEFAULT_INTERVAL = 600000; // 10 minutes
var NOTIFIER_INFO = {
    notifier_name: NOTIFIER_NAME,
    notifier_version: NOTIFIER_VERSION,
    os: typeof window !== 'undefined' &&
        window.navigator &&
        window.navigator.userAgent
        ? window.navigator.userAgent
        : undefined,
    language: 'JavaScript',
};
// Remote config settings.
var ERROR_SETTING = 'errors';
var APM_SETTING = 'apm';
var RemoteSettings = /** @class */ (function () {
    function RemoteSettings(opt) {
        this._opt = opt;
        this._requester = makeRequester(opt);
        this._data = new SettingsData(opt.projectId, {
            project_id: null,
            poll_sec: 0,
            updated_at: 0,
            config_route: '',
            settings: [],
        });
        this._origErrorNotifications = opt.errorNotifications;
        this._origPerformanceStats = opt.performanceStats;
    }
    RemoteSettings.prototype.poll = function () {
        var _this = this;
        // First request is immediate. When it's done, we cancel it since we want to
        // change interval time to the default value.
        var pollerId = setInterval(function () {
            _this._doRequest();
            clearInterval(pollerId);
        }, 0);
        // Second fetch is what always runs in background.
        return setInterval(this._doRequest.bind(this), DEFAULT_INTERVAL);
    };
    RemoteSettings.prototype._doRequest = function () {
        var _this = this;
        this._requester(this._requestParams(this._opt))
            .then(function (resp) {
            _this._data.merge(resp.json);
            _this._opt.host = _this._data.errorHost();
            _this._opt.apmHost = _this._data.apmHost();
            _this._processErrorNotifications(_this._data);
            _this._processPerformanceStats(_this._data);
        })
            .catch(function (_) {
            return;
        });
    };
    RemoteSettings.prototype._requestParams = function (opt) {
        return {
            method: 'GET',
            url: this._pollUrl(opt),
            headers: {
                Accept: 'application/json',
                'Cache-Control': 'no-cache,no-store',
            },
        };
    };
    RemoteSettings.prototype._pollUrl = function (opt) {
        var url = this._data.configRoute(opt.remoteConfigHost);
        var queryParams = '?';
        for (var _i = 0, _a = this._entries(NOTIFIER_INFO); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            queryParams += "&" + encodeURIComponent(key) + "=" + encodeURIComponent(value);
        }
        return url + queryParams;
    };
    RemoteSettings.prototype._processErrorNotifications = function (data) {
        if (!this._origErrorNotifications) {
            return;
        }
        this._opt.errorNotifications = data.errorNotifications();
    };
    RemoteSettings.prototype._processPerformanceStats = function (data) {
        if (!this._origPerformanceStats) {
            return;
        }
        this._opt.performanceStats = data.performanceStats();
    };
    // Polyfill from:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#polyfill
    RemoteSettings.prototype._entries = function (obj) {
        var ownProps = Object.keys(obj);
        var i = ownProps.length;
        var resArray = new Array(i);
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        return resArray;
    };
    return RemoteSettings;
}());
export { RemoteSettings };
var SettingsData = /** @class */ (function () {
    function SettingsData(projectId, data) {
        this._projectId = projectId;
        this._data = data;
    }
    SettingsData.prototype.merge = function (other) {
        this._data = __assign(__assign({}, this._data), other);
    };
    SettingsData.prototype.configRoute = function (remoteConfigHost) {
        var host = remoteConfigHost.replace(/\/$/, '');
        var configRoute = this._data.config_route;
        if (configRoute === null ||
            configRoute === undefined ||
            configRoute === '') {
            return host + "/" + API_VER + "/config/" + this._projectId + "/config.json";
        }
        else {
            return host + "/" + configRoute;
        }
    };
    SettingsData.prototype.errorNotifications = function () {
        var s = this._findSetting(ERROR_SETTING);
        if (s === null) {
            return true;
        }
        return s.enabled;
    };
    SettingsData.prototype.performanceStats = function () {
        var s = this._findSetting(APM_SETTING);
        if (s === null) {
            return true;
        }
        return s.enabled;
    };
    SettingsData.prototype.errorHost = function () {
        var s = this._findSetting(ERROR_SETTING);
        if (s === null) {
            return null;
        }
        return s.endpoint;
    };
    SettingsData.prototype.apmHost = function () {
        var s = this._findSetting(APM_SETTING);
        if (s === null) {
            return null;
        }
        return s.endpoint;
    };
    SettingsData.prototype._findSetting = function (name) {
        var settings = this._data.settings;
        if (settings === null || settings === undefined) {
            return null;
        }
        var setting = settings.find(function (s) {
            return s.name === name;
        });
        if (setting === undefined) {
            return null;
        }
        return setting;
    };
    return SettingsData;
}());
export { SettingsData };
