import React, { FunctionComponent } from 'react';
import { IconButton } from 'components/Buttons/IconButton';
import { LogoImage } from 'components/LogoImage';
import { ThumbImage } from 'components/Images/ThumbImage';
import styles from './styles.module.scss';
import { useAppSelector } from 'hooks/redux';

interface ModalHeaderProps {
  title?: string;
  showChannelLogo?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const ModalHeader: FunctionComponent<ModalHeaderProps> = (props) => {
  const channel = useAppSelector((state) => state.channel.channelDetails);

  return (
    <div className={styles['modal__header']}>
      {props.showChannelLogo && channel && (
        <div className={styles['modal__header-logo']}>
          <LogoImage size={'medium'}>
            <ThumbImage
              artworkUrl={channel.channelLogo.image.small}
              artworkMode={'logo'}
              alt={''}
            />
          </LogoImage>
        </div>
      )}
      {props.title && (
        <h4 className={styles['modal__header-title']}>{props.title}</h4>
      )}
      <div className={styles['modal__header-close']}>
        <IconButton
          label={'Close login'}
          isLight={true}
          onClick={props.onClick}
          icon={'x'}
          noBackground={true}
        />
      </div>
    </div>
  );
};
