import classNames from 'classnames';
import { AlertState, resetAlert } from 'features/alert/alertSlice';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import { Icon } from 'components/Icons';

export const Alert: FunctionComponent = () => {
  const alerts = useSelector((state: RootState) => state.notifications.alerts);
  const [alert, setAlert] = useState<AlertState>({ message: '', type: '' });
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[alerts.length - 1]);
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
        dispatch(resetAlert());
      }, 5000);
    }
  }, [alerts, dispatch]);

  return showAlert ? (
    <div
      className={classNames(styles['alert'], {
        [styles['alert--success']]: alert.type === 'success',
        [styles['alert--notify']]: alert.type === 'notify',
        [styles['alert--error']]: alert.type === 'error'
      })}
    >
      <Icon
        height={16}
        icon={alert.type}
        hidden={true}
        label={alert.type}
        width={16}
      />
      <p className={styles['alert__message']}>{alert.message || ''}</p>
    </div>
  ) : null;
};
