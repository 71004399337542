import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchChannelView } from 'features/channel/channelSlice';
import { fetchCurrentUser } from 'features/current-user/thunks';
import { useSubdomain } from 'hooks/useSubdomain';

interface DataProviderProps {
  children?: ReactNode[];
}

export const DataProvider: FunctionComponent<DataProviderProps> = (props) => {
  const dispatch = useDispatch();
  const subdomain = useSubdomain();

  useEffect(() => {
    async function loadData() {
      dispatch(fetchChannelView(subdomain));
      dispatch(fetchCurrentUser());
    }

    loadData();
  }, [subdomain, dispatch]);

  return <>{props.children}</>;
};
