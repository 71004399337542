import React, { FunctionComponent } from 'react';
import { Pill } from 'components/Pill';
import { LiveNow } from 'components/LiveNow';

interface CardStatusesProps {
  isLive?: boolean;
  isPrivate?: boolean;
  isPlaying?: boolean;
  duration?: string;
  type: 'event' | 'recording';
}

export const CardStatuses: FunctionComponent<CardStatusesProps> = ({
  isLive,
  isPrivate,
  isPlaying,
  duration,
  type
}) => {
  return (
    <div className="tw-inline-flex tw-gap-4">
      {type === 'event' && isLive && <LiveNow isLive={isLive} />}
      {type === 'event' && !isLive && (
        <Pill label={'Upcoming'} type={'live'} icon={'calendar'} />
      )}
      {type === 'recording' && (
        <Pill
          label={duration}
          type={'live'}
          icon={isPlaying ? 'stop' : 'play'}
        />
      )}
      {isPrivate && <Pill label={'Private'} type={'default'} icon={'lock'} />}
    </div>
  );
};
