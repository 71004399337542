import { createSelector } from 'reselect';
import { MessageState } from 'features/chat/chatSlice';
import { ChatMessageModel } from 'features/chat/ChatMessageModel';
import { RootState } from 'rootReducer';

const wrapChatMessageModel = (messages: MessageState[]): ChatMessageModel[] => {
  return messages.map((message: MessageState) => {
    return ChatMessageModel(message);
  });
};

export const recentMessagesSelector = createSelector(
  (state: RootState) => state.chat.messages,
  (messages): ChatMessageModel[] => {
    return wrapChatMessageModel(messages).filter((message) =>
      message.isRecent()
    );
  }
);
