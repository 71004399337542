import { V3Me } from 'api/v3/types/me';
import { V3UserExistanceStatus } from 'api/v3/types/user_existence_status';

export async function apiV3GetMe(): Promise<V3Me> {
  const results = await fetch(
    `${process.env.REACT_APP_API_CDN_ENDPOINT}/v3/channel_me`,
    {
      credentials: 'include'
    }
  );

  if (results.ok) {
    const json = await results.json();

    return json;
  } else {
    throw results;
  }
}

export async function apiLogin(
  email: string,
  password: string,
  rememberMe: string
): Promise<Response> {
  const formData = new FormData();

  // To keep compability with V3 endpoint we need to send email in username param - endpoint accepts both
  formData.set('credentials[username]', email);
  formData.set('credentials[password]', password);
  formData.set('credentials[remember_me]', String(rememberMe));

  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/login`,
    {
      method: 'post',
      credentials: 'include',

      body: formData
    }
  );

  return results;
}

export async function apiGetUserExistenceStatus(
  email: string
): Promise<V3UserExistanceStatus> {
  const results = await fetch(
    `${
      process.env.REACT_APP_API_ENDPOINT
    }/v3//user_existence_status/?email=${encodeURIComponent(email)}`,
    {
      method: 'GET',
      credentials: 'include'
    }
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}

export async function apiCreateUser(
  email: string,
  username: string,
  password: string,
  captchaToken: string,
  channelOwnerId: string
): Promise<V3Me> {
  const formData = new FormData();

  formData.set('user[email]', email);
  formData.set('user[username]', username);
  formData.set('user[password]', password);
  formData.set('user[referring_broadcaster_id]', channelOwnerId);
  formData.set('recaptcha_v3_token', captchaToken);

  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/users`,
    {
      method: 'post',
      credentials: 'include',

      body: formData
    }
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}

export async function apiCreateUserActivation(
  email: string,
  code: string
): Promise<V3Me> {
  const formData = new FormData();

  formData.set('activation[email]', email);
  formData.set('activation[code]', code);

  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/user_activations`,
    {
      method: 'post',
      credentials: 'include',

      body: formData
    }
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}

export async function apiResendActivation(email: string): Promise<Response> {
  const formData = new FormData();

  formData.set('email', email);

  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/resend_activation`,
    {
      method: 'post',
      credentials: 'include',

      body: formData
    }
  );

  if (results.ok) {
    return results;
  } else {
    throw results;
  }
}
