import { useState, useEffect, useContext } from 'react';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useOnceEffect } from 'hooks/useOnceEffect';

export function usePageLoaded() {
  const [pageLoaded, setPageLoaded] = useState(false);
  const { postMessage } = useNativeEvents();
  const { isNativeAppWebview } = useContext(UserAgentContext);

  useOnceEffect(() => {
    setTimeout(() => {
      postMessage('eventLoaded');
      postMessage('webViewLoaded');
    }, 100);
  }, isNativeAppWebview && pageLoaded);

  useEffect(() => {
    const onPageLoad = () => {
      setPageLoaded(true);
    };
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);
}
