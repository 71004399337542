import React, { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Icon } from 'components/Icons';

interface FormMessageProps {
  text: string | ReactNode;
  type: 'error' | 'notify' | 'success' | 'info';
  align?: 'left' | 'center' | 'right';
}

export const FormMessage: FunctionComponent<FormMessageProps> = (props) => {
  return (
    <div
      className={classNames(styles['form__message'], {
        [styles['form__message--info']]: props.type === 'info',
        [styles['form__message--success']]: props.type === 'success',
        [styles['form__message--notify']]: props.type === 'notify',
        [styles['form__message--error']]: props.type === 'error',
        [styles['form__message--left']]: props.align === 'center',
        [styles['form__message--center']]: props.align === 'left',
        [styles['form__message--right']]: props.align === 'right'
      })}
    >
      <Icon hidden={true} icon={props.type} label={''} height={12} width={12} />
      {props.text}
    </div>
  );
};
