import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { PageAnimationFullScreen } from 'components/LayoutAnimations/PageAnimationFullScreen';
import { RecordingWrapper } from 'features/recordings/components/RecordingWrapper';
import { RecordingEmbedHeader } from './RecordingEmbedHeader';
import { RecordingEmbedFooter } from 'features/recordings/components/RecordingEmbed/RecordingEmbedFooter';
import { ErrorEmbed } from 'components/ErrorPages/ErrorEmbed';
import { RecordingEmbedContent } from './RecordingEmbedContext';

interface RecordingEmbedProps {
  recording: RecordingModel | null;
}

export const RecordingEmbed: FunctionComponent<RecordingEmbedProps> = ({
  recording
}) => {
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );

  if (!channel) {
    return null;
  }

  if (!channel.recordingEmbedPlayerEnabled) {
    return <ErrorEmbed type={'recording'} />;
  }

  return (
    <PageAnimationFullScreen>
      <RecordingWrapper recording={recording}>
        <RecordingEmbedHeader />
        <RecordingEmbedContent recording={recording} />
        <RecordingEmbedFooter recording={recording} />
      </RecordingWrapper>
    </PageAnimationFullScreen>
  );
};
