import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';

interface FullScreenFooterProps {
  leftColumn?: React.ReactNode;
  rightColumn?: React.ReactNode;
}

export const FullScreenFooter: FunctionComponent<FullScreenFooterProps> = (
  props
) => {
  return (
    <footer className={styles['full-screen__footer']}>
      {props.leftColumn && (
        <div className={styles['full-screen__footer__left-col']}>
          {props.leftColumn}
        </div>
      )}
      {props.rightColumn && (
        <div className={styles['full-screen__footer__right-col']}>
          {props.rightColumn}
        </div>
      )}
    </footer>
  );
};
