import { UserAgentContext } from 'features/user-agent/userAgent';
import { useContext } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export const useIsFullScreenView = (): boolean => {
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);
  const isRecordingPath = matchPath('/recordings/:id', pathname);
  const { isEmbed } = useContext(UserAgentContext);
  if (isEventPath || isRecordingPath || isEmbed) return true;
  return false;
};
