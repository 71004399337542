"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PREDEFINED_PROFILES = void 0;
var behance_1 = require("./behance");
var dev_to_1 = require("./dev_to");
var dribbble_1 = require("./dribbble");
var exercism_1 = require("./exercism");
var facebook_1 = require("./facebook");
var github_1 = require("./github");
var instagram_1 = require("./instagram");
var keybase_1 = require("./keybase");
var linkedin_1 = require("./linkedin");
var linktree_1 = require("./linktree");
var medium_1 = require("./medium");
var patreon_1 = require("./patreon");
var pinterest_1 = require("./pinterest");
var soundcloud_1 = require("./soundcloud");
var spotify_1 = require("./spotify");
var stackoverflow_1 = require("./stackoverflow");
var substack_1 = require("./substack");
var telegram_1 = require("./telegram");
var tiktok_1 = require("./tiktok");
var twitch_1 = require("./twitch");
var twitter_1 = require("./twitter");
var vk_1 = require("./vk");
var youtube_1 = require("./youtube");
exports.PREDEFINED_PROFILES = [
    behance_1.behance,
    dev_to_1.dev_to,
    dribbble_1.dribbble,
    exercism_1.exercism,
    facebook_1.facebook,
    github_1.github,
    instagram_1.instagram,
    keybase_1.keybase,
    linkedin_1.linkedin,
    linktree_1.linktree,
    medium_1.medium,
    patreon_1.patreon,
    pinterest_1.pinterest,
    soundcloud_1.soundcloud,
    spotify_1.spotify,
    stackoverflow_1.stackoverflow,
    substack_1.substack,
    telegram_1.telegram,
    tiktok_1.tiktok,
    twitch_1.twitch,
    twitter_1.twitter,
    vk_1.vk,
    youtube_1.youtube,
];
