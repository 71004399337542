import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { IconButton } from 'components/Buttons/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { setCrowdVisible } from 'features/crowd/crowdSlice';
import { setChatVisible } from 'features/chat/chatSlice';
import { RootState } from 'rootReducer';
import { useSlidePanelForCrowd } from 'features/chat/chatHooks';

export const ChatHeader: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isCrowdVisible = useSelector(
    (state: RootState) => state.crowd.isCrowdVisible
  );
  const hasNewComments = useSelector(
    (state: RootState) => state.chat.hasNewComments
  );
  const slidePanelForCrowd = useSlidePanelForCrowd();

  const renderLabel = () => {
    if (slidePanelForCrowd) {
      return isCrowdVisible ? 'Close' : 'Community';
    }
    return isCrowdVisible ? 'Chat' : 'Community';
  };

  const renderTitle = () => {
    if (slidePanelForCrowd) {
      return 'Chat';
    }
    return isCrowdVisible ? 'Community' : 'Chat';
  };

  const renderToggleIcon = () => {
    if (slidePanelForCrowd) {
      return isCrowdVisible ? 'arrowRight' : 'crowd';
    }
    return isCrowdVisible ? 'chatOn' : 'crowd';
  };

  return (
    <header className={styles['chat__header']}>
      <IconButton
        icon={renderToggleIcon()}
        label={renderLabel()}
        showLabel={false}
        noBackground={true}
        tooltip={true}
        onClick={() => {
          dispatch(setCrowdVisible(!isCrowdVisible));
        }}
        showNotification={isCrowdVisible && hasNewComments}
      />
      {renderTitle()}
      <IconButton
        icon={'x'}
        label={'Hide chat'}
        showLabel={false}
        noBackground={true}
        onClick={() => {
          dispatch(setChatVisible(false));
        }}
      />
    </header>
  );
};
