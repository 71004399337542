import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { IconButton } from '../Buttons/IconButton';
import { useAudioPosition } from 'features/audio/useAudioPosition';
import { useAudio } from 'features/audio/audio';

export const ForwardButton: FunctionComponent = () => {
  const { seek } = useAudioPosition();
  const { audio } = useAudio();
  const loading = useSelector((state: RootState) => state.audio.loading);

  const onClick = () => {
    if (audio) {
      seek(audio.currentTime + 15);
    }
  };

  return (
    <IconButton
      label={'Fast Foward 15 seconds'}
      onClick={onClick}
      isDisabled={loading || !audio}
      icon={'forward15'}
      isTransparent={true}
    />
  );
};
