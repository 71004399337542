import React, { FunctionComponent, useState } from 'react';
import { V3ChannelSubscriptionPrices } from 'api/v3/types/channel_subscription_prices';
import { V3ChannelSubscriptionCheckoutSession } from 'api/v3/types/channel_subscription_checkout_session';
import { apiV3CreateChannelSubscriptionCheckoutSession } from 'api/v3/channel_view';
import { useSubdomain } from 'hooks/useSubdomain';
import { useCurrentUser } from 'features/current-user/hooks';
import { useDispatch } from 'react-redux';
import { showAuthenticationEmailPromptForm } from 'features/current-user/currentUserSlice';
import { Button } from 'components/Buttons/Button';
import styles from './styles.module.scss';
import { Icon } from 'components/Icons';

interface SubscriptionPricesProps {
  prices: V3ChannelSubscriptionPrices | undefined;
}

export const SubscriptionPrices: FunctionComponent<SubscriptionPricesProps> = ({
  prices
}) => {
  const currentUser = useCurrentUser();
  const [subscribing, setSubscribing] = useState(false);
  const subdomain = useSubdomain();
  const dispatch = useDispatch();

  const onSubscribeToPaidChannel = (priceId: string) => {
    if (currentUser) {
      setSubscribing(true);
      (async () => {
        try {
          const checkoutSession: V3ChannelSubscriptionCheckoutSession =
            await apiV3CreateChannelSubscriptionCheckoutSession(
              priceId,
              subdomain
            );

          setSubscribing(false);
          if (checkoutSession.data.attributes.url) {
            window.location.replace(checkoutSession.data.attributes.url);
          }
        } catch (error) {
          console.error(error);
        }
      })();
    } else {
      dispatch(showAuthenticationEmailPromptForm('To subscribe to channel'));
    }
  };

  const formatSubscriptionCurrency = (
    amount: string,
    currency: string
  ): string => {
    let currencyString = '';
    const formattedAmount = Number(amount);
    switch (currency) {
      case 'usd':
        currencyString = '$';
        break;
      default:
        currencyString = '$';
        break;
    }
    return `${currencyString}${formattedAmount}`;
  };

  if (!prices) {
    return null;
  }

  return (
    <>
      <ul className={styles['pricing-table']}>
        {prices.data.map((price) => {
          return (
            <li key={price.id} className={styles['pricing-table__item']}>
              <dl>
                <dt>{price.attributes.interval} sub</dt>
                <dd>
                  <strong className={styles['pricing-table__amount']}>
                    {formatSubscriptionCurrency(
                      price.attributes.amount,
                      price.attributes.currency
                    )}
                  </strong>
                  <small>{price.attributes.currency}</small>
                  <ul className={styles['pricing-table__features']}>
                    <li>
                      <Icon
                        hidden={true}
                        icon={'success'}
                        label={''}
                        height={16}
                        width={16}
                      />
                      Listen to live events
                    </li>
                    <li>
                      <Icon
                        hidden={true}
                        icon={'success'}
                        label={''}
                        height={16}
                        width={16}
                      />
                      Full recording archive
                    </li>
                  </ul>
                  <Button
                    className="mar-t"
                    onClick={() => onSubscribeToPaidChannel(price.id)}
                    disabled={subscribing}
                  >
                    Subscribe
                  </Button>
                </dd>
              </dl>
            </li>
          );
        })}
      </ul>
      <p>
        <small>
          By subscribing you agree to{' '}
          <a
            href={`${process.env.REACT_APP_WEBSITE_ENDPOINT}/terms-of-use`}
            target="_blank"
            rel="noreferrer"
          >
            Mixlr&#39;s terms of use
          </a>
        </small>
      </p>
    </>
  );
};
